import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

interface ICSOptions {
  start: string;
  end?: string;
  title: string;
  description: string;
  reminderTime?: string;
}

const formatString = "yyyyMMdd'T'HHmmss'Z'";

export const createICS = (options: ICSOptions) => {
  const { start, end, title, description, reminderTime } = options;

  const startDt = format(parseISO(start), formatString);
  const endDt = end ? format(parseISO(end), formatString) : null;
  const reminderDt = reminderTime ? format(parseISO(reminderTime), formatString) : null;

  const lines = [];

  const addLine = (line: string) => {
    lines.push(line);
  };

  addLine('BEGIN:VCALENDAR');
  addLine('VERSION:2.0');
  addLine('BEGIN:VEVENT');
  addLine(`DTSTART:${startDt}`);

  if (endDt) { addLine(`DTEND:${endDt}`); }

  addLine(`SUMMARY:${title}`);
  addLine(`DESCRIPTION:${description}`);

  if (reminderDt) {
    addLine('BEGIN:VALARM');
    addLine('ACTION:DISPLAY');
    addLine(`DESCRIPTION:${description}`);
    addLine(`TRIGGER;VALUE=DATE-TIME:${reminderDt}`);
    addLine('END:VALARM');
  }

  addLine('END:VEVENT');
  addLine('END:VCALENDAR');

  const data = lines.join('\n');

  return new Blob([data],
    { type: 'text/calendar;charset=utf-8' });
};
