import { FC } from 'react';
import { Box, Card, Checkbox, Fade, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ManagerApprovalDto } from 'src/types/apiSchemas';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { useTranslation } from 'react-i18next';
import SickOutlinedIcon from '@mui/icons-material/SickOutlined';
import { differenceInHours, format, isSameDay } from 'date-fns';
import { ensureUTCFormat } from 'src/utils/dateUtils';
import ChevronRightButton from 'src/components/ChevronRightButton';
import DateRangeIcon from '@mui/icons-material/DateRange';

export interface ManagerInfo {
  managerName: string;
}

interface ApprovalCardProps {
  approval: ManagerApprovalDto;
  managerInfo: ManagerInfo;
  chevronLinkUrl?: string;
  selectable?: boolean;
  isChecked?: boolean;
  onCheckedChange?: (id: number, checked: boolean) => void;
}

const ApprovalCard: FC<ApprovalCardProps> = (props) => {
  const { approval, managerInfo, chevronLinkUrl, selectable, isChecked, onCheckedChange } = props;
  const { t } = useTranslation();

  const dateFormatter = () => {
    const dateStart = new Date(ensureUTCFormat(approval.absence.startOfAbsenceUTC));
    const dateEnd = new Date(ensureUTCFormat(approval.absence.endOfAbsenceUTC));
    if (isSameDay(dateEnd, dateStart)) {
      return `${format(dateStart, 'dd.MM.yyyy')}`;
    }
    return `${format(dateStart, 'dd.MM.yyyy')}-${format(dateEnd, 'dd.MM.yyyy')}`;
  };

  const absenceTranslator = (): string => {
    if (approval.absence.absenceCategory === 'SickLeave') {
      return t('ApprovalSickLeaveTitle');
    }
    return '';
  };

  const selectOnChange = (event) => {
    onCheckedChange(approval.holidayBonusChangeRequest.holidayBonusChangeRequestId, event.target.checked);
  };

  const attachmentTypeTranslator = (): string => {
    switch (approval.attachment.attachmentType) {
      case 'EmploymentDocument':
        return t('ApprovalEmploymentDocument');
      case 'SickLeaveCertificate':
        return t('ApprovalSickLeaveDocument');
      default:
        return t('ApprovalOtherDocument');
    }
  };

  const absenceTimeDurationFormatter = (absenceStart, absenceEnd): string => {
    const startDate = new Date(`${absenceStart}Z`);
    const endDate = new Date(`${absenceEnd}Z`);
    const hourDifference = differenceInHours(endDate, startDate);
    const dayDifference = Math.ceil(hourDifference / 24);
    const isUnderOneHour = hourDifference < 1;
    if (isUnderOneHour) return `${t('ApprovalUnderHour')} ${t('ApprovalHourLabel')}`;
    const isUnderOneDay = hourDifference < 23;
    if (isUnderOneDay) return `${hourDifference} ${hourDifference < 2 ? t('ApprovalHourLabel') : t('ApprovalHoursLabel')}`;
    if (dayDifference < 2) return `1 ${t('ApprovalDayLabel')}`;
    return `${dayDifference} ${t('ApprovalDaysLabel')}`;
  };

  const holidayBonusChangeDaysFormatter = (): string => {
    const title: string = t('ApprovalsHolidayBonusChangeRequest');
    const daysTitle: string = approval.holidayBonusChangeRequest.days > 1 ? t('ApprovalDaysLabel') : t('ApprovalDayLabel');
    return `${title} ${approval.holidayBonusChangeRequest.days} ${daysTitle}`;
  };

  const approvalTypeToPath = (): string => {
    if (approval.absence) return `/dashboard/approvals/absence/${approval.absence.absenceId}`;
    if (approval.attachment) return `/dashboard/approvals/attachment/${approval.attachment.userAttachmentId}`;
    if (approval.holidayBonusChangeRequest) return `/dashboard/approvals/holidayBonusChangeRequest/${approval.holidayBonusChangeRequest.holidayBonusChangeRequestId}`;
    return '';
  };

  return (
    <Fade in>
      <Card sx={{ minWidth: 315, backgroundColor: 'primary.cardBackground' }}>
        <Box sx={{ m: 3 }}>
          <Grid
            container
            spacing={1}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              sx={{ mb: 0 }}
            >
              <Grid
                item
                xs={11}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography
                  color="textPrimary"
                  variant="h5"
                  fontWeight={700}
                >
                  {approval.userName}
                </Typography>
                {approval.absence && (
                  <Typography sx={{ mx: 1 }}>
                    {approval.absence.absenceCategory === 'SickLeave' && (
                      <SickOutlinedIcon />
                    )}
                  </Typography>
                )}
                {approval.holidayBonusChangeRequest && (
                  <Typography sx={{ mx: 1 }}>
                    <DateRangeIcon />
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={1}
              >
                {!selectable && (
                  <ChevronRightButton
                    size={{ width: 25, height: 25 }}
                    to={chevronLinkUrl || approvalTypeToPath()}
                    state={managerInfo}
                  />
                )}
                {selectable && approval.holidayBonusChangeRequest !== null && (
                  <Checkbox
                    checked={isChecked}
                    onChange={selectOnChange}
                  />
                )}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
            >
              {approval.absence && (
                <>
                  <Typography>
                    {`${absenceTranslator()} ${absenceTimeDurationFormatter(approval.absence.startOfAbsenceUTC, approval.absence.endOfAbsenceUTC)}`}
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="h6"
                  >
                    {`${dateFormatter()}`}
                  </Typography>

                </>
              )}
              {approval.attachment && (
                <>
                  <Grid
                    item
                    xs={11}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <AttachmentIcon
                      fontSize="small"
                      sx={{
                        mr: 1
                      }}
                    />
                    <Typography color="textSecondary">
                      {attachmentTypeTranslator()}
                    </Typography>
                  </Grid>

                </>
              )}
              {approval.holidayBonusChangeRequest && (
                <>
                  <Grid
                    item
                    xs={11}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography color="textPrimary">
                      {holidayBonusChangeDaysFormatter()}
                    </Typography>
                  </Grid>

                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Fade>
  );
};

ApprovalCard.propTypes = {
  // @ts-ignore
  approval: PropTypes.any.isRequired,
};

export default ApprovalCard;
