import { FC, ReactElement, useState } from 'react';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { PayslipPartDto, PayslipRowDto } from 'src/types/apiSchemas';
import { SalaryCodeDescriptionMappings } from 'src/pages/dashboard/Payslip';
import { formatNumber } from 'src/utils/numberUtils';

interface PayslipDetailsProps {
  payslipPartDetails: PayslipPartDto;
  descriptionMappings?: SalaryCodeDescriptionMappings;
  formatNumbers?: boolean;
}

const PayslipDetailCard: FC<PayslipDetailsProps> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const screenSizeMid = useMediaQuery(theme.breakpoints.down('md'));
  const { payslipPartDetails, descriptionMappings, formatNumbers } = props;
  const [hasExtraCells] = useState<boolean>(payslipPartDetails?.items?.length > 0 && 'units' in payslipPartDetails.items.at(0));
  const makeExtraCells = (item: PayslipRowDto): ReactElement => {
    if (hasExtraCells) {
      const { unitRate, units, hourlyRate, hours } = item;
      return (
        <>
          <TableCell
            align="right"
            sx={{
              width: '50%',
              mx: 8,
              whiteSpace: 'nowrap'
            }}
          >
            <Typography>
              {units}
            </Typography>
          </TableCell>
          <TableCell
            align="right"
            sx={{
              width: '50%',
              mx: 8,
              whiteSpace: 'nowrap'
            }}
          >
            <Typography>
              {unitRate}
            </Typography>
          </TableCell>
          <TableCell
            align="right"
            sx={{
              width: '50%',
              mx: 8,
              whiteSpace: 'nowrap'
            }}
          >
            <Typography>
              {hours}
            </Typography>
          </TableCell>
          <TableCell
            align="right"
            sx={{
              width: '50%',
              mx: 8,
              whiteSpace: 'nowrap'
            }}
          >
            <Typography>
              {formatNumbers ? formatNumber(hourlyRate) : hourlyRate}
            </Typography>
          </TableCell>
        </>
      );
    }
    return <></>;
  };
  const handleBlankString = (s: string) => {
    if (s === '') {
      return '-';
    }
    return s;
  };

  return (
    <>
      <Typography
        color="textPrimary"
        variant="h5"
        sx={{
          mb: 1,
          mt: 1
        }}
      >
        {payslipPartDetails.title}
      </Typography>
      <Card
        variant="outlined"
      >
        {!screenSizeMid && (
          <Box sx={{ minWidth: 700 }}>
            <Table
              sx={{
                borderStyle: 'inset',
                borderLeftStyle: 'hidden',
                borderRightStyle: 'hidden',
                borderTopStyle: 'hidden',
                borderBottomStyle: 'hidden'
              }}
            >
              {hasExtraCells && (
                <TableHead>
                  <TableRow sx={{ mx: 50 }}>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell
                      align="right"
                      sx={{
                        mx: 5
                      }}
                    >
                      <Typography variant="tableHead">
                        {t('PayslipRateTitle')}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        mx: 5
                      }}

                    >
                      <Typography
                        variant="tableHead"
                        noWrap
                      >
                        {t('PayslipUnitTitle')}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        mx: 5
                      }}
                    >
                      <Typography variant="tableHead">
                        {t('PayslipAmountTitle')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}

              <TableBody>
                {payslipPartDetails.items.map((item, i) => (
                  <TableRow
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item}-${i}`}
                  >
                    <TableCell
                      sx={{ width: '50%' }}
                    >
                      <Typography>
                        {item.name}
                        {descriptionMappings && descriptionMappings[item.name]
                          && (
                            <Tooltip title={descriptionMappings[item.name]}>
                              <IconButton size="small">
                                <InfoIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                      </Typography>
                    </TableCell>
                    {makeExtraCells(item)}
                    <TableCell
                      align="right"
                      sx={{ width: '50%', whiteSpace: 'nowrap' }}
                    >
                      <Typography>
                        {formatNumbers ? formatNumber(item.value) : item.value}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
                {payslipPartDetails?.items?.length < 1 && (
                  <TableRow
                    key="no-data-available"
                  >
                    <TableCell
                      sx={{ width: '50%' }}
                    >
                      <Typography>
                        N/A
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ width: '50%' }}
                    >
                      <Typography />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        )}
        {screenSizeMid && (
          <Box sx={{ minWidth: 700 }}>
            <Table
              sx={{
                borderStyle: 'inset',
                borderLeftStyle: 'hidden',
                borderRightStyle: 'hidden',
                borderTopStyle: 'hidden',
                borderBottomStyle: 'hidden'
              }}
            >
              <TableHead>
                {payslipPartDetails.items.map((item) => (
                  <TableRow
                    key={item.name}
                  >
                    {hasExtraCells && (
                      <>
                        <TableCell
                          align="left"
                          sx={{ width: '100%' }}
                        >
                          <Typography>
                            {item.name}
                          </Typography>
                          <TableCell
                            sx={{
                              borderStyle: 'hidden'
                            }}
                          >
                            <Typography>
                              <Typography variant="tableHead">{t('PayslipRateTitle')}</Typography>
                            </Typography>
                            <Typography>
                              <Typography variant="tableHead">{t('PayslipUnitTitle')}</Typography>
                            </Typography>
                            <Typography>
                              <Typography variant="tableHead">{t('PayslipAmountTitle')}</Typography>
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              borderStyle: 'hidden'
                            }}
                          >
                            <Typography>
                              {handleBlankString(item.units + item.hours)}
                            </Typography>
                            <Typography>
                              {handleBlankString(item.unitRate + item.hourlyRate)}
                            </Typography>
                            <Typography>
                              {item.value}
                            </Typography>
                          </TableCell>
                        </TableCell>

                      </>
                    )}
                    {!hasExtraCells && (
                      <TableCell
                        sx={{ width: '100%' }}
                      >
                        <Typography>
                          {item.name}
                        </Typography>
                        <Typography>
                          {item.value}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableHead>
            </Table>
          </Box>
        )}
      </Card>
    </>
  );
};

export default PayslipDetailCard;
