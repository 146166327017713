import axios from 'axios';
import { ReleaseDtoPagedResultDto } from 'src/types/apiSchemas';
import { getBackendUrl } from 'src/utils/requestUtils';

const getReleases = async (pageNumber: number, pageSize: number, customerId: number): Promise<ReleaseDtoPagedResultDto> => {
  if (!customerId) return Promise.reject();
  const releasesEndpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/Releases/`;
  try {
    const response = await axios.get(releasesEndpointUrl,
      {
        params: { pageNumber, pageSize },
        responseType: 'json'
      });
    return await Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export default { getReleases };
