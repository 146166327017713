import { SiteUser } from 'src/types/siteUser';
import AzureAuthenticationContext from 'src/azure/azure-authentication-context';
import { AppStore } from 'src/store/mobx';
import { AuthenticationResult } from '@azure/msal-browser';
import { LoginInfoDto } from 'src/types/apiSchemas';
import { selectDistinct } from 'src/utils/arrayUtils';
import axios from 'axios';
import { getAuthHeader } from 'src/utils/requestUtils';
import { AuthorizationService, IAuthorizationService } from './authorizationService';
import { uiTheme } from 'src/utils/environmentUtils';
import { THEMES } from 'src/constants';

export interface IAuthenticationService {
  tryRefreshToken(user: SiteUser): Promise<boolean>;
  tryGetRefreshTokenInBackground(user: SiteUser): Promise<string>;
  acquireTokenSilent(userName: string): Promise<string>;
  refreshSiteUser(updateObject: ISiteUserUpdate): void;
}

export enum RefreshOperation {
  initialize = 'initialize',
  update = 'update'
}

export interface ISiteUserUpdate {
  userDto: LoginInfoDto;
  accessToken: string;
  operation: RefreshOperation;
}

export class AuthenticationService implements IAuthenticationService {
  private store: AppStore = null;
  private authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext();
  private auth: IAuthorizationService = new AuthorizationService();

  constructor(store: AppStore) {
    this.store = store;
  }

  public acquireTokenSilent = async (userName: string): Promise<string> => {
    const authResponse: AuthenticationResult = await this.authenticationModule.acquireTokenSilent(userName);
    axios.defaults.headers.common.Authorization = getAuthHeader(authResponse.accessToken);
    return authResponse.accessToken;
  };

  public tryRefreshToken = async (user: SiteUser): Promise<boolean> => {
    try {
      await this.tryGetRefreshTokenInBackground(user);
      return true;
    } catch (error) {
      console.error(`AuthenticationService::tryRefreshToken, ${error}`);
      return false;
    }
  };

  public tryGetRefreshTokenInBackground = async (user: SiteUser): Promise<string> => {
    if (!user?.email) {
      throw new Error('tryRefreshToken:: invalid input');
    }
    const token = await this.acquireTokenSilent(user.email);
    this.store.loginStore.login({ ...user, token });
    return token;
  };

  /**
   * to be used each time login information is fetched
   * to refresh the updated situation of user related objects to store (login related, settings related)
   * @param updateObject
   */
  public refreshSiteUser = (updateObject: ISiteUserUpdate): void => {
    const { userDto, accessToken, operation } = updateObject;

    const isMessagingConsultant = this.auth.get(userDto as SiteUser).isMessagingConsultant;

    try {
      const settings = this.store.settingsStore.get();
      const existingLogin = this.store.loginStore.get();

      if (!accessToken) {
        console.error(`User '${userDto.username}' token is empty`);
        throw new Error(`User '${userDto.username}' token is empty`);
      }

      // Ensure that at least one administrative company is found
      if (userDto.companies.length === 0) {
        console.error(`User '${userDto.username}' is not associated to any company`);
        throw new Error(`User '${userDto.username}' is not associated to any company`);
      }

      const uniqueAdministrativeCustomers = selectDistinct(userDto.companies, 'customerId');
      if (uniqueAdministrativeCustomers.length > 1 && operation === RefreshOperation.initialize) {
        console.warn(`User administers multiple customers with ids ${uniqueAdministrativeCustomers.map((c) => (c.customerId)).join(', ')} and should get a customer selection prompt next`);
      }

      let selectedCustomerId;
      let selectedCustomerName = '';

      const employeeCustomer = userDto.employmentCustomerId ? uniqueAdministrativeCustomers.find((c) => c.customerId === userDto.employmentCustomerId) : undefined;
      const defaultEmployeeCustomer = employeeCustomer ? employeeCustomer : uniqueAdministrativeCustomers[0];

      if (operation === RefreshOperation.update) {
        const previousSelectedCustomerId = existingLogin?.user?.customerId;

        // customerId 0 means user has selected "All customers" from the dropdown
        if (previousSelectedCustomerId === 0 && uniqueAdministrativeCustomers.length >= 1) {
          selectedCustomerId = 0;
          selectedCustomerName = '';
        } else {
          const previousSelectedCustomer = uniqueAdministrativeCustomers.find((c) => c.customerId === previousSelectedCustomerId);

          if (!previousSelectedCustomer) {
            selectedCustomerId = defaultEmployeeCustomer.customerId;
            selectedCustomerName = defaultEmployeeCustomer.customerName;
          } else {
            selectedCustomerId = previousSelectedCustomer.customerId;
            selectedCustomerName = previousSelectedCustomer.customerName;
          }
        }
      } else if (operation === RefreshOperation.initialize) {
        if (!isMessagingConsultant) {
          selectedCustomerId = defaultEmployeeCustomer.customerId;
          selectedCustomerName = defaultEmployeeCustomer.customerName;
        }
      }

      const user: SiteUser = {
        email: userDto.username,
        name: userDto.name,
        avatar: userDto.profilePictureUrl,
        token: accessToken,
        customerId: selectedCustomerId,
        customerName: selectedCustomerName,
        userId: userDto.userId,
        notificationPreference: userDto.notificationPreference,
        languageCode: userDto.languageCode,
        companies: userDto.companies,
        accessPermissions: userDto.accessPermissions,
        lastLoginTimeUTC: userDto.lastLoginTimeUTC,
        isConsultantUser: userDto.isConsultantUser,
        employmentCustomerId: userDto.employmentCustomerId,
        employmentCustomerName: defaultEmployeeCustomer?.customerName,
        managerSubstitutions: userDto.managerSubstitutions,
        // no need for substitutionManagers for now
        // substitutionManagers: userDto.substitutionManagers,
        customerInternalRoles: userDto.customerInternalRoles,
        customerUserRoles: userDto.customerUserRoles,
        isManager: userDto.isManager
      };

      const willShowCustomerSelection = (uniqueAdministrativeCustomers?.length > 1 || isMessagingConsultant) && operation === RefreshOperation.initialize;
      this.store.loginStore.login(user, willShowCustomerSelection);

      // setting UI language according to users preference & getting refreshed notification counts
      // necessary, if the information is expected to have changed and user refreshes the browser
      this.store.settingsStore.set({ ...settings, language: userDto?.languageCode, theme: uiTheme === THEMES.ACCOUNTOR ? THEMES.ACCOUNTOR : userDto?.siteTheme?.toUpperCase() });
      this.store.notificationStore.refreshAllNotifications(user);
    } catch (error) {
      throw new Error(error);
    }
  };
}
