import { FC } from 'react';
import { TransactionDto } from 'src/types/apiSchemas';
import PropTypes from 'prop-types';
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card
} from '@mui/material';
import { nanoid } from 'nanoid';
import { formatNumber } from 'src/utils/numberUtils';

interface PayslipHeaderProps {
  transaction: TransactionDto;
}

const PayslipHeader: FC<PayslipHeaderProps> = (props: PayslipHeaderProps) => {
  const { transaction } = props;
  if (!transaction) { return null; }

  return (
    <Card
      variant="outlined"
    // sx={{ width: '50%' }}
    >
      <Table
        sx={{
          borderStyle: 'inset',
          borderLeftStyle: 'hidden',
          borderRightStyle: 'hidden',
          borderTopStyle: 'hidden',
          borderBottomStyle: 'hidden'
        }}
      >
        <TableBody>
          <TableRow key={nanoid()}>
            <TableCell sx={{ width: '25%' }}>
              <Typography
                color="textPrimary"
                variant="h6"
                sx={{
                  mb: 1,
                  mt: 1
                }}
              >
                {transaction.recipient}
              </Typography>
            </TableCell>
            <TableCell sx={{ width: '75%' }} />
          </TableRow>
          <TableRow key={nanoid()}>
            <TableCell sx={{ width: '25%' }}>
              <Typography
                color="textPrimary"
                sx={{
                  mb: 3,
                  mt: 2,
                }}
              >
                {transaction.description}
              </Typography>
            </TableCell>
            <TableCell sx={{ width: '75%' }}>
              <Typography
                color="textPrimary"
                sx={{
                  mb: 3,
                  mt: 2
                }}
              >
                {formatNumber(transaction.netAmount)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

PayslipHeader.propTypes = {
  transaction: PropTypes.any.isRequired
};

export default PayslipHeader;
