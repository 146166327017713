import { Avatar } from '@mui/material';
import { getInitials } from 'src/utils/getInitial';

const UserDisplay = ({ displayName, imageUrl }: { displayName: string, imageUrl?: string }) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
    <div>
      <Avatar
        sx={{
          backgroundColor: 'success.main',
          color: '#ffffff'
        }}
        src={imageUrl}
        alt={displayName}
      >
        {getInitials(displayName)}
      </Avatar>
    </div>
    <div>{displayName}</div>
  </div>
);

export default UserDisplay;
