import { MenuItem, Select, SelectChangeEvent, darken, useTheme } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { getComponentStyle } from 'src/utils/themeUtils';


const Handle = styled.div<{ padding: number, openOnClick?: boolean }>`
  width: 35px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  opacity: 0.2;
  cursor: pointer;

  > div {
    width: 100%;
    height: 100%;
    border-top-right-radius: ${(props) => props.padding + 8}px;
    border-bottom-right-radius: ${(props) => props.padding + 8}px;
  }

  pointer-events: none;
  ${(props) => !props.openOnClick && `
  pointer-events: auto;
  &:hover {
    > div {
      background: #fff;
    }
  }`}

`;

interface Option {
  value: string;
  label: string;
}

interface ButtonDropDownProps {
  value: '' | any;
  onClick?: (e: Event) => void;
  onChange: (e: SelectChangeEvent, child: ReactNode) => void;
  options: Option[];
  disabled?: boolean;
  padding?: number;
  style?: any;
  openOnClick?: boolean;
  dropDownStyles?: any;
  handleStyles?: any;
  onItemClick?: (value: any) => void;
  styleVariant?: string;
}

interface ThemeStyles {
  backgroundColor: string;
  color: string;
  border: string;
}

const ButtonDropDown: FC<ButtonDropDownProps> = ({ value, onClick, onChange, options, padding = 8, disabled, openOnClick, style, dropDownStyles, handleStyles, onItemClick, styleVariant }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const styleSheet = {
    backgroundColor: theme.palette.primary.mainButton,
    color: '#fff',
    border: '0'
  };

  const resolveStyle = (): ThemeStyles => {
    switch (styleVariant) {
      case 'skinny':
        const s = getComponentStyle(theme, 'MuiButton', 'skinny', styleSheet);
        return {
          backgroundColor: s?.backgroundColor,
          color: s?.color,
          border: s?.border
        };
      default:
        return {
          backgroundColor: styleSheet.backgroundColor,
          color: styleSheet.color,
          border: styleSheet.border
        };
    }
  };

  const themeStyle = resolveStyle();

  return (
    <div style={{ display: 'inline-flex', position: 'relative' }}>
      <Select
        disabled={disabled}
        onClose={() => {
          if (!disabled) {
            setOpen(false);
          }
        }}
        disableUnderline
        value={value}
        onOpen={(e) => {
          if (!disabled) {
            if (openOnClick) {
              setOpen(true);
            } else {
              onClick(e.nativeEvent);
            }
          }
        }}
        onChange={onChange}
        open={open}
        variant="filled"
        sx={{
          color: themeStyle.color,
          textTransform: theme.typography.button.textTransform,
          fontWeight: theme.typography.button.fontWeight,
          fontFamily: theme.typography.button.fontFamily,
          fontSize: theme.typography.button.fontSize,
          border: themeStyle.border,
          backgroundColor: themeStyle.backgroundColor,
          borderRadius: `${padding + 8}px`,
          '>.MuiSelect-filled': {
            padding: `${padding}px ${padding + 8}px`,
            paddingRight: '45px !important',
            borderRadius: `${padding}px !important`
          },
          ...disabled ? {} : {
            ':hover': {
              backgroundColor: darken(themeStyle.backgroundColor, 0.3),
            },
            '&.Mui-focused': {
              backgroundColor: darken(themeStyle.backgroundColor, 0.3),
            }
          },
          'svg': {
            color: themeStyle.color
          },
          ...style
        }}
        inputProps={{
          MenuProps: {
            MenuListProps: {
              sx: {
                backgroundColor: themeStyle.backgroundColor,
                '&& .Mui-selected, .Mui-selected:hover': {
                  backgroundColor: darken(themeStyle.backgroundColor, 0.3),
                },
                ...dropDownStyles
              }
            }
          }
        }}
      >
        {options.map((item) => (

          <MenuItem
            key={item.value}
            value={item.value}
            style={{ color: themeStyle.color }}
            onClick={ () => {
              if (onItemClick) {
                onItemClick(item.value);
                setOpen(false);
              }
              
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {!disabled && (
        <Handle
          onClick={() => {
            setOpen((val) => !val);
          }}
          role="button"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setOpen((val) => !val);
            }
          }}
          tabIndex={0}
          aria-label="Open"
          padding={padding}
          openOnClick={openOnClick}
          style={handleStyles}
        >
          <div />
        </Handle>
      )}
    </div>
  );
};

export default ButtonDropDown;
