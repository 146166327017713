import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { PaymentDetailDto, PaymentStatus } from 'src/types/apiSchemas';
import { useTranslation } from 'react-i18next';
import { getInitial } from 'src/utils/getInitial';
import { format } from 'date-fns';
import { formatNumber } from 'src/utils/numberUtils';

interface PaymentDetailsProps {
  payment: PaymentDetailDto;
}

const PaymentDetailCard: FC<PaymentDetailsProps> = (props) => {
  const { payment, ...other } = props;
  const { t } = useTranslation();

  const typeTranslator = (type: string) => (type === 'Salary'
    ? t('PaymentTypeSalary')
    : t('PaymentTypeOther'));

  const statusTranslator = (status: PaymentStatus) => {
    if (status === 'Pending') {
      return <Typography>{t('PaymentsNavigationPending')}</Typography>;
    }
    return status === 'Completed'
      ? <Typography color="success.main">{t('PaymentsNavigationCompleted')}</Typography>
      : <Typography color="error.main">{t('PaymentsNavigationDeclined')}</Typography>;
  };

  return (
    <Card {...other}>
      <CardHeader title={t('PaymentTitle')} />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {t('PaymentCompanyTitle')}
              </Typography>
            </TableCell>
            <TableCell>
              <Avatar
                src={payment.companyLogoUrl}
                sx={{
                  height: 42,
                  width: 42
                }}
              >
                {getInitial(payment.ownerName)}
              </Avatar>
              <Typography>{payment.ownerName}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {t('PaymentTransactionTitle')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{payment.transactionCount}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {t('PaymentDateTitle')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{payment.paymentDate ? format(new Date(payment.paymentDate), 'dd.MM.yyyy') : payment.date.substr(0, 10)}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {t('PaymentAmount')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{formatNumber(payment.amount)}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {t('PaymentsPaymentStatusTitle')}
              </Typography>
            </TableCell>
            <TableCell>
              {statusTranslator(payment.paymentStatus)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {t('PaymentType')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{typeTranslator(payment.paymentType)}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

PaymentDetailCard.propTypes = {
  // @ts-ignore
  payment: PropTypes.object.isRequired
};

export default PaymentDetailCard;
