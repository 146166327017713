import { hotjarId } from './environmentUtils';

const loadHotjarAnalytics = () => {
  const hotjarEmbedId = 'hotjarEmbed';
  const existingScript = document.getElementById(hotjarEmbedId);
  if (!existingScript && hotjarId) {
    const headElement = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.innerText = `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:${hotjarId},hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
    script.id = hotjarEmbedId;
    headElement.appendChild(script);
  }
  if (!hotjarId) {
    console.warn('hotjar id not configured!');
  }
};
export default loadHotjarAnalytics;
