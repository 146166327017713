import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { WhiteOutlineButton } from 'src/components/WhiteOutlineButton';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import DeleteAnnouncementModal from './DeleteAnnouncementModal';
import CommonModal from 'src/components/common/CommonModal';

interface CreateAnnouncementHeaderProps {
  formValues: any,
  icon: any;
  loading: boolean;
  disabled: boolean;
  submit: any;
  announcementId?: number;
}

const CreateAnnouncementHeader: FC<CreateAnnouncementHeaderProps> = (props) => {
  const { formValues, icon, loading, disabled, submit, announcementId } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid
        item
        container
        wrap="nowrap"
        justifyContent="flex-start"
        alignItems="center"
        zeroMinWidth
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ maxHeight: 45, maxWidth: 45, minHeight: 45, minWidth: 45 }}
        >
          {icon}
        </Grid>
        <Typography
          color={formValues.subjectFi === '' ? 'textSecondary' : 'textPrimary'}
          fontSize={26}
          fontWeight={600}
          sx={{ mx: 2 }}
          noWrap
        >
          {formValues.subjectFi === '' ? t('CreateAnnouncementNewAnnouncementHeaderTitle') : formValues.subjectFi}
        </Typography>
      </Grid>
      <Grid
        xs
        item
        container
        columnSpacing={1}
        wrap="nowrap"
      >
        {announcementId && (
          <Grid item>
            <WhiteOutlineButton
              aria-label="close"
              onClick={() => setDeleteModalOpen(true)}
              variant="outlined"
              disabled={loading}
            >
              {t('EditAnnouncementHeaderDeleteButton')}
            </WhiteOutlineButton>
          </Grid>
        )}
        <Grid item>
          <WhiteOutlineButton
            aria-label="close"
            onClick={() => { navigate(-1); }}
            variant="outlined"
            disabled={loading}
          >
            {t('CreateAnnouncementHeaderBackButton')}
          </WhiteOutlineButton>
        </Grid>
        <Grid item>
          <LoadingButton
            aria-label="close"
            onClick={submit}
            variant="contained"
            loading={loading}
            disabled={disabled}
          >
            {t('CreateAnnouncementHeaderPreviewButton')}
          </LoadingButton>
        </Grid>
        <CommonModal
          width={315}
          open={deleteModalOpen}
          onClose={(e: object, reason: string) => {
            switch (reason) {
              case 'backdropClick':
                break;
              default:
                setDeleteModalOpen(false);
                break;
            }
          }}
        >
          <DeleteAnnouncementModal
            announcementId={announcementId}
            closeModal={() => setDeleteModalOpen(false)}
          />
        </CommonModal>
      </Grid>
    </Grid>
  );
};

CreateAnnouncementHeader.propTypes = {
  // @ts-ignore
  formValues: PropTypes.any.isRequired,
  icon: PropTypes.any.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  submit: PropTypes.any.isRequired
};

export default CreateAnnouncementHeader;
