import { jwtDecode } from 'jwt-decode';
import { aadAppClientId, externalIdAppId } from './environmentUtils';

export class ExpiredTokenError extends Error { }

export const isTokenValid = (authToken: string): boolean => {
  try {
    const payload = jwtDecode<any>(authToken);
    if (Date.now() >= payload.exp * 1000) {
      return false;
    }
    return true;
  } catch (error) {
    console.warn(`isTokenValid: cannot parse token ${error}`);
    return false;
  }
};

export const isTokenAuthorizedPartyValid = (authToken: string): boolean => {
  try {
    const payload = jwtDecode<any>(authToken);

    if (payload?.azp !== aadAppClientId && payload?.azp !== externalIdAppId) {
      return false;
    }

    return true;
  } catch (error) {
    console.warn(`isTokenAuthorizedPartyValid: cannot parse token ${error}`);
    return false;
  }
};

export const validateAccessToken = (authToken: string) => {
  if (!authToken) {
    throw new Error('Token is empty.');
  }

  if (!isTokenValid(authToken)) {
    throw new ExpiredTokenError('Expired token');
  }

  if (!isTokenAuthorizedPartyValid(authToken)) {
    throw new Error('You do not have the rights to use this site.');
  }
};
