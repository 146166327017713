import { FC, ReactNode } from 'react';
import { AppStore, AppStoreContext } from './appStore';

interface AppStoreProviderProps {
  children: ReactNode;
  store: AppStore;
}

export const AppStoreProvider: FC<AppStoreProviderProps> = (props) => {
  const { store, children } = props;

  return <AppStoreContext.Provider value={store}>{children}</AppStoreContext.Provider>;
};
