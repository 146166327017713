import { Table, TableBody, TableRow, TableCell, TextField, Typography, Button, Container, CircularProgress, Box, useTheme, useMediaQuery, Link } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useEffect, useMemo, useState } from 'react';
import { baseApi } from 'src/API/baseApi';
import { useMounted } from 'src/hooks';
import { useAppStore } from 'src/store/mobx';
import { InternalRole, MessageThreadVisibility, MessageThreadVisibilityParameters, MessageThreadsResponse, UserReference, UserRole } from 'src/types/apiSchemas';
import CommonModal from 'src/components/common/CommonModal';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import RoleDisplay from './RoleDisplay';
import UserDisplay from './UserDisplay';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import CommonDialog from 'src/components/common/CommonDialog';
import { IAuthorizationService, AuthorizationService } from 'src/Services';

interface MessageThreadVisibilityModalProps {
  messageThreadId?: string;
  messageThread?: MessageThreadsResponse;
  customerId: number;
  onClose: () => void;
  onVisibilityUpdate: (visibility: MessageThreadVisibility) => void;
  initialVisibility?: MessageThreadVisibility;
}

const MessageThreadVisibilityModal: FC<MessageThreadVisibilityModalProps> = observer(({ messageThreadId, messageThread, onClose, onVisibilityUpdate, customerId, initialVisibility }) => {
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const auth: IAuthorizationService = new AuthorizationService();
  const [visibility, setVisibility] = useState<MessageThreadVisibility>(initialVisibility ?? {
    userRoles: [],
    internalRoles: [],
    users: [],
    managerHasAccess: false
  });
  const [visibilityParameters, setVisibilityParameters] = useState<MessageThreadVisibilityParameters>({
    userRoles: [],
    internalRoles: [],
    users: [],
  });
  const [filter, setFilter] = useState('');
  const [isInitialising, setIsInitialising] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useMounted();
  const theme = useTheme();
  const isLargeView = useMediaQuery(theme.breakpoints.down('lg'));
  const endCellWidth = 400;
  const [roleDetailsId, setRoleDetailsId] = useState<InternalRole | UserRole>(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [userRemoval, setUserRemoval] = useState(false);
  const [elementToBeDeleted, setElementToBeDeleted] = useState(null);
  const [visibilityUsersConsultancyStatus, setVisibilityUsersConsultancyStatus] = useState({});
  const { t } = useTranslation();
  const nav = useNavigate();

  const isCustomerHR = auth.get(user).is(UserRole.HR, messageThread?.customerId).verify();
  const isCustomerTechnical = auth.get(user).is(UserRole.Technical, messageThread?.customerId).verify();
  const isCustomerFinance = auth.get(user).is(UserRole.Finance, messageThread?.customerId).verify();
  const isCustomerMainUser = auth.get(user).is(UserRole.MainUser, messageThread?.customerId).verify();
  const canAddInternalRoles = user.isConsultantUser || isCustomerHR || isCustomerTechnical || isCustomerFinance || isCustomerMainUser;

  const handleCloseDialog = () => {
    setUserRemoval(false);
  };

  const handleOpenDialog = (r) => {
    setElementToBeDeleted(r);
    setUserRemoval(true);
  };

  const getVisibility = async () => {
    if (messageThreadId) {
      const response = await baseApi.getMessageThreadVisibility(messageThreadId);
      if (isMounted) {
        setVisibility(response);
      }
    }
  };

  const getVisibilityParameters = async (userNameFilter?: string) => {
    const response = await baseApi.getMessageThreadVisibilityParameters(customerId, userNameFilter);
    if (isMounted) {
      setVisibilityParameters(response);
    }
  };

  const getVisibilityUsersConsultancyStatus = async () => {
    if (!user.isConsultantUser) {
      const response = await baseApi.isConsultancyEnabledForUserIds(visibility.users.map(u => u.id));
      if (isMounted) {
        setVisibilityUsersConsultancyStatus(response);
      }
    }
  };

  useEffect(() => {
    onVisibilityUpdate(visibility);
    getVisibilityUsersConsultancyStatus();
  }, [visibility]);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (filter.length > 2) {
        setIsLoadingUsers(true);
        try {
          await getVisibilityParameters(filter);
        } catch (err) {
          console.log(err);
        }
      } else {
        setVisibilityParameters((prev) => ({ ...prev, users: [] }));
      }
      setIsLoadingUsers(false);
    }, 700);
    return () => clearTimeout(timeout);
  }, [filter]);

  useEffect(() => {
    const init = async () => {
      setIsInitialising(true);
      await Promise.allSettled([getVisibility(), getVisibilityParameters()]);
      setIsInitialising(false);
    };
    init();
  }, [messageThreadId]);

  const addInternalRole = async (role: InternalRole) => {
    setIsLoading(true);
    if (messageThreadId) {
      await baseApi.updateMessageThreadVisibility(messageThreadId, { ...visibility, internalRoles: [...visibility.internalRoles, role] });
      await getVisibility();
    } else {
      setVisibility((prev) => ({ ...prev, internalRoles: [...prev.internalRoles, role] }));
    }

    setIsLoading(false);
  };

  const removeInternalRole = async (role: InternalRole) => {
    try {
      setIsLoading(true);
      if (messageThreadId) {
        await baseApi.updateMessageThreadVisibility(messageThreadId, { ...visibility, internalRoles: [...visibility.internalRoles.filter((r) => r !== role)] });
        toast.success(t('VisibilityRemovalSuccessToast'));
        await getVisibility();
        handleCloseDialog();
      } else {
        setVisibility((prev) => ({ ...prev, internalRoles: [...prev.internalRoles.filter((r) => r !== role)] }));
      }
    } catch (err: unknown) {
      const error = err as AxiosError;
      if (error?.response?.status === (401)) {
        nav('/dashboard/messages');
        console.log(err);
      }
    }
    setIsLoading(false);
  };

  const addUserRole = async (role: UserRole) => {
    setIsLoading(true);
    if (messageThreadId) {
      await baseApi.updateMessageThreadVisibility(messageThreadId, { ...visibility, userRoles: [...visibility.userRoles, role] });
      await getVisibility();
    } else {
      setVisibility((prev) => ({ ...prev, userRoles: [...prev.userRoles, role] }));
    }
    setIsLoading(false);
  };

  const removeUserRole = async (role: UserRole) => {
    try {
      setIsLoading(true);
      if (messageThreadId) {
        await baseApi.updateMessageThreadVisibility(messageThreadId, { ...visibility, userRoles: [...visibility.userRoles.filter((r) => r !== role)] });
        toast.success(t('VisibilityRemovalSuccessToast'));
        await getVisibility();
        handleCloseDialog();
      } else {
        setVisibility((prev) => ({ ...prev, userRoles: [...prev.userRoles.filter((r) => r !== role)] }));
      }
    } catch (err: unknown) {
      const error = err as AxiosError;
      if (error?.response?.status === (401)) {
        nav('/dashboard/messages');
        console.log(err);
      }
    }

    setIsLoading(false);
  };

  const addUser = async (userId: number, userName: string) => {
    setIsLoading(true);
    if (messageThreadId) {
      await baseApi.updateMessageThreadVisibility(messageThreadId, { ...visibility, users: [...visibility.users, { id: userId }] });
      await getVisibility();
    } else {
      setVisibility((prev) => ({ ...prev, users: [...prev.users, { id: userId, name: userName }] }));
    }

    setIsLoading(false);
  };

  const removeUser = async (userId: number) => {
    try {
      setIsLoading(true);
      if (messageThreadId) {
        await baseApi.updateMessageThreadVisibility(messageThreadId, { ...visibility, users: [...visibility.users.filter((u) => u.id !== userId)] });
        toast.success(t('VisibilityRemovalSuccessToast'));
        await getVisibility();
        handleCloseDialog();
      } else {
        setVisibility((prev) => ({ ...prev, users: [...prev.users.filter((u) => u.id !== userId)] }));
      }
    } catch (err: unknown) {
      const error = err as AxiosError;
      if (error?.response?.status === (401)) {
        nav('/dashboard/messages');
        console.log(err);
      }
    }
    setIsLoading(false);
  };

  const addManagers = async () => {
    setIsLoading(true);
    if (messageThreadId) {
      await baseApi.updateMessageThreadVisibility(messageThreadId, { ...visibility, managerHasAccess: true });
      await getVisibility();
    } else {
      setVisibility((prev) => ({ ...prev, managerHasAccess: true }));
    }

    setIsLoading(false);
  };

  const removeManagers = async () => {
    try {
      setIsLoading(true);
      if (messageThreadId) {
        await baseApi.updateMessageThreadVisibility(messageThreadId, { ...visibility, managerHasAccess: false });
        toast.success(t('VisibilityRemovalSuccessToast'));
        await getVisibility();
        handleCloseDialog();
      } else {
        setVisibility((prev) => ({ ...prev, managerHasAccess: false }));
      }
    } catch (err: unknown) {
      const error = err as AxiosError;
      if (error?.response?.status === (401)) {
        nav('/dashboard/messages');
        console.log(err);
      }
    }
    setIsLoading(false);
  };

  const userRolesToBeAdded = useMemo(() => visibilityParameters.userRoles.filter((r) => !visibility.userRoles.some((ur) => ur === r.userRole)), [visibilityParameters, visibility]);
  const internalRolesToBeAdded = useMemo(() => visibilityParameters.internalRoles.filter((r) => !visibility.internalRoles.some((ir) => ir === r.internalRole)), [visibilityParameters, visibility]);

  const usersToBeAdded: UserReference[] = useMemo(() => visibilityParameters.users.reduce((acc, cur) => {
    const hasVisibility = visibility.users.some((u) => u.id === cur.id) || visibilityParameters.internalRoles.some((r) => visibility.internalRoles.includes(r.internalRole) && r.users.some((u) => u.id === cur.id))
      || visibilityParameters.userRoles.some((r) => visibility.userRoles.includes(r.userRole) && r.users.some((u) => u.id === cur.id));
    return hasVisibility ? acc : [...acc, cur];
  }, []), [visibilityParameters, visibility]);

  if (isInitialising) {
    return <CircularProgress sx={{ display: 'block', margin: 'auto', mt: 20 }} />;
  }

  const roleDetailsUsers = roleDetailsId in InternalRole ? visibilityParameters.internalRoles.find((r) => r.internalRole === roleDetailsId)?.users : visibilityParameters.userRoles.find((r) => r.userRole === roleDetailsId)?.users;

  const buttonRenderCheck = () => {
    if (visibility.internalRoles.length + visibility.userRoles.length + visibility.users.length === 1 && !visibility.managerHasAccess) {
      return false;
    }
    if (visibility.internalRoles.length + visibility.userRoles.length + visibility.users.length === 0 && visibility.managerHasAccess) {
      return false;
    }
    return true;
  };

  const isRemovingOwnVisibility = (visibilityToBeRemoved: any) => {
    if (messageThread.starterName === user.name) {
      return false;
    }
    const userCustomerRoles = user.customerUserRoles.find((r) => r.customerId === messageThread.customerId)?.userRoles ?? [];
    const userCustomerInternalRoles = user.customerInternalRoles.find((r) => r.customerId === messageThread.customerId)?.internalRoles ?? [];
    const userGlobalInternalRoles = user.customerInternalRoles.find((r) => r.customerId === null)?.internalRoles ?? [];

    const hasVisibilityBecauseRoles = userCustomerRoles.filter((r) => visibility.userRoles.includes(r));
    const hasVisibilityBecauseInternalRoles = [...userCustomerInternalRoles, ...userGlobalInternalRoles].filter((r) => visibility.internalRoles.includes(r));
    const hasVisibilityBecauseUser = visibility.users.find((u) => u.id === user.userId) ? [user.userId] : [];
    const hasVisibilityBecauseIsManager = visibility.managerHasAccess && messageThread.isSubordinateThread ? ['managerHasAccess'] : [];

    const combinedVisibility = [
      ...hasVisibilityBecauseRoles,
      ...hasVisibilityBecauseInternalRoles,
      ...hasVisibilityBecauseUser,
      ...hasVisibilityBecauseIsManager
    ];

    if (combinedVisibility.length === 1) {
      if (Object.values(UserRole).includes(visibilityToBeRemoved)) {
        return hasVisibilityBecauseRoles.includes(visibilityToBeRemoved);
      }
      if (Object.values(InternalRole).includes(visibilityToBeRemoved)) {
        return hasVisibilityBecauseInternalRoles.includes(visibilityToBeRemoved);
      }
      if (visibilityToBeRemoved === 'managerHasAccess') {
        return hasVisibilityBecauseIsManager.includes('managerHasAccess');
      }
      if ('id' in visibilityToBeRemoved) {
        return hasVisibilityBecauseUser.includes(visibilityToBeRemoved.id);
      }
    }
    return false;
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        ...!messageThreadId ? { padding: 0 } : {}
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{
          position: 'sticky',
          // eslint-disable-next-line no-nested-ternary
          top: !messageThreadId ? -1 : isLargeView ? 0 : 80,
          backgroundColor: theme.palette.background.default,
          zIndex: 10
        }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            height="56px"
          >
            <div />
            <Link
              color="textPrimary"
              sx={{
                textDecoration: 'none',
                display: 'inline-flex',
                alignItems: 'center',
                mt: 3,
                cursor: 'pointer'
              }}
              variant="h6"
              onClick={onClose}
            >
              <CloseIcon sx={{ mr: 1 }} />
              {' '}
              {t('GenericButtonClose')}
            </Link>
          </Box>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: 24 }}>
          <div style={{ flex: 1 }}>
            {/* eslint-disable-next-line no-nested-ternary */}
            <Box sx={{ position: 'sticky', top: !messageThreadId ? 55 : isLargeView ? 56 : 136, padding: '24px 0', }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Typography variant="h5">
                  {t('MessagesPageVisibility')}
                </Typography>
              </Box>
              <Table sx={{ mt: 2 }}>
                <TableBody sx={{ border: '1px solid', borderColor: 'primary.tableBorderColor', whiteSpace: 'nowrap' }}>
                  {visibility.internalRoles.map((r) => {
                    const users = visibilityParameters.internalRoles.find((ir) => ir.internalRole === r)?.users;
                    return (
                      <TableRow key={r}>
                        <TableCell><RoleDisplay role={r} /></TableCell>
                        <TableCell sx={{ width: endCellWidth }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '16px' }}>
                            <Link
                              onClick={() => {
                                setRoleDetailsId(r);
                              }}
                              sx={{ color: 'inherit', textDecoration: 'none', cursor: 'pointer', ':hover': { textDecoration: 'underline' } }}
                            >
                              <Typography>
                                {users?.length}
                                {' '}
                                {users?.length === 1 ? t('MessagesPagePerson') : t('MessagesPagePersons')}
                              </Typography>
                            </Link>
                            {buttonRenderCheck() && (
                              <Button
                                variant="contained"
                                sx={{ minWidth: '100px' }}
                                onClick={() => (messageThreadId && isRemovingOwnVisibility(r) ? handleOpenDialog(r) : removeInternalRole(r))}
                                disabled={isLoading || !user.isConsultantUser}
                              >
                                {t('MessagesPageRemove')}
                              </Button>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {visibility.userRoles.map((r) => {
                    const users = visibilityParameters.userRoles.find((ur) => ur.userRole === r)?.users;
                    return (
                      <TableRow key={r}>
                        <TableCell><RoleDisplay role={r} /></TableCell>
                        <TableCell sx={{ width: endCellWidth }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '16px' }}>
                            <Link
                              onClick={() => {
                                setRoleDetailsId(r);
                              }}
                              sx={{ color: 'inherit', textDecoration: 'none', cursor: 'pointer', ':hover': { textDecoration: 'underline' } }}
                            >
                              <Typography>
                                {users?.length}
                                {' '}
                                {users?.length === 1 ? t('MessagesPagePerson') : t('MessagesPagePersons')}
                              </Typography>
                            </Link>
                            {buttonRenderCheck() && (
                              <Button
                                variant="contained"
                                sx={{ minWidth: '100px' }}
                                onClick={() => (messageThreadId && isRemovingOwnVisibility(r) ? handleOpenDialog(r) : removeUserRole(r))}
                                disabled={isLoading}
                              >
                                {t('MessagesPageRemove')}
                              </Button>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {visibility.managerHasAccess && (
                    <TableRow>
                      <TableCell><UserDisplay displayName={t('ApprovalsManager')} /></TableCell>
                      <TableCell sx={{ width: endCellWidth, textAlign: 'right' }}>
                        {buttonRenderCheck() && (
                          <Button
                            variant="contained"
                            sx={{ minWidth: '100px' }}
                            onClick={messageThreadId && isRemovingOwnVisibility('managerHasAccess') ? handleOpenDialog : removeManagers}
                            disabled={isLoading}
                          >
                            {t('MessagesPageRemove')}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                  {visibility.users.map((u) => (
                    <TableRow key={u.id}>
                      <TableCell>
                        <UserDisplay
                          displayName={u.name}
                          imageUrl={u.profilePictureUrl}
                        />
                      </TableCell>
                      <TableCell sx={{ width: endCellWidth, textAlign: 'right' }}>
                        {buttonRenderCheck() && (
                          <Button
                            variant="contained"
                            sx={{ minWidth: '100px' }}
                            onClick={() => (messageThreadId && isRemovingOwnVisibility(u) ? handleOpenDialog(u) : removeUser(u.id))}
                            disabled={isLoading || (Object.keys(visibilityUsersConsultancyStatus).length === 0 && !user.isConsultantUser) || (visibilityUsersConsultancyStatus[u?.id] && !user.isConsultantUser)}
                          >
                            {t('MessagesPageRemove')}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </div>
          <div style={{ flex: 1 }}>
            {/* eslint-disable-next-line no-nested-ternary */}
            <Box sx={{ position: 'sticky', top: !messageThreadId ? 55 : isLargeView ? 56 : 136, backgroundColor: 'background.default', zIndex: 10, padding: '24px 0 ' }}>
              <Typography variant="h5">{t('MessagesPageAddToThread')}</Typography>
              <TextField
                autoComplete="off"
                value={filter}
                placeholder={t('MessagesPageVisibilitySearch')}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                fullWidth
                sx={{ mt: 2 }}
              />
              <Typography
                variant="body2"
                sx={{ opacity: 0.8, mt: 1 }}
              >
                {t('MessagesPagePersonSearchInstruction')}
              </Typography>
            </Box>
            {canAddInternalRoles && (
              <><Typography variant="h7">{t('MessagesPageInternalRoles')}</Typography>
                <Table sx={{ mt: 2, mb: 4 }}>
                  <TableBody sx={{ border: '1px solid', borderColor: 'primary.tableBorderColor', whiteSpace: 'nowrap' }}>
                    {internalRolesToBeAdded.filter((r) => !filter || r.internalRole.toLowerCase().includes(filter.toLowerCase())).map((r) => (
                      <TableRow key={r.internalRole}>
                        <TableCell><RoleDisplay role={r.internalRole} /></TableCell>
                        <TableCell sx={{ width: endCellWidth }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '16px' }}>
                            <Link
                              onClick={() => {
                                setRoleDetailsId(r.internalRole);
                              } }
                              sx={{ color: 'inherit', textDecoration: 'none', cursor: 'pointer', ':hover': { textDecoration: 'underline' } }}
                            >
                              <Typography>
                                {r.users.length}
                                {' '}
                                {r.users.length === 1 ? t('MessagesPagePerson') : t('MessagesPagePersons')}
                              </Typography>
                            </Link>
                            <Button
                              variant="contained"
                              color="success"
                              sx={{ minWidth: '100px' }}
                              onClick={() => {
                                addInternalRole(r.internalRole);
                              } }
                              disabled={isLoading}
                            >
                              {t('MessagesPageAdd')}
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
            <Typography variant="h7">{t('MessagesPageCustomerRolesAndPersons')}</Typography>
            <Table sx={{ mt: 2, mb: 4 }}>
              <TableBody sx={{ border: '1px solid', borderColor: 'primary.tableBorderColor', whiteSpace: 'nowrap' }}>
                {userRolesToBeAdded.filter((r) => !filter || r.userRole.toLowerCase().includes(filter.toLowerCase())).map((r) => (
                  <TableRow key={r.userRole}>
                    <TableCell><RoleDisplay role={r.userRole} /></TableCell>
                    <TableCell sx={{ width: endCellWidth }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '16px' }}>
                        <Link
                          onClick={() => {
                            setRoleDetailsId(r.userRole);
                          }}
                          sx={{ color: 'inherit', textDecoration: 'none', cursor: 'pointer', ':hover': { textDecoration: 'underline' } }}
                        >
                          <Typography>
                            {r.users.length}
                            {' '}
                            {r.users.length === 1 ? t('MessagesPagePerson') : t('MessagesPagePersons')}
                          </Typography>
                        </Link>
                        <Button
                          variant="contained"
                          color="success"
                          sx={{ minWidth: '100px' }}
                          onClick={() => {
                            addUserRole(r.userRole);
                          }}
                          disabled={isLoading}
                        >
                          {t('MessagesPageAdd')}
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
                {!visibility.managerHasAccess && (!filter || t('ApprovalsManager').toLowerCase().includes(filter.toLowerCase()))
                  && (
                    <TableRow>
                      <TableCell><UserDisplay displayName={t('ApprovalsManager')} /></TableCell>
                      <TableCell sx={{ width: endCellWidth, textAlign: 'right' }}>
                        <Button
                          variant="contained"
                          color="success"
                          sx={{ minWidth: '100px' }}
                          onClick={addManagers}
                          disabled={isLoading}
                        >
                          {t('MessagesPageAdd')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                {!isLoadingUsers && filter.length > 2 && usersToBeAdded.filter((u) => !filter || u.name.toLowerCase().includes(filter.toLowerCase())).map((u) => (
                  <TableRow key={u.id}>
                    <TableCell>
                      <UserDisplay
                        displayName={u.name}
                        imageUrl={u.profilePictureUrl}
                      />
                    </TableCell>
                    <TableCell sx={{ width: endCellWidth, textAlign: 'right' }}>
                      <Button
                        variant="contained"
                        color="success"
                        sx={{ minWidth: '100px' }}
                        onClick={() => {
                          addUser(u.id, u.name);
                        }}
                        disabled={isLoading}
                      >
                        {t('MessagesPageAdd')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {isLoadingUsers && <CircularProgress sx={{ display: 'block', margin: 'auto' }} />}
          </div>
        </div>
      </div>
      <CommonModal
        onClose={() => { setRoleDetailsId(null); }}
        open={!!roleDetailsId}
        width={400}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            variant="text"
            sx={{ color: '#fff', textTransform: 'none' }}
            onClick={() => { setRoleDetailsId(null); }}
          >
            <Typography>{t('GenericButtonClose')}</Typography>
            <CloseIcon sx={{ ml: 1 }} />
          </Button>
        </div>
        <div style={{ margin: '20px 0' }}><RoleDisplay role={roleDetailsId} /></div>
        <Typography sx={{ mb: 3 }}>
          {roleDetailsUsers?.length}
          {' '}
          {roleDetailsUsers?.length === 1 ? t('MessagesPagePerson') : t('MessagesPagePersons')}
        </Typography>
        {roleDetailsUsers?.map((u) => <div key={u.id}>{u.name}</div>)}
      </CommonModal>
      <CommonDialog
        isOpen={userRemoval}
        dialogTitle={t('RemoveAllVisibilityConfirmation')}
        dialogContent={t('MessageThreadSelfRemovalWarning')}
        processing={isLoading}
        handleCancel={() => handleCloseDialog()}
        handleConfirm={async () => {
          if (Object.values(InternalRole).includes(elementToBeDeleted)) {
            removeInternalRole(elementToBeDeleted);
          } else if (Object.values(UserRole).includes(elementToBeDeleted)) {
            removeUserRole(elementToBeDeleted);
          } else if (elementToBeDeleted?.id) {
            removeUser(elementToBeDeleted?.id);
          } else {
            removeManagers();
          }
        }}
        confirmButtonTitle={t('MessagesPageRemove')}
        cancelButtonTitle={t('ButtonCancel')}
      />
    </Container>
  );
});

export default MessageThreadVisibilityModal;
