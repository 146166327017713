import { AccessPermission, CustomerCompanyDto, InternalRole, PaymentDetailDtoPagedResultDto, PaymentQueryParameters, UserAccessRequestDto, UserRole } from 'src/types/apiSchemas';
import { SiteUser } from 'src/types/siteUser';
import { managerApprovalsApi } from 'src/API/managerApprovalsApi';
import { paymentApi } from 'src/API/paymentAPI';
import { AuthorizationService, IAuthorizationService } from './authorizationService';
import { Notifications, CustomerNotification } from 'src/store/mobx';
import { selectDistinct } from 'src/utils/arrayUtils';
import { baseApi } from 'src/API/baseApi';
import { allCustomersId } from 'src/constants';

export interface INotificationService {
  getPendingNotifications(user: SiteUser, notifications: Notifications): Promise<Notifications>;
  getAndReturnPendingPaymentNotifications(user: SiteUser, notificationInfo: Notifications): Promise<CustomerNotification[]>;
  getAndReturnPendingApprovalNotifications(user: SiteUser, notificationInfo: Notifications): Promise<CustomerNotification[]>;
  getAndReturnPendingAccessRequestNotifications(user: SiteUser, notificationInfo: Notifications): Promise<CustomerNotification[]>;
  getAndReturnUnreadMessageThreadsNotifications(user: SiteUser, notificationInfo: Notifications): Promise<CustomerNotification[]>;
}

export class NotificationService implements INotificationService {
  /**
   * resolves notifications for user
   * initializes notifications when user logs in
   * updates notifications when user does actions that affects notification count: approves/declines payments, approves declines sick leaves etc.
   * @param user
   * @param notificationInfo
   * @returns
   */
  public getPendingNotifications = async (user: SiteUser, notificationInfo: Notifications): Promise<Notifications> => {
    try {
      const pendingPaymentNotifications = await this.getAndReturnPendingPaymentNotifications(user, notificationInfo);
      const pendingApprovalNotifications = await this.getAndReturnPendingApprovalNotifications(user, notificationInfo);
      const pendingAccessRequestNotifications = await this.getAndReturnPendingAccessRequestNotifications(user, notificationInfo);
      const unreadMessageThreads = await this.getAndReturnUnreadMessageThreadsNotifications(user);

      return {
        notificationsFetched: true,
        notifications: {
          pendingApprovalNotifications,
          pendingPaymentNotifications,
          pendingAccessRequestNotifications,
          unreadMessageThreads
        }
      } as Notifications;
    } catch (err) {
      console.error(`getPendingNoficications:: error, ${err}`);
      return { notificationsFetched: false };
    }
  };

  public getAndReturnPendingPaymentNotifications = async (user: SiteUser, notificationInfo: Notifications): Promise<CustomerNotification[]> => {
    try {
      const newPaymentNotifications = await this.getPendingPaymentNotifications(user);
      const pendingPaymentNotifications = this.appendNotifications(newPaymentNotifications, notificationInfo?.notifications?.pendingPaymentNotifications);
      return pendingPaymentNotifications;
    } catch (err) {
      console.error(`getAndReturnPendingPaymentNotifications:: error, ${err}`);
      return [];
    }
  };

  public getAndReturnPendingApprovalNotifications = async (user: SiteUser, notificationInfo: Notifications): Promise<CustomerNotification[]> => {
    try {
      const newApprovalNotifications = await this.getPendingApprovalNotifications(user);
      const pendingApprovalNotifications = this.appendNotifications(newApprovalNotifications, notificationInfo?.notifications?.pendingApprovalNotifications);
      return pendingApprovalNotifications;
    } catch (err) {
      console.error(`getAndReturnPendingApprovalNotifications:: error, ${err}`);
      return [];
    }
  };

  public getAndReturnPendingAccessRequestNotifications = async (user: SiteUser, notificationInfo: Notifications): Promise<CustomerNotification[]> => {
    try {
      const newAccessRequestNotifications = await this.getPendingAccessRequestNotifications(user);
      const pendingAccessRequestNotifications = this.appendNotifications(newAccessRequestNotifications, notificationInfo?.notifications?.pendingAccessRequestNotifications);
      return pendingAccessRequestNotifications;
    } catch (err) {
      console.error(`getAndReturnPendingAccessRequestNotifications:: error, ${err}`);
      return [];
    }
  };

  public getAndReturnUnreadMessageThreadsNotifications = async (user: SiteUser): Promise<CustomerNotification[]> => {
    try {
      const newUnreadMessageThreads = await this.getUnreadMessageThreadsNotifications(user);
      return newUnreadMessageThreads;
    } catch (err) {
      console.error(`getAndReturnUnreadMessageThreadsNotifications:: error, ${err}`);
      return [];
    }
  };

  /**
   * appends new notification to existing notifications
   * @param appendFrom
   * @param appendTo
   * @returns
   */
  private appendNotifications = (appendFrom: CustomerNotification[], appendTo: CustomerNotification[]): CustomerNotification[] => {
    if (!appendTo) { appendTo = []; }
    if (!appendFrom) { appendFrom = []; }

    appendFrom.forEach((n) => {
      const idx = appendTo.findIndex((en) => en.customerId === n.customerId);
      if (idx < 0) {
        appendTo.push(n);
      } else {
        appendTo[idx] = n;
      }
    });

    return appendTo;
  };

  /**
   *
   * if user has not selected a customer yet and accesspermissions still contains permissions for each company
   * will refresh all notification counts
   * otherwise will just refresh the selected customer's notifications when customer has already been selected or set as the default
   * @param user
   * @returns
   */
  private administrativeCompanies = (user: SiteUser): CustomerCompanyDto[] => {
    if (!user?.accessPermissions) { return []; }

    const administrativeCompanies = user.isConsultantUser
      // scenario for updating all notifications
      ? selectDistinct(user.companies, 'customerId')
      // scenario for updating specific customer's notifications
      : selectDistinct(user.companies, 'customerId').filter((company) => company.customerId === user.customerId);
    return administrativeCompanies;
  };

  /**
   * resolves pending payments amount for users that are payment reviewers or approvers
   * @param user
   * @returns
   */
  private getPendingPaymentNotifications = async (user: SiteUser): Promise<CustomerNotification[]> => {
    try {
      const queryParams: PaymentQueryParameters = {
        PageNumber: 1,
        PageSize: 1,
        PaymentStatus: ['Pending'],
        SearchString: '',
      };

      const result: CustomerNotification[] = [];

      const promises: Promise<PaymentDetailDtoPagedResultDto>[] = [];

      this.administrativeCompanies(user).forEach((company) => {
        // will do permission checking per customer here for being able to show
        // pending notifications also on the forced cuystomer selection when customer has not yet been selected
        // and all accessPermissions for all customers are briefly associated to user before they select the customer
        // and even after that no harm using customer specific access permssion loading here
        const auth: IAuthorizationService = new AuthorizationService();
        const perms = user.accessPermissions?.filter((perm) => perm.customerId === company.customerId);
        const isApproverOrReviewer = auth
          .hasCustomerPermission(AccessPermission.PaymentsApprover, perms, company.customerId)
          .hasCustomerPermission(AccessPermission.PaymentsReviewer, perms, company.customerId)
          .verify();
        // only load pending payment count if the user is a reviewer or an approver
        const promise = isApproverOrReviewer || auth.hasSpecificInternalRole(InternalRole.Payroll, company.customerId)
          ? paymentApi.getPaymentData(user, queryParams, company.customerId)
          : Promise.resolve({ customerId: company.customerId, totalCount: 0, pageNumber: 0, pageSize: 0, results: [] } as PaymentDetailDtoPagedResultDto);

        promises.push(promise);
      });
      const paymentData = await Promise.all(promises.map(promise =>
        promise.catch(error => {
          console.error('Pending payment notifications fetch failed:', error);
          return null;
        })
      ));

      paymentData.forEach((p) => {
        result.push({ count: p.totalCount ?? 0, customerId: p.customerId });
      });

      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  /**
   * resolves pending approval amounts for users that have for instance pending sick leaves from their employees to approve/decline
   * @param user
   * @returns
   */
  private getPendingApprovalNotifications = async (user: SiteUser): Promise<CustomerNotification[]> => {
    try {
      const promises: Promise<CustomerNotification>[] = [];

      this.administrativeCompanies(user).forEach((company) => {
        // will do permission checking per customer here for being able to show
        // pending notifications also on the forced cuystomer selection when customer has not yet been selected
        // and all accessPermissions for all customers are briefly associated to user before they select the customer
        // and even after that no harm using customer specific access permssion loading here
        const auth: IAuthorizationService = new AuthorizationService();
        const perms = user.accessPermissions?.filter((perm) => perm.customerId === company.customerId);
        const isManager = auth
          .hasCustomerPermission(AccessPermission.ManagerAccess, perms, company.customerId)
          .hasCustomerPermission(AccessPermission.SubstituteManagerAccess, perms, company.customerId)
          .verify();
        const { isHR } = auth.get(user).is(UserRole.HR, company.customerId);

        // only load pending approvals count if the user is a manager or is in HR role (could approve holiday bonus change requests)
        const promise = isManager || isHR
          ? managerApprovalsApi.getMyPendingApprovalsCount(user, company.customerId)
          : Promise.resolve({ count: 0, customerId: company.customerId } as CustomerNotification);

        promises.push(promise);
      });

      const approvalCountData = await Promise.all(promises.map(promise =>
        promise.catch(error => {
          console.error('Pending approval notification fetch failed:', error);
          return null;
        })
      ));

      return approvalCountData;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  /**
   * resolves pending access request amounts for user
   * @param user
   * @returns
   */
  private getPendingAccessRequestNotifications = async (user: SiteUser): Promise<CustomerNotification[]> => {
    const result: CustomerNotification[] = [];

    try {
      const promises: Promise<UserAccessRequestDto[]>[] = [];
      const customerIds: number[] = [];

      const isConsultant = await baseApi.isConsultancyEnabledForUserName(user?.email);
      if (isConsultant) {
        this.administrativeCompanies(user).forEach(async (company) => {
          // only load pending access request count if the user is a consultant
          const promise = baseApi.getPendingUserAccessRequests(company.customerId);
          promises.push(promise);
          customerIds.push(company.customerId);
        });
      }

      const accessRequestData = await Promise.all(promises.map(promise =>
        promise.catch(error => {
          console.error('Pending access request notification fetch failed:', error);
        })
      ));

      accessRequestData.forEach((p, i) => {
        result.push({ count: p ? p.length : 0, customerId: customerIds.at(i) });
      });
      return result;
    } catch (err) {
      console.error(err);
      return result;
    }
  };

  /**
   * resolves unread message threads amount
   * @returns
   */
  private getUnreadMessageThreadsNotifications = async (user: SiteUser): Promise<CustomerNotification[]> => {
    try {
      const auth: IAuthorizationService = new AuthorizationService();
      if (!auth.get(user).anyCustomerHas(AccessPermission.MessagingAccess).verify()) {
        return [];
      }
      const customers = user.isConsultantUser ? [] : this.administrativeCompanies(user);
      const res = await baseApi.getUnreadMessageThreadsAllCustomers(customers.map((c) => c.customerId));

      const allCustomersNotifications = {
        customerId: allCustomersId,
        count: res.reduce((acc, cur) => acc + cur.messageThreadIds.length, 0),
        threadIds: res.reduce((acc, cur) => [...acc, ...cur.messageThreadIds], []),
        solvedCount: res.reduce((acc, cur) => acc + cur.solvedThreadIds.length, 0),
        solvedThreadIds: res.reduce((acc, cur) => [...acc, ...cur.solvedThreadIds], [])
      };

      return [
        allCustomersNotifications,
        ...res.map((r) => ({
          customerId: r.customerId,
          count: r.messageThreadIds.length,
          threadIds: r.messageThreadIds,
          solvedCount: r.solvedThreadIds.length,
          solvedThreadIds: r.solvedThreadIds
        }))
      ];
    } catch (err) {
      console.error(err);
      return [];
    }
  };
}
