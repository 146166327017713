import type { FC } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import { createICS } from 'src/utils/ics';

interface AnnouncementCardCalendarReminderProps {
  reminderTime: string;
  title?: string;
  description?: string;
}

const AnnouncementCardCalendarReminder: FC<AnnouncementCardCalendarReminderProps> = (props) => {
  const { reminderTime, title, description } = props;
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ marginTop: 2 }}
    >
      <Button
        variant="outlined"
        startIcon={<CalendarMonthIcon />}
        onClick={() => {
          const ics = createICS({
            start: reminderTime,
            title: title || '',
            description: description || '',
            reminderTime
          });

          FileSaver.saveAs(ics, 'event.ics');
        }}
      >
        <Typography
          color="textPrimary"
          fontWeight={500}
          sx={{ mx: 1 }}
        >
          {t('AddReminderToCalendar')}
        </Typography>
      </Button>
    </Grid>
  );
};

export default AnnouncementCardCalendarReminder;
