export interface AnnouncementCreationDto {
  announcementType: AnnouncementType;
  importance: AnnouncementImportance;
  content: AnnouncementContentCreationDto[];
  customerId: number | null;
  authorCustomerId: number;
  authorUserId: number | null;
  authorCompanyId: number | null;
  confirmationRequired: boolean;
  pushNotification: boolean;
  confirmationLabel: string;
  publishTimeUTC: string | null;
  expirationTimeUTC: string | null;
  eventStartTimeUTC: string | null;
  eventEndTimeUTC: string | null;
  reminderDueDateUTC: string | null;
  links: AnnouncementLinkCreationDto[];
  announcementScopes: AnnouncementScopeDto[];
}
export interface AnnouncementEditDto {
  announcementId: number | null;
  announcementType: AnnouncementType;
  importance: AnnouncementImportance;
  content: AnnouncementContentCreationDto[];
  authorCustomerId: number;
  authorUserId: number | null;
  authorCompanyId: number | null;
  confirmationRequired: boolean;
  pushNotification: boolean;
  confirmationLabel: string;
  publishTimeUTC: string | null;
  expirationTimeUTC: string | null;
  eventStartTimeUTC: string | null;
  eventEndTimeUTC: string | null;
  reminderDueDateUTC: string | null;
  links: AnnouncementLinkCreationDto[];
  announcementScopes: AnnouncementScopeDto[];
}
export enum AnnouncementTargetGroup {
  Everyone = 'EVERYONE',
  SpecificGroups = 'SPECIFICGROUPS'
}
export enum AnnouncementType {
  Announcement = 'Announcement',
  Event = 'Event',
  Reminder = 'Reminder',
}
export enum AnnouncementImportance {
  Regular = 'Regular',
  Important = 'Important'
}
export interface AnnouncementContentCreationDto {
  languageCode: LanguageCode;
  title: string;
  content: string;
}
export interface AnnouncementLinkCreationDto {
  url: string;
  text: string;
}
export interface AnnouncementScopeDto {
  announcementScopeId: number;
  scopeType: AnnouncementScopeType;
  scopeValue: string;
}
export enum AnnouncementScopeType {
  Company = 0,
  Location = 1,
  OrganizationUnit = 2,
  EmploymentType = 3,
  OnlyManagers = 4,
  OnlyEmployees = 5,
  BusinessUnit = 6,
}
export interface AnnouncementImageDto {
  announcementImageId?: number; // int32
  url?: string | null;
  caption?: string | null;
}
export interface AnnouncementLinkDto {
  announcementLinkId?: number; // int32
  url?: string | null;
  text?: string | null;
}
export interface AnnouncementDto {
  announcementId?: number; // int32
  announcementType?: AnnouncementType;
  importance?: AnnouncementImportance;
  subject?: string | null;
  content?: string | null;
  authorName?: string | null;
  announcementConfirmed?: boolean;
  pushNotification?: boolean;
  confirmationRequired?: boolean;
  confirmationLabel?: string | null;
  authorImageUrl?: string | null;
  creationTimeUTC?: string; // date-time
  publishTimeUTC?: string; // date-time
  expirationTimeUTC?: string | null; // date-time
  image?: AnnouncementImageDto;
  links?: AnnouncementLinkDto[] | null;
}

export enum AnnouncementNotificationStatus {
  Pending = 'Pending',
  Processed = 'Processed',
  Aborted = 'Aborted',
  Failed = 'Failed'
}

export interface AnnouncementNotificationDto {
  announcementNotificationId?: number;
  announcementId?: number;
  correlationId?: string;
  status?: AnnouncementNotificationStatus;
}

export interface AnnouncementDetailDto {
  announcementId: number;
  announcementType: AnnouncementType;
  importance: AnnouncementImportance;
  announcementContents: AnnouncementContentDto[];
  authorName: string;
  authorCustomerId: number;
  authorCompanyId: number | null;
  confirmationRequired: boolean;
  confirmationLabel: string;
  pushNotification: boolean;
  authorImageUrl: string;
  publishTimeUTC: string;
  expirationTimeUTC: string | null;
  eventStartTimeUTC: string | null;
  eventEndTimeUTC: string | null;
  reminderDueDateUTC: string | null;
  links: AnnouncementLinkDto[];
  announcementScopeDtos: AnnouncementScopeDto[];
  attachedImage: string;
  attachedImageCaption: string;
  announcementNotification?: AnnouncementNotificationDto;
}
export interface AnnouncementContentDto {
  announcementId: number;
  languageCode: LanguageCode;
  title: string;
  content: string;
}
export interface Company {
  companyId?: number; // int32
  name?: string | null;
  logoUrl?: string | null;
  paymentFileCompanyId?: string | null;
  maxPaymentHysteresisPercentage?: number; // int32
  customerId?: number; // int32
  customer?: Customer;
  requiredReviews?: number; // int32
  requiredApprovals?: number; // int32
}

export interface CompanyDtoPagedResultDto {
  pageNumber?: number; // int32
  pageSize?: number; // int32
  totalPages?: number; // int32
  totalCount?: number; // int32
  results?: Company[] | null;
}

export interface CustomerDetails {
  extendedManagerMessagingRights?: boolean;
}

export interface CompanyDetails {
  customer?: CustomerDetails;
}
export interface CustomerCompanyDto {
  companyId?: number; // int32
  companyName?: string | null;
  customerId?: number; // int32
  customerName?: string | null;
  customerLinks?: CustomerLink[];
  company?: CompanyDetails;
}
export interface CustomerLink {
  linkType: LinkType;
  url: string;
}
export enum LinkType {
  Mepco = 'Mepco',
  CustomerService = 'CustomerService'
}
export interface Customer {
  customerId?: number; // int32
  name?: string | null;
  teamsChannelId?: string | null;
  teamsTeamId?: string | null;
  customerFeatures?: Feature[] | null;
}
export interface AnnouncementDetailDtoPagedResultDto {
  pageNumber?: number; // int32
  pageSize?: number; // int32
  totalPages?: number; // int32
  totalCount?: number; // int32
  results?: AnnouncementDetailDto[] | null;
}
export type LanguageCode = 'fi' | 'en' | 'sv';

export interface TeamMemberDto {
  name?: string | null;
  title?: string | null;
  phoneNumber?: string | null;
  profilePictureUrl?: string | null;
}

export interface SubordinateDto {
  name?: string | null;
  id?: number | null;
}
export interface CustomerAccessPermissionDto {
  customerId: number;
  accessPermissions: Array<AccessPermission>;
}

export interface ManagerSubstitutionDto {
  substituteName?: string;
  substituteProfilePictureUrl?: string;
  substituteId: number;
  managerId: number;
  managerName?: string;
  startOfSubstitutionUTC?: Date;
  endOfSubstitutionUTC?: Date;
  isEffective?: boolean;
}

export interface LoginInfoDto {
  userId?: number; // int32
  username?: string | null;
  name?: string | null;
  languageCode?: LanguageCode;
  notificationPreference?: NotificationPreference;
  companies?: CustomerCompanyDto[] | null;
  accessPermissions?: CustomerAccessPermissionDto[] | null;
  lastLoginTimeUTC?: Date;
  managerSubstitutions?: ManagerSubstitutionDto[];
  // no need for substitutionManagers for now
  // substitutionManagers?: ManagerSubstitutionDto[];
  isManager?: boolean;
  isSubstituteManager?: boolean;
  isConsultantUser?: boolean;
  employmentCustomerId?: number;
  profilePictureUrl?: string;

  customerInternalRoles: CustomerInternalRoles[],
  customerUserRoles: CustomerUserRoles[],
  siteTheme?: string;
}

export interface CustomerUserRoles {
  customerId: number;
  userRoles: UserRole[]
}

export interface CustomerInternalRoles {
  customerId?: number;
  internalRoles: InternalRole[]
}

export interface UserDtoPagedResultDto {
  pageNumber?: number; // int32
  pageSize?: number; // int32
  totalPages?: number; // int32
  totalCount?: number; // int32
  results?: LoginInfoDto[] | null;
}
export enum UserRole {
  HR = 'HR',
  Approver = 'Approver',
  Reviewer = 'Reviewer',
  MainUser = 'MainUser',
  Finance = 'Finance',
  Technical = 'Technical'
}

export enum InternalRole {
  Payroll = 'Payroll',
  IntegrataHR = 'IntegrataHR',
  MepcoAdmin = 'MepcoAdmin',
  MepcoWebAdmin = 'MepcoWebAdmin',
  QuinyxAdmin = 'QuinyxAdmin',
  TiimaAdmin = 'TiimaAdmin',
  NeptonAdmin = 'NeptonAdmin',
  M2Admin = 'M2Admin',
  AcubizAdmin = 'AcubizAdmin',
  SympaAdmin = 'SympaAdmin',
  AccountManager = 'AccountManager',
  IT = 'IT',
  TravelExpenses = 'TravelExpenses',
  HumHumSupport = 'HumHumSupport',
  Billing = 'Billing',
  Analytics = 'Analytics',
  ProjectManager = 'ProjectManager',
  MepcoConsultant = 'MepcoConsultant'
}

export enum NotificationPreference {
  Push = 'Push',
  Email = 'Email',
  PushAndEmail = 'EmailAndPush',
  None = 'None'
}

export enum Feature {
  Payments = 'Payments',
  Payslips = 'Payslips',
  Messaging = 'Messaging',
  Reporting = 'Reporting',
  Absences = 'Absences',
  Attachments = 'Attachments',
  ManagerApprovals = 'ManagerApprovals',
  PersonalInformation = 'PersonalInformation',
  Announcements = 'Announcements',
  HolidayBonusChanges = 'HolidayBonusChanges'
}

export enum AccessPermission {
  AbsencesAccess = 'absences_access',
  AnnouncementsAccess = 'announcements_access',
  AnnouncementsWrite = 'announcements_write',
  AttachmentsAccess = 'attachments_access',
  MessagingAccess = 'messaging_access',
  PaymentsApprover = 'payments_approver',
  PaymentsReviewer = 'payments_reviewer',
  EmployeePayslips = 'employee_payslips',
  PersonalInformationAccess = 'personal_information_access',
  ManagerAccess = 'manager_access',
  SubstituteManagerAccess = 'substitute_manager_access',
  Reporting = 'reporting_access',
  HolidayBonusChangesAccess = 'holidaybonuschanges_access'
}

export interface CustomerDtoPagedResultDto {
  pageNumber?: number; // int32
  pageSize?: number; // int32
  totalPages?: number; // int32
  totalCount?: number; // int32
  results?: Customer[] | null;
}

export interface PaymentDto {
  paymentId?: number; // int32
  paymentType?: PaymentType;
  paymentStatus?: PaymentStatus;
  companyId?: number; // int32
  ownerName?: string | null;
  companyLogoUrl?: string | null;
  companyRequiredReviews?: number | null;
  amount?: number; // double
  fileName?: string;
  date?: string; // date-time
  paymentDate?: string; // date-time
  periodStartDate?: string; // date-time
  periodTermDate?: string; // date-time
  transactionCount?: number; // int32
  approvals?: PaymentApprovalDto[] | null;
  reviews?: PaymentReviewDto[] | null;
  approvalsCompleted?: boolean | false;
  reviewsCompleted?: boolean | false;
  userActionCompleted?: boolean | false;
}

export interface PaymentDetailDto {
  paymentId?: number; // int32
  paymentType?: PaymentType;
  paymentStatus?: PaymentStatus;
  companyId?: number; // int32
  ownerName?: string | null;
  companyLogoUrl?: string | null;
  amount?: number; // double
  date?: string; // date-time
  paymentDate?: string; // date-time
  periodStartDate?: string; // date-time
  periodTermDate?: string; // date-time
  transactionCount?: number; // int32
  approvals?: PaymentApprovalDto[] | null;
  reviews?: PaymentReviewDto[] | null;
}

export interface PaymentDetailDtoPagedResultDto {
  customerId?: number;
  pageNumber?: number; // int32
  pageSize?: number; // int32
  totalCount?: number;
  results?: PaymentDto[] | null;
}

export interface PaymentReviewDto {
  paymentReviewId?: number; // int32
  paymentId?: number; // int32
  userId?: number; // int32
  userName?: string | null;
  profilePictureUrl?: string | null;
  status?: PaymentReviewStatus;
  comment?: string | null;
  processed?: string | null;
}

export interface TransactionDto {
  transactionId?: number; // int32
  paymentId?: number; // int32
  recipient?: string | null;
  payslipId?: null | number; // int32
  netAmount?: number; // double
  currency?: string | null;
  changes?: string[] | null;
  description?: string | null;
  flagged?: boolean;
  comments?: string | null;
  changeDetails?: TransactionChangeDto[];
}

export interface TransactionChangeDto {
  transactionId: number;
  transactionChangeId: number;
  transactionChangeType: number;
  transactionChangeDetails?: TransactionChangeDetailDto[];
}

export interface TransactionChangeDetailDto {
  transactionChangeDetailId: number;
  transactionChangeId: number;
  newValue?: string;
  oldValue?: string;
  detailContent?: string;
}

export enum TransactionChangeType {
  UNCHANGED = -1,
  NEW_EMPLOYMENT = 0,
  ENDING_EMPLOYMENT = 1,
  SALARY_CHANGE = 2,
  EXCEPTION = 3,
  PAYLESS_ABSENCES = 4,
  WORKTIME_CHANGE = 5,
}

export interface TransactionDtoPagedResultDto {
  pageNumber?: number; // int32
  pageSize?: number; // int32
  totalPages?: number; // int32
  totalCount?: number; // int32
  results?: TransactionDto[] | null;
}

export interface PaymentApprovalDto {
  paymentApprovalId?: number; // int32
  paymentId?: number; // int32
  userId?: number; // int32
  userName?: string | null;
  profilePictureUrl?: string | null;
  status?: PaymentApprovalStatus;
  comment?: string | null;
  processed?: string | null;
}

export interface PayslipRowDto {
  name?: string;
  value?: string;
  hourlyRate?: string | null;
  hours?: string | null;
  unitRate?: string | null;
  units?: string | null;
}

export interface PayslipPartDto {
  title?: string;
  items?: PayslipRowDto[];
}

export interface PayslipCollectionDto {
  payslipCollectionId?: number;
  blobUrl?: string;
  status?: PayslipStatus;
  creationTime?: string;
  fileName?: string;
  mepcoCompanyId?: string;
  payCategory?: string;
  companyId?: number;
  paymentId?: number;
}

export interface PayslipDto {
  payslipId?: number;
  transaction?: TransactionDto;
  personId?: string;
  netPayment?: number;
  creationTime?: string;
  status?: PayslipStatus;
  mepcoCompanyId?: string;
  payCategory?: string;
  recipient?: string;
  fileName?: string;
  payslipJsonFile?: string;
  payslipCollection?: PayslipCollectionDto;
}

export interface UserPayslip {
  payslipId: number;
  netAmount: number;
  companyName?: string;
  paymentDate: string;
  title?: string;
  currency?: string;
}

export interface TranslatedSalaryCodeDescriptionDto {
  id: number;
  customerId?: number;
  salaryCode: number;
  description: string;
}

export type PaymentReviewStatus = 'Pending' | 'Reviewed' | 'Declined';
export type PaymentApprovalStatus = 'Pending' | 'Approved' | 'Declined';
export type PaymentType = 'Salary' | 'Other';
export type PaymentStatus = 'Created' | 'Pending' | 'Completed' | 'Declined';

// QueryParameters
export interface AnnouncementQueryParameters extends CommonQueryParams {
  SearchString?: string | null;
  AnnouncementType?: AnnouncementType | null;
  AnnouncementImportance?: AnnouncementImportance | null;
}

export interface AnnouncementScopesQueryParameters {
  Locations: string[];
  Companies: string[];
  OrganizationUnits: string[];
  EmploymentTypes: string[];
  OnlyManagers: string[];
  OnlyEmployees: string[];
  BusinessUnits: string[];
}

export interface DashboardCompanyQueryParameters extends CommonQueryParams {
  SearchString?: string | null;
}

export interface DashboardCompanyPagedResult {
  pageNumber?: number;
  pageSize?: number;
  totalPages?: number;
  totalCount?: number;
  results?: Company[] | null;
}

export interface DashboardUserQueryParameters extends CommonQueryParams {
  NameFilter?: string;
  RoleFilter?: string;
  CustomerId?: number;
  CompanyId?: number;
}

export interface DashboardCustomerQueryParameters extends CommonQueryParams {
  SearchString?: string | null;
}

export interface PaymentQueryParameters extends CommonQueryParams {
  PaymentStatus?: string[];
  SearchString?: string;
  CustomerId?: number;
  StartDate?: string;
  EndDate?: string;
}

export interface FilterOption {
  value?: string;
  label: string;
  icon?: any;
}

export interface DashboardPaymentTransactionsQueryParameters extends CommonQueryParams {
  SearchString?: string,
  Changes?: string[];
  Flagged?: boolean | null;
}

export type ManagerApprovalStatus = 'Pending' | 'Approved' | 'Declined' | 'Returned';
export type AbsenceCategory = 'SickLeave';
export type AttachmentRequirement = 'None' | 'Required' | 'Optional';
export type AttachmentType = 'SickLeaveCertificate' | 'EmploymentDocument' | 'Other';

export interface AbsenceApprovalDto {
  absenceId?: number | null;
  userId?: number;
  absenceCategory?: AbsenceCategory;
  absenceTypeId?: number;
  attachmentRequirement?: AttachmentRequirement;
  startOfAbsenceUTC?: string;
  endOfAbsenceUTC?: string;
}

export interface AttachmentApprovalDto {
  userAttachmentId?: number;
  userId?: number;
  attachmentType?: AttachmentType;
  absenceId?: number;
}

export interface AttachmentApprovalDetailDto {
  userAttachmentId?: number;
  userId?: number;
  attachmentType?: AttachmentType;
  absenceId?: number;
  files?: AttachmentFileDto[];
}

export interface AttachmentFileDto {
  userAttachmentFileId?: number;
  uri?: string;
  originalFilename?: string;
  thumbnailUrl?: string;
}

export interface ManagerApprovalDto {
  userId?: number;
  userName?: string;
  approverId?: number | null;
  approvalStatus?: ManagerApprovalStatus;
  approverComment?: string | null;
  processedTimeUTC?: string | null;
  absence?: AbsenceApprovalDto | null;
  attachment?: AttachmentApprovalDto | null;
  holidayBonusChangeRequest?: HolidayBonusChangeRequestDto | null;
}

export interface UserRoleDto {
  userRole: UserRole;
  customerId: number;
}
export interface User {
  userId?: number; // int32
  active?: boolean;
  isManager?: boolean;
  username?: string | null;
  name?: string | null;
  title?: string | null;
  phoneNumber?: string | null;
  languageCode?: LanguageCode;
  userRoles?: UserRoleDto[];
  userInternalRoles?: UserInternalRoleDto[];
  profilePictureUrl?: string | null;
  companies?: CustomerCompanyDto[] | null;
  topics?: string[] | null;
  employeeId?: string | null;
  alternativeEmail?: string | null;
}

export interface ManagerApprovalDetailDto {
  userId?: number;
  userName?: string;
  approverId?: number | null;
  approvalStatus?: ManagerApprovalStatus;
  approverComment?: string | null;
  processedTimeUTC?: string | null;
  userPhoneNumber: string | null;
  creationTimeUTC: string | null;
  absence?: AbsenceApprovalDto | null;
  attachment?: AttachmentApprovalDetailDto | null;
  holidayBonusChangeRequest?: HolidayBonusChangeRequestDto | null;
}

export interface ManagerApprovalAbsenceTypeDto {
  absenceTypeId?: number;
  customerId: number;
  absenceCategory: string;
  attachmentRequirement: AttachmentRequirement;
  code: string;
  name: string;
  description: string;
}

export interface Absence {
  absenceTypeId: number;
  startOfAbsenceUTC: string;
  endOfAbsenceUTC: string;
  alwaysPending: boolean;
}

export interface AbsenceTranslated {
  absenceId: number;
  userId: number;
  absenceCategory: AbsenceCategory;
  attachmentRequirement: AttachmentRequirement;
  attachment?: AttachmentApprovalDetailDto | null;
  absenceTypeId: number;
  approverComment?: string;
  absenceTypeName: string;
  absenceCreatedUTC: string;
  startOfAbsenceUTC: string;
  endOfAbsenceUTC: string;
}

export interface AbsenceResult {
  resultKey: string;
  absenceId: number;
}

export interface AbsenceTypeContent {
  absenceTypeId: number;
  languageCode: LanguageCode;
  name: string;
  description: string;
}

export interface AbsenceType {
  absenceTypeId: number;
  customerId: number;
  absenceCategory: string;
  attachmentRequirement: AttachmentRequirement;
  code: string;
  contents: AbsenceTypeContent[];
}

export interface ManagerApprovalsDto {
  manager: User;
  managerApprovals: ManagerApprovalDto[];
}

export interface Report {
  id: string;
  name: string;
}
export interface ReportingWorkspace {
  id: string;
  name: string;
  reports: Report[];
}

export interface ReportingNavigation {
  workspaces: ReportingWorkspace[];
  isDemoNavigation?: boolean;
}

export interface ReleaseDto {
  releaseId?: number;
  title: string;
  description: string;
  releaseDateUTC: string;
}

export interface ReleaseDtoPagedResultDto {
  pageNumber?: number;
  pageSize?: number;
  totalPages?: number;
  totalCount?: number;
  results?: ReleaseDto[] | null;
}

export enum PayslipStatus {
  Created = 'Created',
  Pending = 'Pending',
  Completed = 'Completed',
  Declined = 'Declined'
}

export enum HolidayBonusChangeApprovalRule {
  NotRequired = 'NotRequired',
  Manager = 'Manager',
  HR = 'HR'
}

export interface HolidayBonusChangePeriodTranslation {
  languageCode: string;
  description: string;
}

export interface HolidayBonusChangePeriod {
  holidayBonusChangePeriodId?: number;
  customerId: number;
  periodStartUtc: string;
  periodEndUtc: string;
  divider: number;
  approvalRule: HolidayBonusChangeApprovalRule;
  translations: HolidayBonusChangePeriodTranslation[];
  choices: number[];
}

export interface CommonQueryParams {
  PageNumber?: number;
  PageSize?: number;
}

export interface PagedResult<T> {
  pageNumber?: number;
  pageSize?: number;
  totalPages?: number;
  totalCount?: number;
  results?: T[];
}
export interface HolidayBonusChangeRequestDto {
  holidayBonusChangeRequestId: number;
  requester?: User | null;
  approver?: User | null;
  userId: number;
  approverId?: number | null;
  days?: number | null;
  approverComment?: string | null;
  status: ManagerApprovalStatus;
  createdUTC?: string | null;
  processedUTC?: string | null;
  period?: HolidayBonusChangePeriod | null;
}

export interface UserQueryParameters extends CommonQueryParams {
  NameFilter?: string;
  RoleFilter?: string;
  CustomerId?: number;
  CompanyName?: string;
  CompanyId?: number;
}

export interface CosmosPaginationParameters {
  PageSize: number;
  ContinuationToken: string;
}
export interface MillerBalanceItem {
  employmentContractId: string;
  absenceCode: string;
  period: string;
  name: string;
  amount: number;
  consumed: number;
  remaining: number;
  effective: string;
  expires: string;
  employeeNumber: string;
  state: number;
  externalId: string;
  payrollSystemId: string;
  id: string;
  batchId: null;
  documentType: string;
  partition: string;
  created: string;
  modified: string;
}

export interface MillerBalanceResponse {
  continuationToken: string;
  items: MillerBalanceItem[];
}
export interface HolidayBonusChangeRequest {
  days: number;
}

export enum ApprovalInclude {
  IncludeAttachments = 'IncludeAttachments',
  IncludeAbsences = 'IncludeAbsences',
  IncludeHolidayBalanceRequests = 'IncludeHolidayBalanceRequests',
}

export interface TranslatedMessageCategory {
  id: number;
  name: string;
  description: string;
  messageCategoryRoles: UserRole[];
  messageCategoryInternalRoles: InternalRole[];
  managerHasAccess: boolean;
  emailCategory: boolean;
  slaMonitoring: boolean;
}

export interface MessageThread {
  categoryId: number;
  subject: string;
  message: string;
  customerId?: number;
  messageThreadVisibility?: MessageThreadVisibility;
  priority?: SLAPriority;
  relatedPersonId?: number;
  relatedCompanyId?: number;
}

export interface MessageThreadQueryParameters {
  searchString?: string,
  status?: MessageThreadStatus[],
  category?: number[];
  customerId?: string[];
  assignmentStatus?: MessageThreadAssignmentStatus[],
  includeBothAssignees?: boolean;
  assigneeId?: number[];
  customerAssigneeId?: number[];
  includeAllCustomers?: boolean;
  pageSize?: number;
  pageNumber?: number;
  sort: MessageThreadSort;
  sortDirection: SortDirection;
  messageCategoryTypes?: MessageCategoryType[];
  slaPriority: SLAPriority[];
}

export enum MessageThreadSort {
  Status = 'Status',
  Category = 'Category',
  Customer = 'Customer',
  LastMessage = 'LastMessage',
  Assignee = 'Assignee',
  CustomerAssignee = 'CustomerAssignee',
  CategoryType = 'CategoryType',
  Priority = 'Priority',
  CreationTime = 'CreationTime'
}

export enum SortDirection {
  Asc = 'Asc',
  Desc = 'Desc'
}

export interface UserReference {
  id: number;
  name?: string;
  profilePictureUrl?: string;
  username?: string;
}

export enum MessageThreadRetentionDays {
  StandardRetention = 1460,
  ExtendedRetention = 2190
}

export interface MessageThreadsResponse {
  id: number;
  messageThreadUniqueId: string;
  categoryName: string;
  categoryDescription: string;
  categoryId: number,
  starterName: string;
  starterProfilePictureUrl: string;
  subject: string;
  creationTime: string;
  latestMessage: Message;
  status: MessageThreadStatus;
  assignee?: UserReference;
  customerAssignee?: UserReference;
  customerId: number;
  customerName: string;
  isSubordinateThread: boolean;
  retentionDays: MessageThreadRetentionDays;
  retentionDate: string;
  emailCategoryMessage: boolean;
  genAiRequestEnabled: boolean;
  slaMonitoring?: boolean;
  priority?: SLAPriority;
  relatedCompanyId?: number;
  relatedCompanyName?: string;
  relatedPerson?: UserReferenceDto;
  messageCategoryType?: MessageCategoryType;
}

export interface TranslatedCreatedMessageThread {
  id: number;
  messageThreadUniqueId: string;
  categoryName: string;
  starterName: string;
  subject: string;
  messageContent: string;
  messageId: number;
  creationTime: string;
  status: MessageThreadStatus;
}

export interface InternalRolesAndUsers {
  internalRole: InternalRole;
  users: UserReference[];
}

export interface UserRolesAndUsers {
  userRole: UserRole;
  users: UserReference[];
}

export interface MessageThreadVisibility {
  userRoles: UserRole[];
  internalRoles: InternalRole[];
  users: UserReference[];
  managerHasAccess: boolean;
}

export interface MessageThreadVisibilityParameters {
  userRoles: UserRolesAndUsers[];
  internalRoles: InternalRolesAndUsers[];
  users: UserReference[];
}

export interface MessageVisibility {
  userRoles: UserRole[];
  internalRoles: InternalRole[];
  users: UserReference[];
  managerHasAccess: boolean;
}

export interface Message {
  id: number;
  senderName: string;
  isDeleted: boolean;
  userCanDelete: boolean;
  senderId: number;
  senderProfilePictureUrl: string;
  content: string;
  creationTime: string;
  isInternal: boolean;
  messageVisibility: MessageVisibility;
  messageAttachments?: MessageAttachment[];
}

export enum MessageThreadStatus {
  New = 'New',
  Open = 'Open',
  Pending = 'Pending',
  Solved = 'Solved'
}

export enum MessageThreadAssignmentStatus {
  Any = 'Any',
  NotAssigned = 'NotAssigned',
  Assigned = 'Assigned'
}

export interface CardEntryItem {
  name: string;
  value: string;
  warnings: string[];
}

export interface CardEntry {
  key: 'VacationInfo' | 'TaxInfo';
  items: CardEntryItem[];
}

export enum UserAccessRequestStatusEnum {
  Pending = 'Pending',
  Declined = 'Declined',
  Approved = 'Approved'
}

export enum ThreadActivityType {
  VisibilityAdded = 'VisibilityAdded',
  VisibilityRemoved = 'VisibilityRemoved',
  StatusChanged = 'StatusChanged',
  AssigneeChanged = 'AssigneeChanged',
  CustomerAssigneeChanged = 'CustomerAssigneeChanged',
  RetentionChanged = 'RetentionChanged',
  CCAdded = 'CCAdded',
  CCRemoved = 'CCRemoved',
  PriorityChanged = 'PriorityChanged',
  ThreadCreated = 'ThreadCreated',
  CategoryChanged = 'CategoryChanged',
  RelatedCompanyOrEmployeeChanged = 'RelatedCompanyOrEmployeeChanged'
}

export interface UserInternalRoleDto {
  userInternalRoleId: number;
  internalRole: InternalRole;
  userId: number;
  customerId?: number;
}

export interface UserAccessRequestCreationDto {
  customerId: number;
  companyIds: number[];
  userRoles: UserRole[];
  internalRoles: InternalRole[];
}

export interface UserAccessRequestRoleDto {
  userRole: UserRole;
}

export interface UserAccessRequestInternalRoleDto {
  internalRole: InternalRole;
}

export interface UserAccessRequestCompanyDto {
  companyId: number;
  company: Company;
}

export interface UserAccessRequestDto {
  id: number;
  userId: number;
  approverId: number;
  customerId: number;
  creationTime: string;
  approvalTime: string;
  user: User;
  approver: User;
  customer: Customer;
  requestStatus: UserAccessRequestStatusEnum;
  requestRoles: UserAccessRequestRoleDto[];
  requestInternalRoles: UserAccessRequestInternalRoleDto[];
  requestCompanies: UserAccessRequestCompanyDto[];
}

export interface UserAccessRequestApprovalDto {
  userAccessRequestsId: number,
  requestStatus: UserAccessRequestStatusEnum
}

export interface CustomerDto extends Customer {
  customerCompanies?: CustomerCompanyDto[];
}

export interface CustomerToJoinSearchResultDto {
  customers: CustomerDto[];
  userCompanies: CustomerCompanyDto[];
  userRoles: UserRoleDto[];
  userInternalRoles: UserInternalRoleDto[];
}

export interface MessageThreadAssigneeOptions {
  user: UserReference;
  internalRoles: InternalRole[];
  userRoles: UserRole[];
}

interface MessageActivityContent {
  UserName?: string;
  RemovedUserName?: string;
  UserRole?: number;
  AddedUserName?: string;
  InternalRole?: number;
  ManagerVisibility?: boolean;
  Status?: number;
  AssigneeName?: string;
  CustomerAssigneeName?: string,
  Retention?: MessageThreadRetentionDays;
  Email?: string;
  Priority?: SLAPriority;
  CategoryId: number;
  RelatedPersonName?: string;
  RelatedCompanyName?: string;
}

export interface MessageThreadActivity {
  userId?: number,
  threadId: number,
  activityType: ThreadActivityType,
  createTime: string,
  content: MessageActivityContent,
  translatedMessage: string
}

export enum NotificationEvent {
  NewMessage = 'NewMessage',
  MessageThreadAssigned = 'MessageThreadAssigned',
  NewMessageThreadAsCustomerSpecialist = 'NewMessageThreadAsCustomerSpecialist',
  NewMessageThreadAsGlobalSpecialist = 'NewMessageThreadAsGlobalSpecialist',
  NewMessageThreadAsCustomerRole = 'NewMessageThreadAsCustomerRole',
  NewMessageThreadAsUser = 'NewMessageThreadAsUser',
}

export interface NotificationSetting {
  notificationEvent: NotificationEvent;
  allowNotification: boolean;

}

export interface PaymentPayslipDto {
  payslipId: number;
  transaction: TransactionDto;
  personId: string;
  netPayment: number;
  creationTime: string;
  status: PayslipStatus;
  mepcoCompanyId: string;
  payCategory: string;
  recipient: string;
  fileName: string;
  payslipJsonFile: string;
  payslipCollection: PayslipCollectionDto;
}

export interface ThreadUserLastRead {
  userId: number;
  name: string;
  profilePictureUrl: string;
  readTS: string;
  user: User;
}

export interface UnreadMessageThreads {
  customerId: number;
  messageThreadIds: string[];
  solvedThreadIds: string[];
}
export interface EmploymentGroup {
  employer: string;
  endOfContract: string;
  startOfEmployment: string;
  title: string;
  typeOfEmployment: string;
  workHours: string;
}

export interface UserPersonalInformation {
  name: string;
  roles: string[];
  companyEmail: string;
  homeEmail: string;
  homePhoneNumber: string;
  homeStreetAddress: string;
  homePostalCode: string;
  homeCity: string;
  ibanBankAccountNumber: string;
  nextOfKinName: string;
  nextOfKinPhoneNumber: string;
  nextOfKinLanguage: string;
  employmentInformation: EmploymentGroup[][];
}

export type UserPersonalInformationUpdate = Omit<UserPersonalInformation, 'name' | 'roles' | 'companyEmail' | 'employmentInformation'>;

export interface UserProfilePictureResultDto {
  resultKey?: string;
  profilePictureUrl?: string;
}

export enum MessageAttachmentType {
  Other = 'Other',
  SickLeaveCertificate = 'SickLeaveCertificate',
  Bonus = 'Bonus',
  OfficialCertificate = 'OfficialCertificate',
  Distraint = 'Distraint',
  Collection = 'Collection',
  Correction = 'Correction',
  Benefits = 'Benefits',
  Absences = 'Absences',
  FinalAccount = 'FinalAccount',
  Deductions = 'Deductions',
  SalaryChange = 'SalaryChange'
}

export interface MessageAttachment {
  messageAttachmentId: number;
  messageId: number;
  messageAttachmentType: MessageAttachmentType;
  fileName?: string;
  url?: string;
  thumbnailUrl?: string;
  isLoading?: boolean;
  loadError?: boolean;
  base64File?: string;
  file?: File;
  allowUploadRetry?: boolean;
}

export interface CategoryFilter {
  categoryId: number,
  categoryName: string
}

export interface CustomerCategoryFilter {
  customerId: number,
  customerName: string,
  categories: CategoryFilter[]
}

export interface AssigneeFilters {
  assigneeId: number,
  assigneeName: string
}

export interface CustomerAssigneeFilters {
  customerAssigneeId: number,
  customerAssigneeName: string
}

export interface MessageThreadsFilters {
  customerCategoryFilters: CustomerCategoryFilter[]
  assigneeFilters: AssigneeFilters[]
  customerAssigneeFilters: CustomerAssigneeFilters[]
}

export interface MessageAttachmentCreation extends Omit<MessageAttachment, 'messageAttachmentId' | 'messageId'> { }

export interface DateRange {
  start?: string;
  end?: string;
}

export interface EmploymentDetail {
  userEmploymentId?: number,
  userId?: number,
  title?: string,
  employer?: string,
  manager?: string,
  employmentStartDate?: string,
  typeOfEmployment?: string,
  endOfContract?: string,
  workHours?: string,
  businessId?: number,
  companyId?: number,
  company?: Company,
  organizationUnit?: string,
  businessUnit?: string,
  location?: string,
  paymentGroupName?: string
}

export interface UserDetail extends User {
  companyEmail?: string;
  homeEmail?: string;
  homePhoneNumber?: string;
  homeStreetAddress?: string;
  homePostalCode?: string;
  profilePictureUrl?: string;
  homeCity?: string;
  iBANBankAccountNumber?: string;
  nextOfKinName?: string;
  nextOfKinPhoneNumber?: string;
  nextOfKinLanguage?: string;
  employmentInformationGroups?: any[];
}

export interface UserListPagedResultDto {
  pageNumber?: number; // int32
  pageSize?: number; // int32
  totalPages?: number; // int32
  totalCount?: number; // int32
  results?: User[] | null;
}

export interface UserReferenceDto {
  id: number;
  name: string;
  profilePictureUrl: string;
}

export interface GenAiRetrievedChunks {
  page?: number;
  fileName: string;
  content: string;
}

export interface GenAiResponse {
  requestId: number;
  generatedAnswer: string;
  retrievedChunks: GenAiRetrievedChunks[];
  condensedQuery: string;
}

export enum GenAiRequestStatus {
  Created = 0,
  Pending = 1,
  Success = 2,
  Error = 3
}

export enum GenAiResponseApprovalStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Declined = 'Declined'
}

export interface GenAiApprovalUpdateDto {
  approvalStatus: GenAiResponseApprovalStatus;
  approverComment?: string;
}

export interface GenAiRequestItem {
  id: number;
  messageId: number;
  senderId?: number;
  requestCreationTime?: string;
  responseCreationTime?: string;
  // requestJson not needed but let's leave it commented out in case we would need it in the future
  // requestJson: string;
  response: GenAiResponse;
  status: GenAiRequestStatus;
  approvalStatus: GenAiResponseApprovalStatus;
  requestErrorMessage: string;
  approverId?: number;
  approverUser: UserReferenceDto;
  approverComment?: string;
}

export enum SLAPriority {
  Low = 'Low',
  Normal = 'Normal',
  High = 'High'
}

export interface CompanyReferenceDto {
  id: number,
  name: string,
  customerId: number,
}

export interface MessageThreadRelatedItemOptionsDto {
  companies: CompanyReferenceDto[];
  persons: UserReferenceDto[];
}

export interface MessageThreadRelatedItemDto {
  companyId: number;
  personUserId: number;
}

export interface MessageThreadRelatedItemWithName {
  companyId: number;
  person: UserReferenceDto;
  relatedCompanyName: string;
}

export interface MessageThreadRelatedCompanyOrEmployeeUpdateDto {
  RelatedEmployeeUserId: number;
  RelatedCompanyId: number;
}

export enum MessageCategoryType {
  Tiima = 'Tiima',
  Sympa = 'Sympa',
  Nepton = 'Nepton',
  M2 = 'M2',
  Acubiz = 'Acubiz',
  Mepco = 'Mepco',
  MepcoWeb = 'MepcoWeb',
  Quinyx = 'Quinyx',
  HumHum = 'HumHum',
  Analytics = 'Analytics',
  IT = 'IT',
  TravelLedger = 'TravelLedger',
  Customer = 'Customer',
  Salary = 'Salary',
  HR = 'HR',
  Multiservice = 'Multiservice',
  CustomerInternal = 'CustomerInternal'
}