import { FC, MouseEventHandler } from 'react';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface IconSize {
  height: number;
  width: number
}

interface ChevronRightButtonProps {
  to: string;
  size: IconSize;
  onClick?: MouseEventHandler
  state?: any;
}

const ChevronRightButton: FC<ChevronRightButtonProps> = ({ to, size, state, onClick }) => {
  const theme = useTheme();
  return (
    <IconButton
      state={state}
      onClick={onClick}
      component={RouterLink}
      to={to}
      size="small"
      sx={{
        background: theme.palette.primary.cardBackground,
        '&:hover': {
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText
        }
      }}
    >
      <ChevronRightIcon sx={{ width: size.width, height: size.height }} />
    </IconButton>
  );
};

export default ChevronRightButton;
