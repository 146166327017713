import type { FC } from 'react';
import PropTypes from 'prop-types';
import { paymentApi } from 'src/API/paymentAPI';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  Typography,
  Grid,
  IconButton,
  Box,
} from '@mui/material';
import X from 'src/icons/X';
import { LoadingButton } from '@mui/lab';
import { observer } from 'mobx-react';

interface PaymentFlagsRemoveDialogProps {
  paymentId?: number;
  openDialog: any,
  closeDialogWithSuccess: any,
  closeDialogWithoutSuccess: any
}

const PaymentFlagDialog: FC<PaymentFlagsRemoveDialogProps> = observer((props) => {
  const { paymentId, openDialog, closeDialogWithSuccess, closeDialogWithoutSuccess } = props;

  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    closeDialogWithoutSuccess();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
    >
      <Formik
        initialValues={{
          submit: null
        }}
        validationSchema={
          Yup
            .object()
            .shape({
            })
        }
        onSubmit={async (values, {
          setStatus,
          setSubmitting
        }): Promise<void> => {
          try {
            await paymentApi.removeFlagTransaction(paymentId);
            setStatus({ success: true });
            setSubmitting(false);
            toast.success(`${t('AllFlagsRemovalSuccessful')}`);
            closeDialogWithSuccess();
            window.location.reload();
          } catch (err) {
            console.log(err);
            toast.error(`${t('AllFlagsRemovalFailed')}`);
            setStatus({ success: false });
            setSubmitting(false);
            closeDialogWithoutSuccess();
          }
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
        }): JSX.Element => (
          <form
            onSubmit={handleSubmit}
          >
            <Box bgcolor="background.default">
              <Grid
                container
                sx={{
                  p: 3
                }}
              >
                <Grid
                  container
                >
                  <Grid
                    item
                    xs={11}
                  >
                    <Typography
                      color="textPrimary"
                      variant="h4"
                    >
                      {t('AllFlagsRemovalConfirmation')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    justifyContent="flex-end"
                    xs={1}
                  >
                    <IconButton
                      aria-label="close"
                      onClick={() => { closeDialogWithoutSuccess(); }}
                      size="large"
                    >
                      <X />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  sx={{ mt: 2 }}
                >
                  <Button
                    sx={{
                      mr: 1,
                    }}
                    onClick={closeDialogWithoutSuccess}
                    variant="light"
                  >
                    {t('ButtonCancel')}
                  </Button>
                  <LoadingButton
                    color="primary"
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                  >
                    {t('RemoveAllFlags')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>

          </form>
        )}
      </Formik>
    </Dialog>
  );
});

PaymentFlagDialog.propTypes = {
  // @ts-ignore
  paymentId: PropTypes.number.isRequired,
  openDialog: PropTypes.any.isRequired,
  closeDialogWithSuccess: PropTypes.any.isRequired,
  closeDialogWithoutSuccess: PropTypes.any.isRequired
};

export default PaymentFlagDialog;
