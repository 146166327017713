import type { FC } from 'react';
import PropTypes from 'prop-types';
import { managerApprovalsApi } from 'src/API/managerApprovalsApi';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  Typography,
  Grid,
  IconButton,
  Box
} from '@mui/material';
import X from 'src/icons/X';
import { LoadingButton } from '@mui/lab';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import { AttachmentApprovalDetailDto } from 'src/types/apiSchemas';

interface ApprovalDialogProps {
  managerId?: number;
  id?: number;
  openDialog: any;
  isAttachmentRequired: boolean;
  absenceAttachment: AttachmentApprovalDetailDto;
  closeDialogWithSuccess: any;
  closeDialogWithoutSuccess: any;
  name?: string;
  absenceString?: string;
}

const AbsenceApprovalDialog: FC<ApprovalDialogProps> = observer((props) => {
  const { managerId, id, openDialog, isAttachmentRequired, absenceAttachment, closeDialogWithSuccess, closeDialogWithoutSuccess, name, absenceString } = props;

  const { t } = useTranslation();
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    closeDialogWithoutSuccess();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <Formik
        initialValues={{
          reasonForApproval: '',
          submit: null
        }}
        validationSchema={
          Yup
            .object()
            .shape({
              reasonForApproval: Yup
                .string()
            })
        }
        onSubmit={async (values, {
          resetForm,
          setStatus,
          setSubmitting
        }): Promise<void> => {
          try {
            await managerApprovalsApi.putApproval(managerId, id, 'Approved', values.reasonForApproval);
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            toast.success(`${t('ApprovalSuccessful')}`);
            appStore.notificationStore.refreshApprovalNotifications(user);
            closeDialogWithSuccess();
          } catch (err) {
            console.log(err);
            toast.error(`${t('ApprovalFailed')}`);
            setStatus({ success: false });
            setSubmitting(false);
            closeDialogWithoutSuccess();
          }
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
        }): JSX.Element => (
          <form
            onSubmit={handleSubmit}
          >
            <Box bgcolor="background.default">
              <Grid
                container
                sx={{
                  p: 3
                }}
              >
                <Grid
                  container
                >
                  <Grid
                    item
                    xs={11}
                  >
                    <Typography
                      color="textPrimary"
                      variant="h4"
                    >
                      {t('AbsenceApprovalConfirmation')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="flex-end"
                    xs={1}
                  >
                    <IconButton
                      aria-label="close"
                      onClick={() => { closeDialogWithoutSuccess(); }}
                      size="large"
                    >
                      <X />
                    </IconButton>
                  </Grid>
                  {!absenceAttachment && isAttachmentRequired && (
                    <Grid
                      item
                      xs={11}
                      sx={{ mt: 2, }}
                    >
                      <Typography
                        color="error.main"
                        fontSize="25px"
                        fontWeight="bold"
                        sx={{ mb: 2 }}
                      >
                        {t('AbsenceApprovalWarning')}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                >
                  <Grid
                    item
                    container
                    xs={3}
                    direction="column"
                  >
                    <Grid
                      item
                    >
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {t('ApprovalNameTitle')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      fontWeight={700}
                    >
                      {name}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={3}
                    direction="column"
                  >
                    <Grid
                      item
                    >
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {t('ApprovalReasonAndDurationLabel')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      fontWeight={700}
                    >
                      {absenceString}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{ mt: 1 }}
                  container
                  justifyContent="flex-end"
                >
                  <Button
                    onClick={closeDialogWithoutSuccess}
                    variant="light"
                    sx={{
                      mr: 1
                    }}
                  >
                    {t('ApprovalsCancelButton')}
                  </Button>
                  <LoadingButton
                    color="success"
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                  >
                    {t('ApproveTitle')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>

          </form>
        )}
      </Formik>
    </Dialog>
  );
});

AbsenceApprovalDialog.propTypes = {
  // @ts-ignore
  managerId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  openDialog: PropTypes.any.isRequired,
  closeDialogWithSuccess: PropTypes.any.isRequired,
  closeDialogWithoutSuccess: PropTypes.any.isRequired
};

export default AbsenceApprovalDialog;
