import { Box, Grid } from '@mui/material';
import { FC, useState } from 'react';
import AnnouncementCard from '../announcements/AnnouncementCard';
import { AnnouncementContentCreationDto, AnnouncementContentDto, AnnouncementCreationDto, AnnouncementDetailDto, AnnouncementImportance, AnnouncementScopeDto, AnnouncementTargetGroup, AnnouncementType } from 'src/types/apiSchemas';
import { AnnouncementLink } from 'src/types/announcementLink';
import { WhiteOutlineButton } from 'src/components/WhiteOutlineButton';
import { AnnouncementSenderOption, AnnouncementSenderType } from 'src/types/announcementSenderOption';
import { baseApi } from 'src/API/baseApi';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import { formatInTimeZone } from 'date-fns-tz';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

interface CreateAnnouncementModalProps {
  closeModal: any,
  formValues?: any
}

export const getEventStartTime = (formValues) => (formValues.eventStartTimeUTC ? formatInTimeZone(formValues.eventStartTimeUTC, 'UTC', "yyyy-MM-dd'T'HH:mm:ss'Z'") : null);
export const getEventEndTime = (formValues) => (formValues.eventEndTimeUTC ? formatInTimeZone(formValues.eventEndTimeUTC, 'UTC', "yyyy-MM-dd'T'HH:mm:ss'Z'") : null);
export const getReminderTime = (formValues) => (formValues.reminderDueDateUTC ? formatInTimeZone(formValues.reminderDueDateUTC, 'UTC', "yyyy-MM-dd'T'HH:mm:ss'Z'") : null);

const CreateAnnouncementModal: FC<CreateAnnouncementModalProps> = observer((props) => {
  const { closeModal, formValues } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const getCurrentImageContext = (): string => (formValues.localImage ?? '');

  const getAuthorValues = (): string => {
    const sender: AnnouncementSenderOption = JSON.parse(formValues.sender);
    return sender.name;
  };

  const createAnnouncementContents = (): AnnouncementContentCreationDto[] => {
    const contents: AnnouncementContentCreationDto[] = [];

    // fi
    if (formValues.subjectFi !== '' && formValues.contentFi !== '') {
      contents.push({
        languageCode: 'fi',
        title: formValues.subjectFi,
        content: formValues.contentFi
      });
    }

    // en
    if (formValues.subjectEn !== '' && formValues.contentEn !== '') {
      contents.push({
        languageCode: 'en',
        title: formValues.subjectEn,
        content: formValues.contentEn
      });
    }

    // sv
    if (formValues.subjectSv !== '' && formValues.contentSv !== '') {
      contents.push({
        languageCode: 'sv',
        title: formValues.subjectSv,
        content: formValues.contentSv
      });
    }

    if (contents.length === 0) {
      throw new Error('No content in announcement!');
    }

    return contents;
  };

  const dateChecker = () => (formValues.expirationDate === null ? null : (formatInTimeZone((formValues?.expirationDate), 'UTC', ("yyyy-MM-dd'T'HH:mm:ss'Z'")) || null));

  const createPreview = (): AnnouncementDetailDto => {
    const sender: AnnouncementSenderOption = JSON.parse(formValues.sender);
    const dto: AnnouncementDetailDto = {
      announcementType: formValues.announcementType,
      announcementContents: createAnnouncementContents() as AnnouncementContentDto[],
      authorName: getAuthorValues(),
      pushNotification: formValues.pushNotification,
      confirmationRequired: formValues.responseRequired,
      confirmationLabel: formValues.responseText,
      publishTimeUTC: formatInTimeZone(formValues.releaseDate, 'UTC', "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      expirationTimeUTC: dateChecker(),
      eventStartTimeUTC: formValues.announcementType === AnnouncementType.Event ? getEventStartTime(formValues) : null,
      eventEndTimeUTC: formValues.announcementType === AnnouncementType.Event ? getEventEndTime(formValues) : null,
      reminderDueDateUTC: formValues.announcementType === AnnouncementType.Reminder ? getReminderTime(formValues) : null,
      links: formValues.createdLinks.map((link: AnnouncementLink) => ({ url: link.url, text: link.text })),
      attachedImage: getCurrentImageContext(),
      announcementId: 0,
      importance: formValues.pinAnnouncement ? AnnouncementImportance.Important : AnnouncementImportance.Regular,
      authorCustomerId: sender.customerId,
      authorCompanyId: sender.type === AnnouncementSenderType.Company ? sender.senderId : null,
      authorImageUrl: '',
      announcementScopeDtos: [],
      attachedImageCaption: ''
    };
    return dto;
  };

  const createCreationDto = async () => {
    setLoading(true);
    const sender: AnnouncementSenderOption = JSON.parse(formValues.sender);
    const scopes: AnnouncementScopeDto[] = formValues.selectedScopes.map((x: string) => JSON.parse(x));

    const dto: AnnouncementCreationDto = {
      announcementType: formValues.announcementType,
      importance: formValues.pinAnnouncement ? AnnouncementImportance.Important : AnnouncementImportance.Regular,
      content: createAnnouncementContents(),
      authorCustomerId: sender.customerId,
      customerId: sender.type === AnnouncementSenderType.Customer ? sender.senderId : null,
      authorUserId: sender.type === AnnouncementSenderType.User ? sender.senderId : null,
      authorCompanyId: sender.type === AnnouncementSenderType.Company ? sender.senderId : null,
      confirmationRequired: formValues.responseRequired,
      pushNotification: formValues.pushNotification,
      confirmationLabel: formValues.responseText,
      publishTimeUTC: formatInTimeZone(formValues.releaseDate, 'UTC', "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      expirationTimeUTC: dateChecker(),
      eventStartTimeUTC: formValues.announcementType === AnnouncementType.Event ? getEventStartTime(formValues) : null,
      eventEndTimeUTC: formValues.announcementType === AnnouncementType.Event ? getEventEndTime(formValues) : null,
      reminderDueDateUTC: formValues.announcementType === AnnouncementType.Reminder ? getReminderTime(formValues) : null,
      links: formValues.createdLinks.map((link: AnnouncementLink) => ({ url: link.url, text: link.text })),
      announcementScopes: formValues.targetGroup === AnnouncementTargetGroup.SpecificGroups ? scopes : []
    };

    try {
      let toastMessage = '';
      let imageUploadSuccess = true;

      // 1. Create announcement
      const createdAnnouncement = await baseApi.postAnnouncement(dto);
      toastMessage = toastMessage.concat('', t('CreateAnnouncementModalCreatedSuccessfully'));

      // 2. Upload image
      if (formValues.localImage) {
        try {
          await baseApi.postAnnouncementImage(sender.customerId, createdAnnouncement.announcementId, formValues.localImage, formValues.localImageName);
        } catch (err) {
          imageUploadSuccess = false;
          toastMessage = toastMessage.concat('', t('CreateAnnouncementModalImageAdditionFailed'));
          console.error(err);
        }
      }
      if (imageUploadSuccess) {
        toast.success(toastMessage);
      } else {
        toast(toastMessage, { icon: '⚠️', duration: 3500, });
      }

      setLoading(false);
      closeModal();
      navigate('/dashboard/announcements');
    } catch (error) {
      toast.error(t('CreateAnnouncementModalCreationFailed'));
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Box sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
        <AnnouncementCard
          announcement={createPreview()}
          showArrow={false}
        />
      </Box>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        sx={{ mt: 2 }}
      >
        <WhiteOutlineButton
          aria-label="close"
          onClick={closeModal}
          variant="outlined"
          sx={{ mr: 1 }}
        >
          {t('CreateAnnouncementModalCloseButton')}
        </WhiteOutlineButton>
        <LoadingButton
          color="primary"
          onClick={createCreationDto}
          variant="contained"
          loading={loading}
        >
          {t('CreateAnnouncementModalPublishButton')}
        </LoadingButton>
      </Grid>
    </>
  );
});

export default CreateAnnouncementModal;
