import { useCallback, useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, CircularProgress, Divider, Fade, Grid, Link, Typography, Breadcrumbs, Container } from '@mui/material';
import { AccessPermission, AnnouncementDetailDto, AnnouncementType } from 'src/types/apiSchemas';
import AnnouncementCardImage from 'src/components/dashboard/announcements/AnnouncementCardImage';
import { withStyles } from '@mui/styles';
import useMounted from 'src/hooks/useMounted';
import { baseApi } from 'src/API/baseApi';
import { useNavigate, useParams } from 'react-router';
import { getInitial } from 'src/utils/getInitial';
import { displayLocalDateTime } from 'src/utils/dateUtils';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '../../icons/ChevronRight';
import { Link as RouterLink } from 'react-router-dom';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import AnnouncementDetailsAudinece from 'src/components/dashboard/announcements/AnnouncementDetailsAudience';
import toast from 'react-hot-toast';
import { AuthorizationService, IAuthorizationService } from 'src/Services';
import AnnouncementCardCalendarEvent from 'src/components/dashboard/announcements/AnnouncementCardCalendarEvent';
import AnnouncementCardCalendarReminder from 'src/components/dashboard/announcements/AnnouncementCardCalendarReminder';
import axios from 'axios';

export const getAnnouncementContent = (announcement: AnnouncementDetailDto, lang: string) => {
  const content = announcement?.announcementContents.filter((x) => x.languageCode === lang);
  return content.length > 0 ? content[0] : announcement.announcementContents[0];
};

const AnnouncementDetails = observer(() => {
  const mounted = useMounted();
  const { announcementId } = useParams();
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const settings = appStore.settingsStore.get();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [announcement, setAnnouncement] = useState<AnnouncementDetailDto>();
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState<boolean>(null);
  const auth: IAuthorizationService = new AuthorizationService();
  const [showConfirmButton, setShowConfirmButton] = useState<boolean>(false);

  const announcementContent = announcement ? getAnnouncementContent(announcement, settings?.language) : null;

  const getUserConfirmation = async () => {
    try {
      const hasUserConfirmed = await baseApi.hasUserConfirmedAnnouncement(user.customerId, announcementId, user.userId);
      if (mounted.current) {
        setConfirmButtonDisabled(hasUserConfirmed);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAnnouncement = useCallback(async () => {
    try {
      const data = await baseApi.getAnnouncement(announcementId, user?.customerId);

      if (mounted.current) {
        setAnnouncement(data);
      }
    } catch (err) {
      console.error(err);
      if (axios.isAxiosError(err) && err.response?.status === 404) {
        navigate('/404');
      }
    } finally {
      setLoading(false);
    }
  }, [mounted]);

  useEffect(() => {
    getAnnouncement();
  }, [mounted]);

  const getIsUserInAudience = async () => {
    try {
      const userIsInAudience = await baseApi.IsUserInAnnouncementAudience(announcementId, user.userId);
      if (mounted.current) {
        setShowConfirmButton(userIsInAudience);
        if (userIsInAudience) {
          getUserConfirmation();
        } else {
          setConfirmButtonDisabled(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (announcement && announcement.confirmationRequired) {
      getIsUserInAudience();
    }
  }, [announcement]);

  const handleConfirm = async () => {
    try {
      await baseApi.putAnnouncementConfirmation(announcementId, user.userId);
    } catch (err) {
      console.log(err);
      toast.error(t('AttachmentDetailsConfirmErrorToast'));
    } finally {
      setConfirmButtonDisabled(true);
      toast.success(t('AttachmentDetailsConfirmSuccessToast'));
    }
  };

  const WhiteUnderlineLink = withStyles({
    root: {
      color: '#FFFFFF',
      textDecoration: 'underline #b5b5b5'
    }
  })(Link);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
          mx: 2
        }}
      >
        <Container
          maxWidth="xl"
        >
          {!loading && (
            <Fade in>
              <Grid
                container
              >
                <Grid sx={{
                  minWidth: '55%',
                  maxWidth: '55%',
                }}
                >
                  <Grid
                    container
                    direction="row"
                  >
                    <Breadcrumbs
                      sx={{
                        paddingLeft: '1%',
                      }}
                      aria-label="breadcrumb"
                      separator={(
                        <ChevronRightIcon
                          fontSize="small"
                          sx={{ mb: 5 }}
                        />
                      )}
                    >

                      <Grid
                        item
                        sx={{ mb: 5 }}
                      >
                        <Link
                          color="textPrimary"
                          component={RouterLink}
                          to="/dashboard/landingpage"
                          variant="subtitle2"
                        >
                          {t('LandingPagePageTitle')}
                        </Link>
                      </Grid>
                      <Grid
                        item
                        sx={{ mb: 5 }}
                      >
                        <Link
                          color="textPrimary"
                          component={RouterLink}
                          to="/dashboard/announcements"
                          variant="subtitle2"
                        >
                          {t('AnnouncementsPageTitle')}
                        </Link>
                      </Grid>
                      <Grid item>
                        <Typography
                          color="text.primary"
                          variant="subtitle2"
                          sx={{ mb: 5 }}
                        >
                          {announcementContent?.title}

                        </Typography>
                      </Grid>
                    </Breadcrumbs>
                  </Grid>
                  {(announcement.authorName === user.name || auth.get(user).has(AccessPermission.AnnouncementsWrite).verify()) && (announcement.authorCustomerId === user.customerId) && (
                    <Button
                      sx={{ mb: 2 }}
                      variant="contained"
                      component={RouterLink}
                      to={`/dashboard/announcements/${announcement.announcementId}/edit`}
                    >
                      {t('AnnouncementsEditAnnouncementButtonTitle')}
                    </Button>
                  )}

                  <Card sx={{
                    backgroundColor: 'primary.cardBackground',
                  }}
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      alignItems="center"
                      xs={12}
                      sx={{
                        mx: 3,
                        my: 2
                      }}
                    >
                      <Grid
                        item
                        container
                        xs={10}
                        direction="row"
                      >
                        <Avatar
                          src={announcement.authorImageUrl}
                          sx={{
                            height: 32,
                            width: 32,
                          }}
                        >
                          {getInitial(announcement.authorName)}
                        </Avatar>
                        <Typography
                          color="textSecondary"
                          sx={{ mx: 1 }}
                        >
                          {announcement.authorName}
                        </Typography>
                        <Typography
                          color="textSecondary"
                        >
                          •
                        </Typography>
                        <Typography
                          color="textSecondary"
                          sx={{ mx: 1 }}
                        >
                          {displayLocalDateTime(announcement.publishTimeUTC, true)}
                        </Typography>
                        {announcement.confirmationRequired && (
                          <Typography>
                            {t('AnnouncementConfirmationRequiredLabel')}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        container
                        direction="row"
                        xs={2}
                        sx={{ px: 3 }}
                      >
                        {showConfirmButton && (
                          <Button
                            onClick={() => handleConfirm()}
                            disabled={confirmButtonDisabled || confirmButtonDisabled === null}
                            variant="contained"
                          >
                            {announcement.confirmationLabel}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        mx: 3
                      }}
                    >
                      <Typography
                        color="textPrimary"
                        variant="h5"
                        fontWeight={700}
                      >
                        {/* TODO: Handle english and Finnish options */}
                        {announcementContent.title}
                      </Typography>
                    </Grid>
                    {announcement.announcementType === AnnouncementType.Event && announcement.eventStartTimeUTC && (
                      <Grid
                        item
                        sx={{ mx: 3 }}
                      >
                        <Typography>
                          {displayLocalDateTime(announcement.eventStartTimeUTC, true)}
                          {announcement.eventEndTimeUTC && (announcement.eventEndTimeUTC !== announcement.eventStartTimeUTC) && ` - ${displayLocalDateTime(announcement.eventEndTimeUTC, true)}`}
                        </Typography>
                      </Grid>
                    )}
                    {announcement.announcementType === AnnouncementType.Reminder && announcement.reminderDueDateUTC && (
                      <Grid
                        item
                        sx={{ mx: 3 }}
                      >
                        <Typography>
                          {displayLocalDateTime(announcement.reminderDueDateUTC, true)}
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      container
                      justifyContent="center"
                    >
                      {announcement.attachedImage && (
                        <AnnouncementCardImage
                          imageUrl={announcement.attachedImage}
                          caption={announcement.attachedImageCaption}
                        />
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{
                        mx: 3,
                        my: 3
                      }}
                    >
                      <Divider sx={{ mb: 2 }} />
                      <Box>
                        <Typography
                          color="textPrimary"
                          sx={{
                            margin: 2,
                            fontSize: 20
                          }}
                        >
                          {announcementContent.content}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      sx={{ my: 3, mx: 3 }}
                    >
                      <Divider sx={{ mb: 2 }} />
                      {announcement.links.map((link) => (
                        <Grid
                          item
                          xs={12}
                          sx={{ my: 1 }}
                          key={link.url}
                        >
                          <WhiteUnderlineLink
                            href={link.url}
                          >
                            <Typography
                              color="textPrimary"
                              fontWeight={600}
                            >
                              {link.text}
                            </Typography>
                          </WhiteUnderlineLink>
                        </Grid>
                      ))}
                    </Grid>
                    {announcement.announcementType === AnnouncementType.Event && announcement.eventStartTimeUTC && (
                      <Box sx={{ margin: 3 }}>
                        <AnnouncementCardCalendarEvent
                          start={announcement.eventStartTimeUTC}
                          end={announcement.eventEndTimeUTC}
                          title={announcementContent.title}
                          description={announcementContent.content}
                        />
                      </Box>
                    )}
                    {announcement.announcementType === AnnouncementType.Reminder && announcement.reminderDueDateUTC && (
                      <Box sx={{ margin: 3 }}>
                        <AnnouncementCardCalendarReminder
                          reminderTime={announcement.reminderDueDateUTC}
                          title={announcementContent.title}
                          description={announcementContent.content}
                        />
                      </Box>
                    )}
                  </Card>
                  {announcement.confirmationRequired && auth.get(user).has(AccessPermission.AnnouncementsWrite).verify() && (
                    <AnnouncementDetailsAudinece
                      announcement={announcement}
                      confirmButtonDisabled={confirmButtonDisabled}
                    />
                  )}
                </Grid>
              </Grid>
            </Fade>
          )}
          {loading && (
            <Box
              display="flex"
              justifyContent="center"
              sx={{ mt: 10 }}
              bgcolor="background.secondary"
            >
              <CircularProgress
                size={65}
              />
            </Box>
          )}
        </Container>
      </Box>
    </>

  );
});

export default AnnouncementDetails;
