export enum StorageType {
  localStorage = 'localStorage',
  sessionStorage = 'sessionStorage'
}

export interface ICacheService {
  get<T>(key: string, type?: StorageType): T;
  set<T>(key: string, value: T, type?: StorageType, expiry?: number): void;
  remove(key: string, type?: StorageType): void;
}

export class CacheService implements ICacheService {
  private expirySuffix: string = '.expiry';

  private getStorage(type?: StorageType): Storage {
    if (!window) {
      return undefined;
    }
    if (type) {
      switch (type) {
        case StorageType.localStorage:
          return window.localStorage;
        default:
          return window.sessionStorage;
      }
    }
    return window.sessionStorage;
  }

  public get<T>(key: string, type?: StorageType): T {
    try {
      const storage = this.getStorage(type);
      if (!storage) { return undefined; }

      // Check if expiry key exists in the storage
      const cacheValueExpiry: string = storage.getItem(`${key}${this.expirySuffix}`);

      if (cacheValueExpiry) {
        // if found, check if the cache has expired
        const expirationTime: number = Number(cacheValueExpiry);
        const currentTime: number = Date.now();

        if (expirationTime > currentTime) {
          // cache still valid
          const cacheValue: string = storage.getItem(key);

          if (cacheValue) {
            const cacheObject: T = JSON.parse(cacheValue);
            return cacheObject;
          }
        } else {
          // cache expired. remove the items from storage.
          this.remove(key, type);
        }
      }
    } catch (error) {
      console.error(error);
    }

    return undefined;
  }

  public set<T>(key: string, value: T, type?: StorageType, expiry: number = (0.25 * 60 * 60 * 1000)): void {
    // Default expiry is 15 minutes unless explicitly passed in as a param
    const cacheExpiry = Date.now() + expiry;
    const storage = this.getStorage(type);
    if (!storage) { return; }
    storage.setItem(key, JSON.stringify(value));
    storage.setItem(`${key}${this.expirySuffix}`, cacheExpiry.toString());
  }

  public remove(key: string, type?: StorageType): void {
    const storage = this.getStorage(type);
    if (!storage) { return; }
    storage.removeItem(key);
    storage.removeItem(`${key}${this.expirySuffix}`);
  }
}
