import { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Scrollbar from '../../Scrollbar';
import { DashboardPaymentTransactionsQueryParameters, TransactionDto } from '../../../types/apiSchemas';
import { useTranslation } from 'react-i18next';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import { nanoid } from 'nanoid';
import ChevronRightButton from 'src/components/ChevronRightButton';
import { formatNumber } from 'src/utils/numberUtils';

interface PaymentTransactionProps {
  transactions: TransactionDto[];
  queryParams: DashboardPaymentTransactionsQueryParameters;
  clicked: () => void;
}

const PaymentUnChangedTransactionCard: FC<PaymentTransactionProps> = (props) => {
  const { transactions, queryParams, clicked, ...other } = props;
  const { t } = useTranslation();

  const filteredUnChanged = transactions.filter((transaction) => transaction.changes === undefined);
  if (filteredUnChanged.length === 0) {
    return null;
  }

  return (
    <>
      <Card
        {...other}
      >
        <CardHeader
          title={`${t('PaymentTransactionsUnChangedTitle')}`}
        />
        <Divider />
        <Scrollbar>
          <Table id="transactionTable">
            <TableHead>
              <TableRow key={nanoid()}>
                <TableCell sx={{ width: '20%' }}>
                  <Typography variant="tableHead">{t('TransactionRecipientTitle')}</Typography>
                </TableCell>
                <TableCell sx={{ width: '20%' }}>
                  <Typography variant="tableHead">{t('TransactionDescriptionTitle')}</Typography>
                </TableCell>
                <TableCell sx={{ width: '20%' }}>
                  <Typography variant="tableHead">{t('TransactionNetAmountTitle')}</Typography>
                </TableCell>
                <TableCell sx={{ width: '20%' }} />
                <TableCell sx={{ width: '20%' }} />
                <TableCell sx={{ width: '20%' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUnChanged.map((filteredTransaction) => (
                <TableRow key={filteredTransaction.transactionId}>
                  <TableCell sx={{ width: '25%' }}>
                    <Typography>{filteredTransaction.recipient}</Typography>
                  </TableCell>
                  <TableCell sx={{ width: '25%' }}>
                    <Typography>{filteredTransaction.description}</Typography>
                  </TableCell>
                  <TableCell sx={{ width: '25%' }}>
                    <Typography noWrap>{formatNumber(filteredTransaction.netAmount)}</Typography>
                  </TableCell>
                  <TableCell sx={{ width: '25%' }} />
                  <TableCell>
                    {filteredTransaction.flagged === true && (
                      <Tooltip
                        key="comment"
                        title={filteredTransaction.comments}
                      >
                        <EmojiFlagsIcon sx={{ color: 'error.main' }} />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell
                    align="right"
                  >
                    {filteredTransaction.payslipId ? (
                      <ChevronRightButton
                        to={`/dashboard/payments/${filteredTransaction.paymentId}/${filteredTransaction.payslipId}`}
                        size={{ height: 25, width: 25 }}
                        state={{ queryParams }}
                        onClick={clicked}
                      />
                    ) : (
                      <ChevronRightButton
                        to={`/dashboard/payments/${filteredTransaction.paymentId}/${filteredTransaction.transactionId}/payslip`}
                        size={{ height: 25, width: 25 }}
                        state={{ queryParams }}
                        onClick={clicked}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </Card>
    </>
  );
};

PaymentUnChangedTransactionCard.propTypes = {
  transactions: PropTypes.array.isRequired
};

export default PaymentUnChangedTransactionCard;
