export const isFloat = (str: string) => {
  const floatRegex = /^[+-]?([0-9]*[.])?[0-9]+$/;
  return floatRegex.test(str);
};

export const formatNumber = (number: number | string) => {
  try {
    const string = `${number}`.replaceAll(',', '.').replaceAll(' ', '').replaceAll('€', '');

    if (!isFloat(string)) {
      return number;
    }

    const numeric = parseFloat(string);

    if (Number.isNaN(numeric)) {
      return number;
    }

    const formattedString = new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).format(
      numeric,
    );
    return formattedString;
  } catch (err) {
    return number;
  }
};
