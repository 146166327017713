/* eslint-disable @typescript-eslint/indent */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, makeAutoObservable, runInAction, configure } from 'mobx';
import { SiteUser } from 'src/types/siteUser';
import { NotificationService, INotificationService } from 'src/Services';
import { autoSaveToStorage } from './autoSaveToStorage';
import { StorageKeys, allCustomersId } from 'src/constants';

/**
 * configure added, more details https://stackoverflow.com/posts/64771774/revisions
 */
configure({
  enforceActions: 'never'
});

export interface NotificationInfo {
  pendingPaymentsCount: number;
  pendingApprovalsCount: number;
  pendingAccessRequestCount: number;
  unreadMessageThreadCount: number;
  unreadSolvedMessageThreadCount: number;
  notificationsFetched?: boolean;
}

export interface CustomerNotification {
  count: number;
  customerId: number;
  threadIds?: string[];

  solvedCount?: number;
  solvedThreadIds?: string[];
}

export interface NotifcationAmounts {
  pendingPaymentNotifications: CustomerNotification[];
  pendingApprovalNotifications: CustomerNotification[];
  pendingAccessRequestNotifications: CustomerNotification[];
  unreadMessageThreads: CustomerNotification[];
}

export interface Notifications {
  notifications?: NotifcationAmounts;
  notificationsFetched?: boolean;
}

export class NotificationStore {
  private static instance = null;

  constructor() {
    makeAutoObservable(this);
    autoSaveToStorage(this, StorageKeys.notificationStoreKey);
  }

  public static getInstance(): NotificationStore {
    if (NotificationStore.instance === null) {
      NotificationStore.instance = new NotificationStore();
    }
    return NotificationStore.instance;
  }

  private notificationInfo: Notifications = {
    notifications: {
      pendingPaymentNotifications: [],
      pendingApprovalNotifications: [],
      pendingAccessRequestNotifications: [],
      unreadMessageThreads: []
    }
  };

  @action.bound
  private set(value: Notifications): void {
    this.notificationInfo = value;
  }

  @action.bound
  private setPendingPaymentsNotifications(value: CustomerNotification[]): void {
    if (this.notificationInfo.notifications) {
      this.notificationInfo.notifications.pendingPaymentNotifications = value;
    }
  }

  @action.bound
  private setPendingApprovalNotifications(value: CustomerNotification[]): void {
    if (this.notificationInfo.notifications) {
      this.notificationInfo.notifications.pendingApprovalNotifications = value;
    }
  }

  @action.bound
  private setPendingAccessRequestNotifications(value: CustomerNotification[]): void {
    if (this.notificationInfo.notifications) {
      this.notificationInfo.notifications.pendingAccessRequestNotifications = value;
    }
  }

  @action.bound
  private setUnreadMessagesNotifications(value: CustomerNotification[]): void {
    if (this.notificationInfo.notifications) {
      this.notificationInfo.notifications.unreadMessageThreads = value;
    }
  }

  @action.bound
  public get(customerId: number): NotificationInfo {
    return {
      pendingApprovalsCount: this.getPendingApprovalNotificationCountByCustomerId(customerId),
      pendingPaymentsCount: this.getPendingPaymentNotificationCountByCustomerId(customerId),
      pendingAccessRequestCount: this.getPendingAccessRequestNotificationCountByCustomerId(customerId),
      unreadMessageThreadCount: this.getUnreadMessageThreads(customerId),
      unreadSolvedMessageThreadCount: this.getUnreadSolvedMessageThreads(customerId),
      notificationsFetched: this.notificationInfo.notificationsFetched
    } as NotificationInfo;
  }

  @action.bound
  public getAllUnreadMessageThreads() {
    return this.notificationInfo.notifications?.unreadMessageThreads.filter(n => n.customerId === allCustomersId).map((n) => n.threadIds);
  }

  @action.bound
  public markMessageThreadAsRead(messageThreadId: string) {
    if (this.notificationInfo.notifications) {
      const updated = this.notificationInfo.notifications.unreadMessageThreads.map((n) => {
        const filteredIds = n.threadIds?.filter((id) => id !== messageThreadId) ?? [];
        const filteredSolvedIds = n.solvedThreadIds?.filter((id) => id !== messageThreadId) ?? [];
        return { ...n, count: filteredIds.length, threadIds: filteredIds, solvedCount: filteredSolvedIds.length, solvedThreadIds: filteredSolvedIds };
      });
      this.notificationInfo.notifications.unreadMessageThreads = updated;
    }
  }

  @action.bound
  private getPendingPaymentNotificationCountByCustomerId(customerId: number): number {
    const customerNotifications = this.notificationInfo?.notifications?.pendingPaymentNotifications?.filter((n) => !!customerId && n.customerId === customerId);
    if (customerNotifications?.length === 1) {
      return customerNotifications[0].count;
    }
    return 0;
  }

  @action.bound
  private getPendingApprovalNotificationCountByCustomerId(customerId: number): number {
    const customerNotifications = this.notificationInfo?.notifications?.pendingApprovalNotifications?.filter((n) => !!customerId && n.customerId === customerId);
    if (customerNotifications?.length === 1) {
      return customerNotifications[0].count;
    }
    return 0;
  }

  @action.bound
  private getPendingAccessRequestNotificationCountByCustomerId(customerId: number): number {
    const customerNotifications = this.notificationInfo?.notifications?.pendingAccessRequestNotifications?.filter((n) => !!customerId && n.customerId === customerId);
    if (customerNotifications?.length === 1) {
      return customerNotifications[0].count;
    }
    return 0;
  }

  @action.bound
  private getUnreadMessageThreads(customerId: number): number {
    const customerNotifications = this.notificationInfo?.notifications?.unreadMessageThreads?.filter((n) => (customerId || customerId === 0) && n.customerId === customerId);
    if (customerNotifications?.length === 1) {
      return customerNotifications[0].count;
    }
    return 0;
  }

  @action.bound
  private getUnreadSolvedMessageThreads(customerId: number): number {
    const customerNotifications = this.notificationInfo?.notifications?.unreadMessageThreads?.filter((n) => (customerId || customerId === 0) && n.customerId === customerId);
    if (customerNotifications?.length === 1) {
      return customerNotifications[0].solvedCount;
    }
    return 0;
  }

  @action.bound
  public refreshAllNotifications = async (user: SiteUser): Promise<void> => {
    try {
      const notificationService: INotificationService = new NotificationService();
      const notifications = await notificationService.getPendingNotifications(user, this.notificationInfo);

      runInAction(() => {
        this.set(notifications);
      });
    } catch (err) {
      console.error(`refreshAllNotifications:: error, ${err}`);
    }
  };

  @action.bound
  public refreshPaymentNotifications = async (user: SiteUser): Promise<void> => {
    try {
      const notificationService: INotificationService = new NotificationService();
      const paymentNotifications = await notificationService.getAndReturnPendingPaymentNotifications(user, this.notificationInfo);

      runInAction(() => {
        this.setPendingPaymentsNotifications(paymentNotifications);
      });
    } catch (err) {
      console.error(`refreshPaymentNotifications:: error, ${err}`);
    }
  };

  @action.bound
  public refreshApprovalNotifications = async (user: SiteUser): Promise<void> => {
    try {
      const notificationService: INotificationService = new NotificationService();
      const approvalNotifications = await notificationService.getAndReturnPendingApprovalNotifications(user, this.notificationInfo);

      runInAction(() => {
        this.setPendingApprovalNotifications(approvalNotifications);
      });
    } catch (err) {
      console.error(`refreshApprovalNotifications:: error, ${err}`);
    }
  };

  @action.bound
  public refreshAccessRequestNotifications = async (user: SiteUser): Promise<void> => {
    try {
      const notificationService: INotificationService = new NotificationService();
      const accessRequestNotifications = await notificationService.getAndReturnPendingAccessRequestNotifications(user, this.notificationInfo);

      runInAction(() => {
        this.setPendingAccessRequestNotifications(accessRequestNotifications);
      });
    } catch (err) {
      console.error(`refreshAccessRequestNotifications:: error, ${err}`);
    }
  };

  @action.bound
  public refreshUnreadMessagesNotifications = async (user: SiteUser): Promise<void> => {
    try {
      const notificationService: INotificationService = new NotificationService();
      const unreadMessagesNotifications = await notificationService.getAndReturnUnreadMessageThreadsNotifications(user, this.notificationInfo);

      runInAction(() => {
        this.setUnreadMessagesNotifications(unreadMessagesNotifications);
      });
    } catch (err) {
      console.error(`refreshUnreadMessagesNotifications:: error, ${err}`);
    }
  };
}
