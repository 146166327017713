import { autorun, set, toJS } from 'mobx';
import { storagePrefix } from 'src/constants';

export const autoSaveToStorage = (_this: any, name: string, storage?: Storage) => {
  const key = `${storagePrefix}:${name}`;

  if (!storage) { storage = window.localStorage; }

  const storedJson = storage.getItem(key);
  if (storedJson) {
    set(_this, JSON.parse(storedJson));
  }
  autorun(() => {
    const value = toJS(_this);
    storage.setItem(key, JSON.stringify(value));
  });
};
