import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, Drawer, Grid, Badge, Typography, Avatar } from '@mui/material';
import type { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import AnnouncementsIcon from '../../icons/Announcements';
import LandingPageIcon from '../../icons/LandingPage';
import HideNavigation from '../../icons/HideNavigation';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ChartPieIcon from '../../icons/ChartPie';
import BarChartIcon from '@mui/icons-material/BarChart';
import HolidayBonusChangeIcon from '@mui/icons-material/CurrencyExchange';
import SickOutlinedIcon from '@mui/icons-material/SickOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CommentIcon from '@mui/icons-material/Comment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { useTranslation } from 'react-i18next';
import { useAppStore } from 'src/store/mobx/appStore';
import { AccessPermission, Report, ReportingNavigation, ReportingWorkspace } from 'src/types/apiSchemas';
import { observer } from 'mobx-react';
import { AuthorizationService, IAuthorizationService } from 'src/Services';
import { reportServiceApi } from 'src/API/reportServiceApi';
import useMounted from 'src/hooks/useMounted';
import { holidayBonusApi } from 'src/API/holidayBonusApi';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CustomerSelection from './customerSelection/CustomerSelection';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { getInitials } from 'src/utils/getInitial';
import CustomerLinks from './CustomerLinks';
import stateStore from 'src/store/mobx/stateStore';
import GroupIcon from '@mui/icons-material/Group';
interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  toggleDesktopNavigation: () => void;
  sidebarDesktopOpen: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = observer((props) => {
  const { onMobileClose, openMobile, toggleDesktopNavigation, sidebarDesktopOpen } = props;
  const location = useLocation();
  const appStore = useAppStore();
  const settings = appStore.settingsStore.get();
  const { user } = appStore.loginStore.get();
  const notificationInfo = appStore.notificationStore.get(user?.customerId);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { t } = useTranslation();
  const mounted = useMounted();
  const [reportingNavigation, setReportingNavigation] = useState<ReportingNavigation>(null);
  const [reportingNavigationLoading, setReportingNavigationLoading] = useState<boolean>(true);
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const auth: IAuthorizationService = new AuthorizationService();
  const [showHolidayChangePeriodNavigation, setShowHolidayChangePeriodNavigation] = useState(auth.get(user).isHR && auth.get(user).hasHolidayBonusChangeAccess);

  // loading reporting related navigation from RetA backend
  const loadReportNavigation = useCallback(async () => {
    if (mounted.current) {
      setReportingNavigationLoading(true);
      if (user?.customerId) {
        try {
          const reportingNavi = await reportServiceApi.loadReportNavigation(user, settings.language);
          setReportingNavigation(reportingNavi);
        } catch (err) {
          console.log(err);
        }
      }
      setReportingNavigationLoading(false);
    }
  }, [settings.language]);

  const validateHolidayPeriodAndBalance = async () => {
    try {
      const activeHolidayPeriod = await holidayBonusApi.getOngoingHolidayBonusChangePeriod();
      if (activeHolidayPeriod) {
        const userBalance = await holidayBonusApi.getHolidayBonusChangePeriodBalance(activeHolidayPeriod.holidayBonusChangePeriodId);
        const isValid = userBalance.items.at(0)?.amount > 0;
        return isValid;
      }
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  useEffect(() => {
    if (mounted.current) {
      setReportingNavigation(null);
      loadReportNavigation();
    }
  }, [settings.language]);

  useEffect(() => {
    // Holiday bonus page link is only shown to HR by default. But in the case (a non-HR) user is in their employment
    // customer context, link is shown to user if ongoing change period is in progress and user has holiday balance.
    if (user && user.customerId === user.employmentCustomerId && !auth.get(user).isHR && auth.get(user).hasHolidayBonusChangeAccess) {
      validateHolidayPeriodAndBalance().then(isValid => {
        setShowHolidayChangePeriodNavigation(isValid);
      });
    }
    stateStore.fetchMessageViews();
  }, []);

  function generateSidebarEntries(): any[] {
    const sections = [];
    const itemList = [];

    const showPaymentAmount = notificationInfo?.notificationsFetched
      && notificationInfo?.pendingPaymentsCount > 0;

    const showApprovalAmount = notificationInfo?.notificationsFetched
      && notificationInfo?.pendingApprovalsCount > 0;

    const showAccessRequestAmount = notificationInfo?.notificationsFetched
      && notificationInfo?.pendingAccessRequestCount > 0;

    const showMessageThreadAmount = notificationInfo?.notificationsFetched
      && notificationInfo?.unreadMessageThreadCount > 0;

    const showUnreadSolvedMessageThreadAmount = notificationInfo?.notificationsFetched
      && notificationInfo?.unreadSolvedMessageThreadCount > 0;

    itemList.push(
      {
        title: t('LandingPagePageTitle'),
        path: '/dashboard/landingpage',
        icon:
          <LandingPageIcon
            style={{ fontSize: 18, width: 20 }}
          />,
        amount: null
      }
    );

    if (auth.get(user).has(AccessPermission.AnnouncementsAccess).verify()) {
      itemList.push(
        {
          title: t('AnnouncementsPageTitle'),
          path: '/dashboard/announcements',
          icon: <AnnouncementsIcon fontSize="small" />,
          amount: null
        }
      );
    }
    if (auth.get(user).isPaymentApproverOrReviewer
      || auth.get(user).isPayroll) {
      itemList.push(
        {
          title: t('PaymentPageTitle'),
          path: '/dashboard/payments',
          icon: <EuroSymbolIcon fontSize="small" />,
          amount: showPaymentAmount && (!lgUp || sidebarDesktopOpen) ? (
            <Grid
              container
              color="text.primary"
              justifyContent="center"
              alignContent="center"
              sx={{
                borderRadius: 30,
                width: 30,
                height: 30,
                backgroundColor: 'primary.main'
              }}
            >
              <Grid item>
                <Badge
                  badgeContent={notificationInfo.pendingPaymentsCount}
                  color="primary"
                  sx={{ '& .MuiBadge-badge': { fontSize: '1rem', fontWeight: 700, width: 30, height: 30, borderRadius: 15 } }}
                />
              </Grid>
            </Grid>

          ) : <></>
        }
      );
    }
    if (auth.get(user).hasManagerOrSubstituteManagerAccess || (auth.get(user).isHR && auth.get(user).hasHolidayBonusChangeAccess)) {
      itemList.push(
        {
          title: t('ApprovalsPageTitle'),
          path: '/dashboard/approvals',
          icon: <DoneAllIcon fontSize="small" />,
          amount: showApprovalAmount && (!lgUp || sidebarDesktopOpen) ? (
            <Grid
              container
              color="text.primary"
              justifyContent="center"
              alignContent="center"
              sx={{
                borderRadius: 30,
                width: 30,
                height: 30,
                backgroundColor: 'primary.main'
              }}
            >
              <Grid
                item
                justifyContent="center"
              >
                <Badge
                  badgeContent={notificationInfo.pendingApprovalsCount}
                  color="primary"
                  sx={{ '& .MuiBadge-badge': { fontSize: '1rem', fontWeight: 700, width: 30, height: 30, borderRadius: 15 } }}
                />
              </Grid>
            </Grid>

          ) : <></>
        }
      );
    }
    if (showHolidayChangePeriodNavigation) {
      itemList.push({
        title: t('HolidayBonusChangePageTitle'),
        path: '/dashboard/holidaybonuschange',
        icon: <HolidayBonusChangeIcon fontSize="small" />
      });
    }

    if (auth.get(user).has(AccessPermission.AbsencesAccess).verify()) {
      itemList.push({
        title: t('SickLeavePageTitle'),
        path: '/dashboard/absences',
        icon: <SickOutlinedIcon fontSize="small" />
      });
    }

    if (auth.get(user).has(AccessPermission.AttachmentsAccess).verify()) {
      itemList.push({
        title: t('AttachmentsPageTitle'),
        path: '/dashboard/attachments',
        icon: <AttachFileIcon fontSize="small" />
      });
    }

    if (auth.get(user).has(AccessPermission.MessagingAccess).verify() || (user?.customerId === 0 && auth.get(user).anyCustomerHas(AccessPermission.MessagingAccess).verify())) {

      const { messageViews } = stateStore;
      const filteredMessageViews = messageViews.filter(f => f.customerId === user.customerId);

      const savedFilterSetItems = [
        {
          title: t('MessageViewsYourViews'),
          customeElement: <div key="your-views" style={{ marginLeft: 50 }}><Typography sx={{ color: 'primary.sidebarText', opacity: 0.7, mt: 1, mb: 1 }}>{t('MessageViewsYourViews')}</Typography></div>
        }, ...filteredMessageViews.map(f => ({
          title: f.name,
          path: `/dashboard/messages/views/${f.urlName}`,
          icon: <CommentIcon fontSize='small' />
        }))];

      const filterSetItems = [...(filteredMessageViews.length > 0 ? savedFilterSetItems : [])];

      itemList.push({
        title: t('MessagesPageTitle'),
        path: '/dashboard/messages/allunsolved',
        icon: <CommentIcon fontSize="small" />,
        showMessageActions: (!lgUp || sidebarDesktopOpen),
        amount: showMessageThreadAmount && (!lgUp || sidebarDesktopOpen) ? (
          <Grid
            container
            color="text.primary"
            justifyContent="center"
            alignContent="center"
            sx={{
              borderRadius: 30,
              width: 30,
              height: 30,
              backgroundColor: 'primary.main'
            }}
          >
            <Grid
              item
              justifyContent="center"
            >
              <Badge
                badgeContent={notificationInfo.unreadMessageThreadCount}
                color="primary"
                sx={{ '& .MuiBadge-badge': { fontSize: '1rem', fontWeight: 700, width: 30, height: 30, borderRadius: 15 } }}
              />
            </Grid>
          </Grid>
        ) : null,
        children: [{
          title: t('MessagesAllUnsolved'),
          path: '/dashboard/messages/allunsolved',
          icon: <CommentIcon fontSize='small' />
        },
        {
          title: t('MessagesMyUnsolved'),
          path: '/dashboard/messages/myunsolved',
          icon: <CommentIcon fontSize='small' />
        },
        {
          title: t('MessageNotAssigned'),
          path: '/dashboard/messages/unassigned',
          icon: <CommentIcon fontSize='small' />
        },
        {
          title: t('MessagesAllSolved'),
          path: '/dashboard/messages/allsolved',
          icon: <CommentIcon fontSize='small' />,
          amount: showUnreadSolvedMessageThreadAmount && (!lgUp || sidebarDesktopOpen) ? (
            <Grid
              container
              color="text.primary"
              justifyContent="center"
              alignContent="center"
              sx={{
                borderRadius: 30,
                width: 30,
                height: 30,
                backgroundColor: 'primary.main'
              }}
            >
              <Grid
                item
                justifyContent="center"
              >
                <Badge
                  badgeContent={notificationInfo.unreadSolvedMessageThreadCount}
                  color="primary"
                  sx={{ '& .MuiBadge-badge': { fontSize: '1rem', fontWeight: 700, width: 25, height: 25, borderRadius: 15 } }}
                />
              </Grid>
            </Grid>
          ) : <></>,
        },
        {
          title: t('MessagesAllMessages'),
          path: '/dashboard/messages',
          icon: <CommentIcon fontSize='small' />
        }, ...filterSetItems],
      });
    }

    if (auth.get(user).has(AccessPermission.EmployeePayslips).verify()) {
      itemList.push({
        title: t('PayslipsPageTitle'),
        path: '/dashboard/payslips',
        icon: <ReceiptIcon fontSize="small" />
      });
    }

    if (user.isConsultantUser && user?.customerId !== 0) {
      itemList.push({
        title: t('AccessRequestPageTitle'),
        path: '/dashboard/accessRequestList',
        icon: <GroupAddIcon fontSize="small" />,
        amount: showAccessRequestAmount && (!lgUp || sidebarDesktopOpen) ? (
          <Grid
            container
            color="text.primary"
            justifyContent="center"
            alignContent="center"
            sx={{
              borderRadius: 30,
              width: 30,
              height: 30,
              backgroundColor: 'primary.main'
            }}
          >
            <Grid
              item
              justifyContent="center"
            >
              <Badge
                badgeContent={notificationInfo.pendingAccessRequestCount}
                color="primary"
                sx={{ '& .MuiBadge-badge': { fontSize: '1rem', fontWeight: 700, width: 30, height: 30, borderRadius: 15 } }}
              />
            </Grid>
          </Grid>

        ) : <></>
      });
    }
    if (auth.get(user).isMainUser) {
      itemList.push(
        {
          title: t('ManageUsersPageTitle'),
          path: '/dashboard/users',
          icon: <GroupIcon fontSize="small" />,
          amount: null,
        }
      );
    }

    // displaying reporting related navigation from RetA backend
    if (auth.get(user).has(AccessPermission.Reporting).verify() && reportingNavigation && reportingNavigation.workspaces?.length > 0) {
      const userPermissions = user?.accessPermissions[0];
      if (reportingNavigation.workspaces.length === 1 && userPermissions?.accessPermissions?.length === 1) {
        // there is only one reporting workspace for user and reporting is the only thing visible on left navigation
        // => will show a flat reporting navigation with all reports on the 1st level
        const workspace = reportingNavigation.workspaces[0];
        workspace.reports?.sort((r1, r2) => r1.name.localeCompare(r2.name)).forEach((report: Report) => {
          const path = `/dashboard/${reportingNavigation.isDemoNavigation ? 'demo' : 'reports'}/workspace/${workspace.id}/report/${report.id}/${report.name}`;
          itemList.push({
            title: report.name,
            path,
            icon: <BarChartIcon fontSize="small" />
          });
        });
      } else {
        // in other cases
        // => will show 2-dimensional reporting navigation with workspaces on 1st level and reports under them on 2nd level
        reportingNavigation.workspaces.forEach((workspace: ReportingWorkspace) => {
          const navItem = {
            title: workspace.name,
            path: '#',
            icon: <ChartPieIcon fontSize="small" />,
            amount: null,
            children: []
          };
          workspace.reports?.sort((r1, r2) => r1.name.localeCompare(r2.name)).forEach((report: Report) => {
            const path = `/dashboard/${reportingNavigation.isDemoNavigation ? 'demo' : 'reports'}/workspace/${workspace.id}/report/${report.id}/${report.name}`;
            navItem.children.push({
              title: report.name,
              path,
              icon: <BarChartIcon fontSize="small" />
            });
          });
          itemList.push(navItem);
        });
      }
    }

    // Add all items under same section
    sections.push(
      {
        title: '',
        items: itemList
      }
    );

    return sections;
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingTop: '90px',
        color: 'primary.contrastText'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        {lgUp && (
          <Box sx={{ pl: sidebarDesktopOpen ? 2 : '6px', pb: 1, mb: 1, borderBottom: '1px solid', borderColor: 'primary.tableBorderColor' }}>
            <CustomerSelection
              displayMenu
              fullWidth
            >
              {!sidebarDesktopOpen && lgUp
                && (
                  <Avatar
                    sx={{
                      height: 42,
                      width: 42
                    }}
                  >
                    {user?.customerId === 0 ? <WorkspacesIcon /> : getInitials(user?.customerName)}
                  </Avatar>
                )}
              {sidebarDesktopOpen
                && (
                  <Typography
                    component="span"
                    variant="h6"
                    sx={{
                      mb: 1,
                      paddingTop: 1,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: 'primary.sidebarText'
                    }}
                  >
                    {user?.customerId === 0 ? t('AllCustomers') : user?.customerName}
                  </Typography>
                )}
            </CustomerSelection>
          </Box>
        )}
        <Box>
          {generateSidebarEntries().map((section) => (
            <NavSection
              key={section.title}
              hideTitle={lgUp && !sidebarDesktopOpen}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
          {reportingNavigationLoading && (
            <Box
              display="flex"
              justifyContent="center"
              sx={{ mt: 10 }}
              bgcolor="background.secondary"
            >
              <CircularProgress
                size={25}
              />
            </Box>
          )}
        </Box>
        {mdDown && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: '20px 10px', alignItems: 'flex-start', gap: 5 }}>
            <CustomerLinks />
          </div>
        )}
        {lgUp && (
          <NavSection
            hideTitle={lgUp && !sidebarDesktopOpen}
            pathname=""
            title=""
            onClick={toggleDesktopNavigation}
            items={[
              { title: t('HideNavigation'), icon: <HideNavigation /> }
            ]}
            sx={{
              marginTop: '100px'
            }}
          />
        )}
      </Scrollbar>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
      />
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'primary.navigationBackground',
            height: '100%',
            width: sidebarDesktopOpen ? 280 : 70,
            transition: 'width 200ms'
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'primary.navigationBackground',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
});

export default DashboardSidebar;
