import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import App from './App';
import AuthProvider from './providers/AuthProvider';
import { AppStoreProvider, AppStore } from './store/mobx';
import { getLocale } from './utils/languageUtils';

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <AppStoreProvider store={AppStore.getInstance}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale(AppStore.getInstance.settingsStore.siteSettings.language)}>
            <BrowserRouter>
              <AuthProvider>
                <App />
              </AuthProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </StyledEngineProvider>
      </AppStoreProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);
