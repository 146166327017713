import { useCallback, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { AccessPermission, AnnouncementScopesQueryParameters } from 'src/types/apiSchemas';
import useMounted from 'src/hooks/useMounted';
import { baseApi } from 'src/API/baseApi';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import { AuthorizationService, IAuthorizationService } from 'src/Services';

const AnnouncementDetailsAudinece = observer((props) => {
  const { announcement, confirmButtonDisabled } = props;
  const mounted = useMounted();
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const { t } = useTranslation();
  const [recipientCount, setRecipientCount] = useState<number>(null);

  const [confirmations, setConfirmations] = useState([]);
  const [confirmationCount, setConfirmationCount] = useState<number>();
  const [showMore, setShowMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [confLoading, setConfLoading] = useState<boolean>(false);

  const auth: IAuthorizationService = new AuthorizationService();

  const getAnnouncementRecipientCounts = useCallback(async () => {
    try {
      if (announcement.confirmationRequired) {
        const parameters: AnnouncementScopesQueryParameters = {
          Locations: announcement.announcementScopeDtos.filter((x) => x.scopeType === 'Location').map((x) => x.scopeValue),
          Companies: announcement.announcementScopeDtos.filter((x) => x.scopeType === 'Company').map((x) => x.scopeValue),
          OrganizationUnits: announcement.announcementScopeDtos.filter((x) => x.scopeType === 'OrganizationUnit').map((x) => x.scopeValue),
          EmploymentTypes: announcement.announcementScopeDtos.filter((x) => x.scopeType === 'EmploymentType').map((x) => x.scopeValue),
          BusinessUnits: announcement.announcementScopeDtos.filter((x) => x.scopeType === 'BusinessUnit').map((x) => x.scopeValue),
          OnlyManagers: announcement.announcementScopeDtos.filter((x) => x.scopeType === 'OnlyManagers').map((x) => x.scopeValue),
          OnlyEmployees: announcement.announcementScopeDtos.filter((x) => x.scopeType === 'OnlyEmployees').map((x) => x.scopeValue),
        };
        if (auth.get(user).has(AccessPermission.AnnouncementsWrite).verify()) {
          const resCountPromise = baseApi.fetchAnnouncementScopeRecipients(user.customerId, parameters);
          const confCountDataPromise = baseApi.getAnnouncementConfirmationCount(user.customerId, announcement.announcementId);

          const [resCount, confCountData] = await Promise.all([resCountPromise, confCountDataPromise]);

          if (mounted.current) {
            setRecipientCount(resCount);
            setConfirmationCount(confCountData);
          }
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [announcement, confirmButtonDisabled]);

  const getAudienceConfirmations = async () => {
    setConfLoading(true);
    try {
      const cData = await baseApi.getAnnouncementConfirmations(user.customerId, announcement.announcementId);

      if (mounted.current) {
        setConfirmations(cData);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfLoading(false);
    }
  };

  useEffect(() => {
    if (confirmButtonDisabled === null) { return; }
    getAnnouncementRecipientCounts();
    getAudienceConfirmations();
  }, [confirmButtonDisabled, announcement]);

  const handleShowMoreClick = () => {
    setShowMore(!showMore);
    if (confirmations.length === 0) {
      getAudienceConfirmations();
    }
  };

  return (
    <>
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          sx={{ mt: 10 }}
          bgcolor="background.secondary"
        >
          <CircularProgress
            size={30}
          />
        </Box>
      )}
      {!loading && (
        <Grid
          container
          sx={{ pt: 1 }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
          >
            <Typography
              variant="h6"
              sx={{ color: 'text.secondary' }}
            >
              {`${t('AnnouncementDetailsConfirmationsTitle')}: ${confirmationCount}/${recipientCount}`}
            </Typography>
            <Button
              sx={{ mx: 1 }}
              variant="contained"
              onClick={() => handleShowMoreClick()}
            >
              {showMore ? t('AnnouncementDetailsHideConfirmationsButton') : t('AnnouncementDetailsShowConfirmationsButton')}
            </Button>
          </Grid>
          {confirmations && showMore && (
            <Grid
              container
              direction="row"
              sx={{ mt: 1 }}
            >
              {confLoading && (
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{ mt: 10 }}
                  bgcolor="background.secondary"
                >
                  <CircularProgress
                    size={20}
                  />
                </Box>
              )}
              {!confLoading && (
                <>
                  {confirmations.filter((cnfrm) => !cnfrm.confirmed).map((c) => (
                    <Grid
                      sx={{ pb: 1 }}
                      key={c.userId}
                    >
                      <Grid container>
                        <PendingActionsIcon sx={{ color: 'text.secondary' }} />
                        <Typography sx={{ color: 'text.secondary', pl: 0.5, pr: 2 }}>
                          {c.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                  {confirmations.filter((cnfrm) => cnfrm.confirmed).map((c) => (
                    <Grid
                      sx={{ pb: 1 }}
                      key={c.userId}
                    >
                      <Grid container>
                        <DoneIcon sx={{ color: 'success.main' }} />
                        <Typography sx={{ color: 'text.secondary', pl: 0.5, pr: 2 }}>
                          {c.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}

            </Grid>
          )}
        </Grid>
      )}

    </>

  );
});

export default AnnouncementDetailsAudinece;
