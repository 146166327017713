import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface CommonDialogProps {
  isOpen: boolean;
  dialogTitle: string;
  dialogContent: string;
  confirmButtonTitle?: string;
  cancelButtonTitle: string;
  handleCancel: () => void;
  handleConfirm?: () => void;
  processing?: boolean;
  confirmButtonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  children?: ReactNode;
}

const CommonDialog: FC<CommonDialogProps> = (props) => {
  const {
    isOpen,
    dialogTitle,
    dialogContent,
    handleCancel,
    handleConfirm,
    confirmButtonTitle,
    cancelButtonTitle,
    processing,
    confirmButtonColor: confirmButtonColorSuccess,
    children
  } = props;

  return (
    <div>
      <Dialog
        maxWidth="sm"
        open={isOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Box
          bgcolor="background.default"
          sx={{
            padding: 2
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {dialogTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogContent}
              <>{children}</>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                mr: 1,
              }}
              variant="light"
              onClick={handleCancel}
            >
              {cancelButtonTitle}
            </Button>
            {handleConfirm && confirmButtonTitle && (
              <LoadingButton
                loading={processing}
                color={confirmButtonColorSuccess ?? 'primary'}
                type="submit"
                variant="contained"
                onClick={handleConfirm}
                autoFocus
              >
                {confirmButtonTitle}
              </LoadingButton>
            )}
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

CommonDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  dialogContent: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  confirmButtonTitle: PropTypes.string.isRequired,
  cancelButtonTitle: PropTypes.string.isRequired,
};

export default CommonDialog;
