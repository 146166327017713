import { Card, Modal } from '@mui/material';
import { FC, ReactNode } from 'react';

interface CommonModalProps {
  open: boolean;
  children: ReactNode;
  onClose?: (e: object, reason: string) => void;
  width?: number | string;
}

const CommonModal: FC<CommonModalProps> = ({ open, children, onClose, width = 600 }) => (
  <Modal
    open={open}
    onClose={onClose}
    sx={{ overflow: 'auto', display: 'flex' }}
  >
    <Card sx={{
      backgroundColor: 'background.default',
      width,
      maxWidth: '100%',
      p: 3,
      margin: 'auto'
    }}
    >
      {children}
    </Card>
  </Modal>
);

export default CommonModal;
