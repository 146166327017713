import { useEffect, useRef } from 'react';

const scrollValues = new Map<string, number>();

const useScroll = (key: string) => {
  const element = useRef<HTMLElement>(null);

  const getScroll = () => {
    return element.current?.scrollTop;
  };

  const saveScroll = () => {
    const val = getScroll();

    if (val) {
      scrollValues.set(key, val);
    }
  };

  const restoreScroll = () => {
    const val = scrollValues.get(key);

    if (val && element.current) {
      element.current.scrollTo(0, scrollValues.get(key));
    }
  };

  const resetScroll = () => {
    scrollValues.set(key, 0);
  };

  useEffect(() => {
    const el = document.getElementById('dashboardLayoutContent');
    if (el) {
      element.current = el;
    }
  }, []);

  return { saveScroll, restoreScroll, resetScroll };
};

export default useScroll;