import X from 'src/icons/X';
import { Box, Button, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { AttachmentFileDto } from 'src/types/apiSchemas';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import { Page, Document, pdfjs } from 'react-pdf';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import FileSaver from 'file-saver';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

interface AttachentsPreviewDialogProps {
  onClose: () => void;
  files: AttachmentFileDto[];
  initialIndex: number;
}

const AttachentsPreviewDialog: FC<AttachentsPreviewDialogProps> = (props) => {
  const { files, initialIndex, onClose } = props;
  const { t } = useTranslation();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemIndex, setItemIndex] = useState(initialIndex);
  const [previewLoadError, setPreviewLoadError] = useState(false);

  const item = files[itemIndex];

  useEffect(() => {
    setPreviewLoadError(false);
  }, [itemIndex]);

  const downloadAttachment = (url: string, filename: string) => {
    FileSaver.saveAs(url, filename);
  };

  return (
    <Dialog maxWidth={false} open>
      {files.length > 1 &&
        <>
          <IconButton style={{ position: 'absolute', background: 'black', color: '#fff', zIndex: 100, right: 15, top: '50%' }}
            onClick={() => {
              setItemIndex(prev => {
                const val = prev + 1;
                return val < files.length ? val : 0;
              });
            }}
          ><NavigateNext />
          </IconButton>
          <IconButton style={{ position: 'absolute', background: 'black', color: '#fff', zIndex: 100, left: 15, top: '50%' }}
            onClick={() => {
              setItemIndex(prev => {
                const val = prev - 1;
                return val > -1 ? val : files.length - 1;
              });
            }}
          ><NavigateBefore />
          </IconButton>
        </>}
      <div style={{ maxHeight: '100%', overflow: 'auto', marginBottom: 10 }}>
        <Grid
          container
          direction="column"
        >
          <div style={{ display: 'flex', padding: 10, alignItems: 'center' }}>
            <div>
              <Typography
                sx={{ fontWeight: 'bold' }}
                variant="h6"
              >
                {item?.originalFilename}
              </Typography>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <IconButton
                disabled={!item?.uri}
                onClick={() => downloadAttachment(item?.uri, item?.originalFilename)}
              >
                <DownloadIcon
                  titleAccess={t('DownloadAttachmentLabel')}
                />
              </IconButton>
            </div>
            <div>
              <IconButton
                aria-label="close"
                onClick={onClose}
              >
                <X />
              </IconButton>
            </div>
          </div>
          <Grid item sx={{ display: 'flex', justifyContent: 'center', minHeight: 100, minWidth: 500 }}>
            {item && item.uri && item.uri.includes('.pdf') && (
              <>
                <Box>
                  <Document
                    file={item.uri}
                    renderMode="canvas"
                    onLoadSuccess={(file) => {
                      setPageNumber(1);
                      setNumPages(file.numPages);
                    }}
                  >
                    <Grid
                      container
                      sx={{ px: 2 }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography
                          sx={{ fontWeight: 'bold' }}
                          variant="h6"
                        >
                          {`${t('ApprovalCarouselPageNumTitle')} ${pageNumber}/${numPages}`}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item>
                          <Button
                            sx={{ fontSize: 20 }}
                            onClick={() => {
                              if (pageNumber > 1) {
                                setPageNumber(prev => prev - 1);
                              }
                            }}
                          >
                            {'<'}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            sx={{ fontSize: 20 }}
                            onClick={() => {
                              if (pageNumber < numPages) {
                                setPageNumber(prev => prev + 1);
                              }
                            }}
                          >
                            {'>'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Page
                      pageNumber={pageNumber}
                      renderAnnotationLayer={false}
                      width={700}
                    />
                  </Document>
                </Box>
              </>
            )}
            {item && (item.uri || item.thumbnailUrl) && !item.uri.includes('.pdf') && (
              <Box
                component="img"
                sx={{ maxWidth: '700px' }}
                src={item.thumbnailUrl || item.uri}
                onError={() => {
                  setPreviewLoadError(true);
                }}
                alt=""
              />
            )}
            {previewLoadError &&
              <div style={{ margin: '20px 100px', maxWidth: 300 }}>
                <Typography>
                  {t('ApprovalCarouselFileNotFound')}
                </Typography>
              </div>
            }
            {item && !item.thumbnailUrl && !item.uri &&
              <div style={{ margin: '20px 100px', maxWidth: 300 }}>
                <Typography>
                  {t('AttachmentsFileNotAvailable')}
                </Typography>
              </div>}
          </Grid>
        </Grid>
      </div>
    </Dialog>);
};

export default AttachentsPreviewDialog;