import { useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import { SiteUser } from 'src/types/siteUser';
import { Message, MessageThreadsResponse, MessageThreadVisibility } from 'src/types/apiSchemas';
import { baseApi } from 'src/API/baseApi';
export const getBackendUrl = () => process.env.REACT_APP_HUMHUM_BACKEND_URL;

const useJoinThreadSignalRConnection = (
  thread: string,
  user: SiteUser,
  setConnectionState,
  addNewMessages: (newMessages: Message[]) => void,
  updateThread: (thread: MessageThreadsResponse) => void,
  updateCC: (cc: string[]) => void,
  updateVisibility: (visibility: MessageThreadVisibility) => void,
  messageDeleted: (messageId: number) => void
) => {
  useEffect(() => {
    const url = getBackendUrl();
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${url}/messageshub`, {
        accessTokenFactory: () => user.token
      })
      .configureLogging(signalR.LogLevel.Error)
      .build();

    connection.on('NewMessage', async (threadId, timestamp) => {
      const messages = await baseApi.getMessageThreadMessages(threadId, timestamp);
      addNewMessages(messages);
    });

    connection.on('ThreadChanged', async (threadId) => {
      const messageThread = await baseApi.getMessageThread(threadId);
      updateThread(messageThread);
    });

    connection.on('ThreadCCChanged', async (threadId) => {
      const cc = await baseApi.getMessageThreadCC(threadId);
      updateCC(cc);
    });

    connection.on('ThreadVisibilityChanged', async (threadId) => {
      const visibility = await baseApi.getMessageThreadVisibility(threadId);
      updateVisibility(visibility);
    });

    connection.on('MessageDeleted', async (threadId, messageId) => {
      messageDeleted(messageId);
    });

    connection.onclose(() => {
      setConnectionState(false);
    });

    const connectToSignalR = async () => {
      try {
        await connection.start();
        await connection.invoke('JoinThread', thread);
        setConnectionState(true);
      } catch (err) {
        console.error(`JoinThread failed: ${err.toString()}`);
        setConnectionState(false);
      }
    };

    connectToSignalR();

    return () => {
      if (connection.state === signalR.HubConnectionState.Connected) {
        connection.invoke('LeaveThread', thread)
          .finally(() => {
            connection.stop().catch(err => console.error('SignalR Disconnect Error:', err));
            setConnectionState(false);
          }).catch(err => console.error('LeaveThread failed:', err));
      } else {
        connection.stop().catch(err => console.error('SignalR Disconnect Error:', err));
        setConnectionState(false);
      }
    };
  }, [thread, user]);
};

export default useJoinThreadSignalRConnection;
