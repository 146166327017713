/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { paymentApi } from 'src/API/paymentAPI';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  Typography,
  Grid,
  IconButton,
  Box,
  Button,
  Divider,
  TableRow,
  TableCell
} from '@mui/material';
import X from 'src/icons/X';
import { LoadingButton } from '@mui/lab';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import { PaymentDetailDto, PaymentDto, PaymentApprovalStatus, DashboardPaymentTransactionsQueryParameters, TransactionDtoPagedResultDto, TransactionDto } from 'src/types/apiSchemas';
import { useMounted } from 'src/hooks';
import PaymentCommentSummaryTable from './PaymentCommentSummaryTable';

interface PaymentApprovalDialogProps {
  openDialog: any;
  closeDialogWithSuccess: any;
  reviews: any;
  approvals: any;
  flaggedTransactions?: TransactionDtoPagedResultDto | null;
  loadingFlaggedTransactions?: boolean;
}

const PaymentCommentSummary: FC<PaymentApprovalDialogProps> = observer((props) => {
  const { openDialog, closeDialogWithSuccess, reviews, approvals, flaggedTransactions } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialogWithSuccess}
      maxWidth="md"
      fullWidth
    >
      <Box bgcolor="background.default">
        <Grid
          container
          direction="column"
          sx={{
            p: 3
          }}
        >
          <Grid
            item
            container
            xs={12}
            direction="row"
            alignItems="center"
          >
            <Grid
              item
              container
              xs={10}
            >
              <Typography variant="h5">
                {t('AllCommentsTitle')}
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={2}
              justifyContent="flex-end"
            >
              <IconButton
                aria-label="close"
                onClick={() => { closeDialogWithSuccess(); }}
                size="large"
              >
                <X />
              </IconButton>
            </Grid>

          </Grid>
          <Divider />

          <PaymentCommentSummaryTable
            approvals={approvals}
            reviews={reviews}
            flaggedTransactions={flaggedTransactions}
            showNoCommentsLabel
          />

        </Grid>
      </Box>
    </Dialog>
  );
});

export default PaymentCommentSummary;
