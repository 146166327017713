import createSvgIcon from '@mui/material/utils/createSvgIcon';

const Announcements = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 18"
    fill="currentColor"
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M7.81813 10H0.181763V8H7.81813V10ZM11.0909 6H0.181763V4H11.0909V6ZM11.0909 2H0.181763V0H11.0909V2Z"
      transform="translate(5,4)"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M18.2727 2V16H2.99993V2H18.2727ZM18.2727 0H2.99993C1.79993 0 0.818115 0.9 0.818115 2V16C0.818115 17.1 1.79993 18 2.99993 18H18.2727C19.4727 18 20.4545 17.1 20.4545 16V2C20.4545 0.9 19.4727 0 18.2727 0Z"
    />
  </svg>,
  'Announcements'
);

export default Announcements;
