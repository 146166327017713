import type { FC } from 'react';
import PropTypes from 'prop-types';
import { paymentApi } from 'src/API/paymentAPI';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  TextField,
  Typography,
  Grid,
  IconButton,
  Box
} from '@mui/material';
import X from 'src/icons/X';
import { LoadingButton } from '@mui/lab';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import PaymentCommentSummaryTable from './PaymentCommentSummaryTable';
import { formatNumber } from 'src/utils/numberUtils';

export enum ApprovalAction {
  Decline = 'Declined',
  Approve = 'Approved'
}
interface PaymentApprovalDialogProps {
  paymentId?: number;
  customerName?: string;
  openDialog: any;
  closeDialogWithSuccess: any;
  closeDialogWithoutSuccess: any;
  isFlagged: boolean;
  approvalAction: ApprovalAction;
  paymentSum?: number;
  approvals?: any;
  reviews?: any;
  flaggedTransactions?: any;
}

const PaymentApprovalDialog: FC<PaymentApprovalDialogProps> = observer((props) => {
  const { paymentId, customerName, openDialog, closeDialogWithSuccess, closeDialogWithoutSuccess, isFlagged, approvalAction, paymentSum, approvals, reviews, flaggedTransactions } = props;
  const { t } = useTranslation();
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    closeDialogWithoutSuccess();
  };

  const renderConfirmationMessage = (): JSX.Element => {
    if (approvalAction === ApprovalAction.Approve) {
      return (
        <Typography
          color="textPrimary"
          variant="h4"
        >
          {t('PaymentReviewConfirmation')}
        </Typography>
      );
    }

    return isFlagged ? (
      <>
        <Typography
          color="textPrimary"
          variant="h5"
          gutterBottom
        >
          {t('PaymentDeclineConfirmation')}
        </Typography>
        <Typography
          color="textPrimary"
          variant="h6"
          gutterBottom
        >
          {t('PaymentDeclineConfirmationWithFlags')}
        </Typography>

      </>
    ) : (
      <Typography
        color="textPrimary"
        variant="h4"
      >
        {t('PaymentDeclineConfirmation')}
      </Typography>
    );
  };

  const successMessage: string = approvalAction === ApprovalAction.Decline ? t('PaymentDeclineSuccessful') : t('PaymentApprovalSuccessful');

  const errorMessage: string = approvalAction === ApprovalAction.Decline ? t('PaymentDeclineFailed') : t('PaymentApprovalFailed');

  const reasonForApprovingLabel: string = t('PaymentReasonForReviewingPayment');

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <Formik
        initialValues={{
          reasonForApproving: '',
          submit: null
        }}
        validationSchema={
          Yup
            .object()
            .shape({
              reasonForApproving: Yup
                .string()
            })
        }
        onSubmit={async (values, {
          resetForm,
          setStatus,
          setSubmitting
        }): Promise<void> => {
          try {
            await paymentApi.approvePayment(paymentId, approvalAction.toString(), values.reasonForApproving, user?.userId);
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            toast.success(successMessage);
            appStore.notificationStore.refreshPaymentNotifications(user);
            closeDialogWithSuccess();
          } catch (err) {
            console.log(err);
            toast.error(errorMessage);
            setStatus({ success: false });
            setSubmitting(false);
            closeDialogWithoutSuccess();
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }): JSX.Element => (
          <form
            onSubmit={handleSubmit}
          >
            <Box bgcolor="background.default">
              <Grid
                container
                sx={{
                  p: 3
                }}
              >
                <Grid
                  container
                >
                  <Grid
                    item
                    xs={11}
                  >
                    {renderConfirmationMessage()}
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    container
                    justifyContent="flex-end"
                  >
                    <IconButton
                      aria-label="close"
                      onClick={() => { closeDialogWithoutSuccess(); }}
                      size="large"
                    >
                      <X />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  container
                  rowSpacing={1}
                  direction="row"
                >
                  <Grid
                    item
                    container
                    xs={2}
                    direction="column"
                  >
                    <Grid
                      item
                    >
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {`${t('Company')}`}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      fontWeight={700}
                    >
                      {`${customerName}`}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={2}
                    direction="column"
                  >
                    <Grid
                      item
                    >
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {`${t('PaymentAmount')}`}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      fontWeight={700}
                    >
                      {formatNumber(paymentSum)}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: 2
                  }}
                >
                  <TextField
                    multiline
                    minRows={1}
                    maxRows={10}
                    inputProps={{ maxLength: 4000 }}
                    label={reasonForApprovingLabel}
                    error={Boolean(touched.reasonForApproving && errors.reasonForApproving)}
                    helperText={touched.reasonForApproving && errors.reasonForApproving ? errors.reasonForApproving : ' '}
                    value={values.reasonForApproving}
                    fullWidth
                    name="reasonForApproving"
                    required={approvalAction !== ApprovalAction.Approve}
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <PaymentCommentSummaryTable
                  approvals={approvals}
                  reviews={reviews}
                  flaggedTransactions={flaggedTransactions}
                  showNoCommentsLabel={false}
                />
                <Grid
                  item
                  sx={{ mt: 1 }}
                  container
                  justifyContent="flex-end"
                >
                  <Button
                    sx={{
                      mr: 1,
                    }}
                    onClick={closeDialogWithoutSuccess}
                    variant="light"
                  >
                    {t('ButtonCancel')}
                  </Button>
                  <LoadingButton
                    color={approvalAction === ApprovalAction.Decline ? 'primary' : 'success'}
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                  >
                    {approvalAction === ApprovalAction.Decline ? t('DeclineTitle') : t('ApproveTitle')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>

          </form>
        )}
      </Formik>
    </Dialog>
  );
});

PaymentApprovalDialog.propTypes = {
  // @ts-ignore
  paymentId: PropTypes.number.isRequired,
  customerName: PropTypes.string.isRequired,
  openDialog: PropTypes.any.isRequired,
  closeDialogWithSuccess: PropTypes.any.isRequired,
  closeDialogWithoutSuccess: PropTypes.any.isRequired,
  approvalAction: PropTypes.any.isRequired,
  isFlagged: PropTypes.bool.isRequired
};

export default PaymentApprovalDialog;
