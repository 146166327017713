import type { FC } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import { createICS } from 'src/utils/ics';

interface AnnouncementCardCalendarEventProps {
  start: string;
  end?: string;
  title?: string;
  description?: string;
}

const AnnouncementCardCalendarEvent: FC<AnnouncementCardCalendarEventProps> = (props) => {
  const { start, end, title, description } = props;
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ marginTop: 2 }}
    >
      <Button
        variant="contained"
        startIcon={<CalendarMonthIcon />}
        onClick={() => {
          const ics = createICS({
            start,
            end,
            title: title || '',
            description: description || ''
          });

          FileSaver.saveAs(ics, 'event.ics');
        }}
      >
        <Typography
          color="textPrimary"
          fontWeight={500}
          sx={{ mx: 1 }}
        >
          {t('AddEventToCalendar')}
        </Typography>
      </Button>
    </Grid>
  );
};

export default AnnouncementCardCalendarEvent;
