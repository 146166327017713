import type { FC } from 'react';
import { Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AnnouncementNotificationStatus, AnnouncementType } from 'src/types/apiSchemas';
import { CreateAnnouncementBaseProps } from './props/CreateAnnouncementBaseProps';

const CreateAnnouncementTypeSettings: FC<CreateAnnouncementBaseProps> = (props) => {
  const { onChangeHandler, touchedHandler, errorHandler, formValues } = props;
  const { t } = useTranslation();

  const translateKey = (key: string): string => {
    switch (key) {
      case AnnouncementType[AnnouncementType.Announcement]:
        return t('CreateAnnouncementsTypeSettingsAnnouncementTypeAnnouncement');
      case AnnouncementType[AnnouncementType.Event]:
        return t('CreateAnnouncementsTypeSettingsAnnouncementTypeEvent');
      case AnnouncementType[AnnouncementType.Reminder]:
        return t('CreateAnnouncementsTypeSettingsAnnouncementTypeReminder');
      default:
        return key;
    }
  };

  // if notification has already bee processed for the announcement
  // will not let toggling of the checkbox after that
  const isNotificationProcessed = formValues.announcementNotification?.status === AnnouncementNotificationStatus.Processed;
  let pushNotificationLabel = t('CreateAnnouncementsTypeSettingsPushNotification');
  pushNotificationLabel += isNotificationProcessed ? ` ${t('CreateAnnouncementsTypeSettingsPushNotificationAlreadyProcessed')}` : '';

  return (
    <Grid
      xs
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-end"
      rowSpacing={1}
    >
      <Grid
        item
        xs={12}
        sm={4}
      >
        <Typography
          color="textPrimary"
          variant="formFieldHeader"
          sx={{ ml: 1, mb: 1 }}
        >
          {t('CreateAnnouncementsTypeSettingsAnnouncementsType')}
        </Typography>
        <TextField
          select
          fullWidth
          required
          name="announcementType"
          onChange={onChangeHandler}
          value={formValues.announcementType}
          error={Boolean(touchedHandler.announcementType && errorHandler.announcementType)}
          variant="outlined"
        >
          {Object.keys(AnnouncementType)
            .filter((x) => Number.isNaN(Number(x)))
            .map((at) => (
              <MenuItem
                key={at}
                value={at}
              >
                {translateKey(at)}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        justifyContent={{ xs: 'flex-start', sm: 'space-evenly' }}
        sx={{ mb: 1 }}
      >
        <Grid
          item
          sx={{ ml: { xs: 1, sm: 0 } }}
        >
          <FormControlLabel
            control={(
              <Checkbox
                checked={formValues.pushNotification}
                onChange={onChangeHandler}
                name="pushNotification"
                color="primary"
                disabled={isNotificationProcessed}
              />
            )}
            label={pushNotificationLabel}
          />
        </Grid>
        <Grid
          item
          sx={{ ml: { xs: 1, sm: 0 } }}
        >
          <FormControlLabel
            control={(
              <Checkbox
                checked={formValues.pinAnnouncement}
                onChange={onChangeHandler}
                name="pinAnnouncement"
                color="primary"
              />
            )}
            label={t('CreateAnnouncementsTypeSettingsPinAnnouncement')}
          />
        </Grid>
      </Grid>

    </Grid>
  );
};

CreateAnnouncementTypeSettings.propTypes = {
  // @ts-ignore
  onChangeHandler: PropTypes.any.isRequired,
  touchedHandler: PropTypes.any.isRequired,
  errorHandler: PropTypes.any.isRequired,
  formValues: PropTypes.any.isRequired,
};

export default CreateAnnouncementTypeSettings;
