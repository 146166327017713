import { FC, ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from '../pages/authentication/Login';
import { ExpiredTokenError, isTokenAuthorizedPartyValid, isTokenValid, validateAccessToken } from 'src/utils/jwt';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import { AuthenticationService, IAuthenticationService } from 'src/Services';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = observer((props) => {
  const { children } = props;
  const appStore = useAppStore();
  const location = useLocation();
  const { t } = useTranslation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const { user } = appStore.loginStore.get();
  const authenticationService: IAuthenticationService = new AuthenticationService(appStore);

  const authToken = user?.token;

  // effect hook to trigger, if something happens to the validity check values of the token
  useEffect(() => {
    const validateRequest = async (): Promise<void> => {
      try {
        // Check that the JWT token is still valid
        validateAccessToken(authToken);
      } catch (err) {
        if (err instanceof ExpiredTokenError && user) {
          if (!await authenticationService.tryRefreshToken(user)) {
            appStore.loginStore.logout();
            toast.error(t('SessionExpiredToast'), {
              id: 'sessionExpired',
            });
          }
        } else {
          appStore.loginStore.logout();
          if (authToken) {
            toast.error(t('SessionExpiredToast'), {
              id: 'sessionExpired',
            });
          }
        }
      }
    };
    validateRequest();
  }, [isTokenValid(authToken), isTokenAuthorizedPartyValid(authToken)]);

  if (!appStore.loginStore.get().isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
});

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
