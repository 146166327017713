import createSvgIcon from '@mui/material/utils/createSvgIcon';

const IntegrataDevLogo = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 200"
    fill="white"
  >
    <text
      y="58"
      x="-5"
      fontSize={70}
      fontWeight="bold"
      fill="white"
    >
      Demo
    </text>
  </svg>,
  'Integrata'
);

export default IntegrataDevLogo;
