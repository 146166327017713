import { FC, useEffect, useState } from 'react';
import { Alert, AlertTitle, Box, Checkbox, Chip, CircularProgress, FormControl, Grid, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CreateAnnouncementBaseProps } from './props/CreateAnnouncementBaseProps';
import { useTranslation } from 'react-i18next';
import CreateAnnouncementSelectableLabel from './CreateAnnouncementSelectableLabel';
import { FieldArray } from 'formik';
import { AnnouncementScopeDto, AnnouncementScopesQueryParameters, AnnouncementScopeType } from 'src/types/apiSchemas';
import { baseApi } from 'src/API/baseApi';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';

interface CreateAnnouncementsTargetGroupsProps extends CreateAnnouncementBaseProps {
  scopes: AnnouncementScopeDto[];
}

const CreateAnnouncementsTargetGroups: FC<CreateAnnouncementsTargetGroupsProps> = observer((props) => {
  const { onChangeHandler, formValues, scopes } = props;
  const { t } = useTranslation();
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const [recipients, setRecipients] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [onlyManagersChecked, setOnlyManagersChecked] = useState(formValues.selectedScopes.filter((e) => e.includes('OnlyManagers')).length !== 0);
  const [onlyEmployeesChecked, setOnlyEmployeesChecked] = useState(formValues.selectedScopes.filter((e) => e.includes('OnlyEmployees')).length !== 0);
  const [cannotChooseBothAlert, setCannotChooseBothAlert] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const parsedScopes: Array<AnnouncementScopeDto> = formValues.selectedScopes.map((x) => JSON.parse(x));
        const parameters: AnnouncementScopesQueryParameters = {
          Locations: parsedScopes.filter((x) => x.scopeType === AnnouncementScopeType.Location).map((x) => x.scopeValue),
          Companies: parsedScopes.filter((x) => x.scopeType === AnnouncementScopeType.Company).map((x) => x.scopeValue),
          OrganizationUnits: parsedScopes.filter((x) => x.scopeType === AnnouncementScopeType.OrganizationUnit).map((x) => x.scopeValue),
          EmploymentTypes: parsedScopes.filter((x) => x.scopeType === AnnouncementScopeType.EmploymentType).map((x) => x.scopeValue),
          OnlyManagers: parsedScopes.filter((x) => x.scopeType === AnnouncementScopeType.OnlyManagers).map((x) => x.scopeValue),
          OnlyEmployees: parsedScopes.filter((x) => x.scopeType === AnnouncementScopeType.OnlyEmployees).map((x) => x.scopeValue),
          BusinessUnits: parsedScopes.filter((x) => x.scopeType === AnnouncementScopeType.BusinessUnit).map((x) => x.scopeValue),
        };
        const recipientCount = await baseApi.fetchAnnouncementScopeRecipients(user?.customerId, parameters);
        setRecipients(recipientCount);
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
      }
      setIsLoading(false);
    };

    setIsLoading(false);
    setIsError(false);

    fetchData();
  }, [formValues.selectedScopes]);

  // Multi-select view props
  const itemHeight = 48;
  const itemPaddingTop = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: itemHeight * 4.5 + itemPaddingTop,
        width: 250,
      },
    },
  };

  const loadingSpinner = (
    <Box
      display="flex"
      justifyContent="center"
    >
      <CircularProgress sx={{ color: 'white', p: 1 }} />
    </Box>
  );

  const onlyEmployeesClick = (event) => {
    if (!onlyEmployeesChecked) {
      if (onlyManagersChecked) {
        // errormsg
        setCannotChooseBothAlert(true);
        return;
      }
      setCannotChooseBothAlert(false);
      setOnlyEmployeesChecked(true);
      const newScope: AnnouncementScopeDto = { announcementScopeId: 5, scopeType: AnnouncementScopeType.OnlyEmployees, scopeValue: event.target.value };
      formValues.selectedScopes = formValues.selectedScopes.concat(JSON.stringify(newScope));
    } else {
      setOnlyEmployeesChecked(false);
      formValues.selectedScopes = formValues.selectedScopes.filter((x: string) => JSON.parse(x).scopeType !== AnnouncementScopeType.OnlyEmployees);
    }
  };

  const onlyManagersClick = (event) => {
    if (!onlyManagersChecked) {
      if (onlyEmployeesChecked) {
        // errormsg
        setCannotChooseBothAlert(true);
        return;
      }
      setCannotChooseBothAlert(false);
      setOnlyManagersChecked(true);
      const newScope: AnnouncementScopeDto = { announcementScopeId: 4, scopeType: AnnouncementScopeType.OnlyManagers, scopeValue: event.target.value };
      formValues.selectedScopes = formValues.selectedScopes.concat(JSON.stringify(newScope));
    } else {
      setOnlyManagersChecked(false);
      formValues.selectedScopes = formValues.selectedScopes.filter((x: string) => JSON.parse(x).scopeType !== AnnouncementScopeType.OnlyManagers);
    }
  };

  if (isError) {
    return (
      <Alert severity="error">
        <AlertTitle>Virhe</AlertTitle>
        Virhe kohderyhmiä hakiessa
      </Alert>
    );
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      rowSpacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          color="textPrimary"
          variant="formFieldHeader"
          sx={{ ml: 1, mb: 1 }}
        >
          {t('CreateAnnouncementsRightPanelGroupLocation')}
        </Typography>
        <FormControl sx={{ width: '100%' }}>
          <Select
            name="selectedScopes"
            multiple
            value={formValues.selectedScopes}
            onChange={onChangeHandler}
            renderValue={(selected) => selected
              .filter((x: string) => JSON.parse(x).scopeType === AnnouncementScopeType.Location)
              .map((x: string) => JSON.parse(x).scopeValue)
              .join(', ')}
            MenuProps={MenuProps}
          >
            {scopes.filter((scope) => scope.scopeType === AnnouncementScopeType.Location)
              .map((scope) => (
                <MenuItem
                  value={JSON.stringify(scope)}
                  key={scope.scopeValue}
                >
                  <Checkbox checked={formValues.selectedScopes.indexOf(JSON.stringify(scope)) > -1} />
                  <ListItemText primary={scope.scopeValue} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography
          color="textPrimary"
          variant="formFieldHeader"
          sx={{ ml: 1, mb: 1 }}
        >
          {t('CreateAnnouncementsRightPanelGroupOrganization')}
        </Typography>
        <FormControl sx={{ width: '100%' }}>
          <Select
            name="selectedScopes"
            multiple
            value={formValues.selectedScopes}
            onChange={onChangeHandler}
            renderValue={(selected) => selected
              .filter((x: string) => JSON.parse(x).scopeType === AnnouncementScopeType.OrganizationUnit)
              .map((x: string) => JSON.parse(x).scopeValue)
              .join(', ')}
            MenuProps={MenuProps}
          >
            {scopes.filter((scope) => scope.scopeType === AnnouncementScopeType.OrganizationUnit)
              .map((scope) => (
                <MenuItem
                  value={JSON.stringify(scope)}
                  key={scope.scopeValue}
                >
                  <Checkbox checked={formValues.selectedScopes.indexOf(JSON.stringify(scope)) > -1} />
                  <ListItemText primary={scope.scopeValue} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography
          color="textPrimary"
          variant="formFieldHeader"
          sx={{ ml: 1, mb: 1 }}
        >
          {t('CreateAnnouncementsRightPanelGroupBusinessUnit')}
        </Typography>
        <FormControl sx={{ width: '100%' }}>
          <Select
            name="selectedScopes"
            multiple
            value={formValues.selectedScopes}
            onChange={onChangeHandler}
            renderValue={(selected) => selected
              .filter((x: string) => JSON.parse(x).scopeType === AnnouncementScopeType.BusinessUnit)
              .map((x: string) => JSON.parse(x).scopeValue)
              .join(', ')}
            MenuProps={MenuProps}
          >
            {scopes.filter((scope) => scope.scopeType === AnnouncementScopeType.BusinessUnit)
              .map((scope) => (
                <MenuItem
                  value={JSON.stringify(scope)}
                  key={scope.scopeValue}
                >
                  <Checkbox checked={formValues.selectedScopes.indexOf(JSON.stringify(scope)) > -1} />
                  <ListItemText primary={scope.scopeValue} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography
          color="textPrimary"
          variant="formFieldHeader"
          sx={{ ml: 1, mb: 1 }}
        >
          {t('CreateAnnouncementsRightPanelGroupEmploymentType')}
        </Typography>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
        >
          <FieldArray
            name="selectedScopes"
            render={(arrayHelpers) => (
              scopes.filter((scope) => scope.scopeType === AnnouncementScopeType.EmploymentType)
                .map((scope) => (
                  <Grid
                    item
                    key={scope.scopeValue}
                  >
                    <CreateAnnouncementSelectableLabel
                      arrayHelpers={arrayHelpers}
                      formValues={formValues}
                      value={JSON.stringify(scope)}
                    >
                      {scope.scopeValue}
                    </CreateAnnouncementSelectableLabel>
                  </Grid>
                ))
            )}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="flex-start"
        xs={12}
        columnSpacing={1}
      >
        <Grid
          alignItems="center"
          item
          container
          xs={6}
        >
          <Checkbox
            checked={onlyManagersChecked}
            value="OnlyManagers"
            onChange={onlyManagersClick}
          />
          <Typography>
            {t('CreateAnnouncementsRightPanelOnlyManagers')}
          </Typography>
        </Grid>
        <Grid
          alignItems="center"
          item
          container
          xs={6}
        >
          <Checkbox
            checked={onlyEmployeesChecked}
            value="OnlyEmployees"
            onChange={onlyEmployeesClick}
          />
          <Typography>
            {t('CreateAnnouncementsRightPanelOnlyEmployees')}
          </Typography>
        </Grid>
        {cannotChooseBothAlert && (
          <Grid
            item
            xs={12}
          >
            <Typography
              color="error.main"
            >
              {t('CreateAnnouncementsRightPanelCannotChooseBothError')}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="flex-start"
        xs={12}
        columnSpacing={1}
      >
        <Grid
          item
        >
          <Typography
            variant="formFieldHeader"
            color="textPrimary"
          >
            {t('CreateAnnouncementsRightPanelGroupRecipientsNumber')}
          </Typography>
        </Grid>
        <Grid
          item
        >
          {isLoading ? loadingSpinner : (
            <Chip
              label={recipients}
              color="primary"
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});

CreateAnnouncementsTargetGroups.propTypes = {
  // @ts-ignore
  onChangeHandler: PropTypes.any.isRequired,
  formValues: PropTypes.any.isRequired,
};

export default CreateAnnouncementsTargetGroups;
