import type { FC } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

interface AnnouncementCardImageProps {
  imageUrl: string;
  caption: string;
}

const AnnouncementCardImage : FC<AnnouncementCardImageProps> = (props) => {
  const { imageUrl, caption } = props;

  return (
    <Box
      component="img"
      sx={{ maxWidth: '100%', maxHeight: { xs: '200px', md: '100%' } }}
      alt={caption}
      src={imageUrl}
    />
  );
};

AnnouncementCardImage.propTypes = {
  // @ts-ignore
  imageUrl: PropTypes.string.isRequired,
  caption: PropTypes.string
};

export default AnnouncementCardImage;
