import type { FC } from 'react';
import { THEMES } from '../../constants';
import { uiTheme } from '../../utils/environmentUtils';

const ThemeBasedLogo: FC = () => {
  if (uiTheme === THEMES.ACCOUNTOR) {
    return (
      <img
        alt="HumHum Logo"
        src="/static/mepco_analytiikka_a_white_logo_NEG.png"
        style={{ width: '250px', maxWidth: '100%' }}
      />
    );
  }

  return (
    <img
      alt="HumHum Logo"
      src="/static/humhum_logo_static_white.png"
      style={{ width: 180, maxWidth: '100%' }}
    />
  );
};

export default ThemeBasedLogo;
