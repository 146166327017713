import { experimentalStyled } from '@mui/material/styles';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { AuthorizationService, IAuthorizationService } from 'src/Services';
import ExternalLink from 'src/icons/ExternalLink';
import { useAppStore } from 'src/store/mobx';
import { LinkType } from 'src/types/apiSchemas';

const ToolbarLink = experimentalStyled('a')(({ theme }) => ({
  color: theme.palette.primary.integrataFeatherGrey,
  textDecoration: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  margin: '0 15px',
  '> svg': {
    width: 12,
    height: 12,
    marginLeft: 4
  }
}));

const CustomerLinks = observer(() => {
  const { t } = useTranslation();
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const mepcoLink = user?.companies?.[0].customerLinks?.find((c) => c.linkType === LinkType.Mepco);
  const customerSupportLink = user?.companies?.[0].customerLinks?.find((c) => c.linkType === LinkType.CustomerService);
  const auth: IAuthorizationService = new AuthorizationService();
  const showLinks = auth.get(user).isPaymentApproverOrReviewer || auth.get(user).isHR;
  return (
    <>
      {/* not showing mepco link to an employee */}
      {mepcoLink?.url && (
        <ToolbarLink
          href={mepcoLink.url}
          target="_blank"
          rel="noreferrer"
        >
          {t('externalLinkMepco')}
          <ExternalLink />
        </ToolbarLink>
      )}
      {showLinks && (
        <ToolbarLink
          href="https://lataamo.integrata.fi"
          target="_blank"
          rel="noreferrer"
        >
          {t('externalLinkLataamo')}
          <ExternalLink />
        </ToolbarLink>
      )}
      {customerSupportLink?.url && showLinks && (
        <ToolbarLink
          href={customerSupportLink.url}
          target="_blank"
          rel="noreferrer"
        >
          {t('externalLinkCustomerService')}
          <ExternalLink />
        </ToolbarLink>
      )}
    </>
  );
});

export default CustomerLinks;
