import { Box, Container } from '@mui/material';
import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import CreateAbsenceForm from 'src/components/dashboard/absences/CreateAbsenceForm';

const CreateAbsence: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('SickLeavePageHelmet')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth="sm">
          <CreateAbsenceForm />
        </Container>
      </Box>
    </>
  );
};

export default CreateAbsence;
