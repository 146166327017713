import { FC } from 'react';
import { Grid, TextField, Typography, Grow } from '@mui/material';
import PropTypes from 'prop-types';
import { CreateAnnouncementBasePropsWithBlur } from './props/CreateAnnouncementBaseProps';
import { useTranslation } from 'react-i18next';
import { LanguageCode } from 'src/types/apiSchemas';

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

interface CreateAnnouncementContentProps extends CreateAnnouncementBasePropsWithBlur {
  language: LanguageCode;
}

const CreateAnnouncementContent: FC<CreateAnnouncementContentProps> = (props) => {
  const { onChangeHandler, touchedHandler, errorHandler, formValues, onBlurHandler, language } = props;
  const { t } = useTranslation();

  const lc = capitalizeFirstLetter(language);

  // Subject conf
  const subjectError = Boolean(touchedHandler[`subject${lc}`] && errorHandler[`subject${lc}`]);
  const subjectHelperText = touchedHandler[`subject${lc}`] && errorHandler[`subject${lc}`];
  const subjectValue = formValues[`subject${lc}`];
  const subjectName = `subject${lc}`;

  // Content conf
  const contentError = Boolean(touchedHandler[`content${lc}`] && errorHandler[`content${lc}`]);
  const contentHelperText = touchedHandler[`content${lc}`] && errorHandler[`content${lc}`];
  const contentValue = formValues[`content${lc}`];
  const contentName = `content${lc}`;

  return (
    <Grow
      in
      timeout={300}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        rowSpacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            color="textPrimary"
            variant="formFieldHeader"
            sx={{ ml: 1, mb: 1 }}
          >
            {t('CreateAnnouncementContentSubject')}
          </Typography>
          <TextField
            error={subjectError}
            helperText={subjectHelperText}
            value={subjectValue}
            fullWidth
            name={subjectName}
            required
            variant="outlined"
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography
            color="textPrimary"
            variant="formFieldHeader"
            sx={{ ml: 1, mb: 1 }}
          >
            {t('CreateAnnouncementContentContent')}
          </Typography>
          <TextField
            error={contentError}
            helperText={contentHelperText}
            value={contentValue}
            fullWidth
            name={contentName}
            required
            variant="outlined"
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            multiline
            rows={8}
          />
        </Grid>
      </Grid>
    </Grow>
  );
};

CreateAnnouncementContent.propTypes = {
  // @ts-ignore
  onChangeHandler: PropTypes.any.isRequired,
  onBlurHandler: PropTypes.any.isRequired,
  touchedHandler: PropTypes.any.isRequired,
  errorHandler: PropTypes.any.isRequired,
  formValues: PropTypes.any.isRequired,
  language: PropTypes.any.isRequired
};

export default CreateAnnouncementContent;
