import { Box, Breadcrumbs, Button, Card, CircularProgress, Container, Grid, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ChevronRightIcon from '../../icons/ChevronRight';
import { UserAccessRequestApprovalDto, UserAccessRequestDto, UserAccessRequestStatusEnum } from 'src/types/apiSchemas';
import { useMounted } from 'src/hooks';
import { baseApi } from 'src/API/baseApi';
import { useAppStore } from 'src/store/mobx/appStore';
import CommonDialog from 'src/components/common/CommonDialog';
import toast from 'react-hot-toast';
import { GetInternalRoleTranslation, GetUserRoleTranslation } from 'src/utils/roleUtils';

const AccessRequestList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mounted = useMounted();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [accessRequests, setAccessRequests] = useState<UserAccessRequestDto[]>();
  const [openApproveDialog, setOpenApproveDialog] = useState<boolean>(false);
  const [newStatus, setNewStatus] = useState<UserAccessRequestStatusEnum>(null);
  const [toBeUpdatedRequestId, setToBeUpdatedRequestId] = useState<number>(null);
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();

  const getAccessRequestData = useCallback(async () => {
    if (mounted.current) {
      setIsLoading(true);
    }
    try {
      const accessRequestData = await baseApi.getPendingUserAccessRequests(user.customerId);
      if (mounted.current) {
        setAccessRequests(accessRequestData);
      }
    } catch (e) {
      console.log(e);
    } finally {
      if (mounted.current) {
        setIsLoading(false);
      }
    }
  }, [mounted]);

  useEffect(() => {
    getAccessRequestData();
  }, [mounted]);

  const updateAccessRequestStatus = async () => {
    try {
      setIsSaving(true);
      const approvalDto: UserAccessRequestApprovalDto = { userAccessRequestsId: toBeUpdatedRequestId, requestStatus: newStatus };
      await baseApi.updateUserAccessRequestApproval(user.customerId, approvalDto);
      toast.success(newStatus === UserAccessRequestStatusEnum.Approved ? t('AccessRequestApprovedSuccessToast') : t('AccessRequestDeclinedSuccessToast'), { duration: 3500, });
      appStore.notificationStore.refreshAccessRequestNotifications(user);
    } catch (e) {
      console.log(e);
      toast.error(t('AccessRequestUpdateError'), { duration: 3500, });
    } finally {
      setIsSaving(false);
      getAccessRequestData();
    }
  };

  const handleApproveCallback = async () => {
    await updateAccessRequestStatus();
    setOpenApproveDialog(false);
  };

  const handleRequestStatusChangeClick = (requestNewStatus: UserAccessRequestStatusEnum, requestId: number) => {
    setToBeUpdatedRequestId(requestId);
    setNewStatus(requestNewStatus);
    setOpenApproveDialog(true);
  };

  if (user.isConsultantUser === false) {
    navigate('/401');
  }

  return (
    <>
      <Helmet>
        <title>{t('AccessRequestsListPageHelmet')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
          mx: 2
        }}
      >
        <Container
          maxWidth="xl"
        >
          <CommonDialog
            confirmButtonTitle={newStatus === UserAccessRequestStatusEnum.Approved ? `${t('ApproveTitle')}` : `${t('DeclineTitle')}`}
            cancelButtonTitle={`${t('ButtonCancel')}`}
            dialogContent={newStatus === UserAccessRequestStatusEnum.Approved ? `${t('AccessRequestApprovalApproveDialogContent')}` : `${t('AccessRequestApprovalDeclineDialogContent')}`}
            dialogTitle={`${t('ConfirmTitle')}`}
            handleCancel={() => setOpenApproveDialog(false)}
            handleConfirm={() => handleApproveCallback()}
            isOpen={openApproveDialog}
            processing={isSaving}
            confirmButtonColor={newStatus === UserAccessRequestStatusEnum.Approved ? 'success' : undefined}
          />
          <Grid
            container
            direction="row"
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
                sx={{ mb: 3 }}
              >
                {t('AccessRequestPageTitle')}
              </Typography>
            </Grid>

          </Grid>
          <Grid
            container
            direction="row"
          >
            <Breadcrumbs
              sx={{
                paddingLeft: '1%',
              }}
              aria-label="breadcrumb"
              separator={(
                <ChevronRightIcon
                  fontSize="small"
                  sx={{ mb: 5 }}
                />
              )}
            >
              <Grid
                item
                sx={{ mb: 5 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/landingpage"
                  variant="subtitle2"
                >
                  {t('LandingPagePageTitle')}
                </Link>
              </Grid>
              <Grid item>
                <Typography
                  color="text.primary"
                  sx={{ mb: 5 }}
                  variant="subtitle2"
                >
                  {t('AccessRequestPageTitle')}
                </Typography>
              </Grid>
            </Breadcrumbs>
          </Grid>
          {isLoading && (
            <Box
              display="flex"
              justifyContent="center"
              sx={{ mt: 10 }}
              bgcolor="background.secondary"
            >
              <CircularProgress
                size={65}
              />
            </Box>
          )}
          <Card>
            <Table>
              {!isLoading && accessRequests && accessRequests.length === 0 && (
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                        align="center"
                      >
                        {t('AccessRequestsNoAccessRequests')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}
              {!isLoading && accessRequests && accessRequests.length !== 0 && (
                <TableHead>
                  <TableRow sx={{ verticalAlign: 'top' }}>
                    <TableCell>
                      <Typography variant="tableHead">{t('AccessRequestsListRequesterName')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="tableHead">{t('AccessRequestsListRolesRequested')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="tableHead">{t('Customer')}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="tableHead">{t('PaymentActionTitle')}</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}

              <TableBody>
                {!isLoading && accessRequests && accessRequests.map((ar) => (
                  <React.Fragment key={ar.id}>
                    <TableRow sx={{ verticalAlign: 'top' }}>
                      <TableCell>
                        <Typography>{ar.user.name}</Typography>
                      </TableCell>
                      <TableCell>
                        {ar.requestRoles?.map((r) => (
                          <React.Fragment key={r.userRole}>
                            <Typography>
                              {GetUserRoleTranslation(t, r.userRole)}
                            </Typography>
                          </React.Fragment>
                        ))}
                        {ar.requestInternalRoles?.map((ir) => (
                          <React.Fragment key={ir.internalRole}>
                            <Typography>
                              {GetInternalRoleTranslation(t, ir.internalRole)}
                            </Typography>
                          </React.Fragment>
                        ))}
                      </TableCell>
                      <TableCell>
                        <Typography>{ar?.customer?.name}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          sx={{ mr: 1 }}
                          color="success"
                          variant="contained"
                          onClick={() => handleRequestStatusChangeClick(UserAccessRequestStatusEnum.Approved, ar.id)}
                        >
                          {t('ApproveTitle')}
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => handleRequestStatusChangeClick(UserAccessRequestStatusEnum.Declined, ar.id)}
                        >
                          {t('DeclineTitle')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </Card>
        </Container>
      </Box>

    </>

  );
};

export default AccessRequestList;
