import { LoadingButton } from '@mui/lab';
import { CircularProgress, Divider, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { holidayBonusApi } from 'src/API/holidayBonusApi';
import { useAppStore } from 'src/store/mobx/appStore';
import { HolidayBonusChangePeriod, HolidayBonusChangeRequest } from 'src/types/apiSchemas';
import NotFound from '../NotFound';
import './numberInputStyles.css';
import { calculateHolidayBalanceChangeVacationDays } from 'src/utils/holidayBalanceUtils';

const HolidayBonusChangeRequestForm = ({ holidayBonusChangePeriod }: { holidayBonusChangePeriod: HolidayBonusChangePeriod }) => {
  const { t } = useTranslation();
  const appStore = useAppStore();
  const settings = appStore.settingsStore.get();
  const { language } = settings;
  const [holidayBonusChangeRequest, setHolidayBonusChangeRequest] = useState<HolidayBonusChangeRequest>({ days: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPercent, setSelectedPercent] = useState<string>('default');
  const [maxChangeDays, setMaxChangeDays] = useState(0);
  const { holidayBonusChangePeriodId } = holidayBonusChangePeriod;

  const infoText = holidayBonusChangePeriod.translations.find((tr) => tr.languageCode === language) || holidayBonusChangePeriod.translations[0];

  const countChangeDaysFromPercent = (percent) => Math.floor(maxChangeDays * (parseInt(percent, 10) / 100));

  useEffect(() => {
    const getHolidayBonusChangeRequest = async () => {
      try {
        const response = await holidayBonusApi.getHolidayBonusChangeRequest(holidayBonusChangePeriodId);
        setHolidayBonusChangeRequest(response);
      } catch (err) {
        console.log(err);
      }
    };

    const getUserHolidayChangePeriodBalance = async () => {
      try {
        const response = await holidayBonusApi.getHolidayBonusChangePeriodBalance(holidayBonusChangePeriodId);

        if (response.items.length > 0) {
          setMaxChangeDays(response.items[0].amount);
        }
      } catch (err) {
        console.log(err);
      }
    };

    const updateData = async () => {
      setIsLoading(true);
      await Promise.allSettled([getHolidayBonusChangeRequest(), getUserHolidayChangePeriodBalance()]);
      setIsLoading(false);
    };

    updateData();
  }, [holidayBonusChangePeriod]);

  if (isLoading) return <CircularProgress sx={{ display: 'block', m: 'auto' }} />;

  if (!holidayBonusChangePeriod) return <NotFound />;

  return (
    <>
      <Formik
        enableReinitialize
        onSubmit={async (values) => {
          const newHolidayBonusChangeRequest: HolidayBonusChangeRequest = {
            days: parseInt(values.changeDays, 10)
          };
          try {
            await holidayBonusApi.putHolidayBonusChangePeriodRequest(holidayBonusChangePeriod.holidayBonusChangePeriodId, newHolidayBonusChangeRequest);
            toast.success(t('HolidayBonusChangeRequestSaved'));
          } catch (err) {
            toast.error(t('HolidayBonusChangeRequestSaveFailed'));
          }
        }}
        initialValues={{
          changeDays: `${holidayBonusChangeRequest.days}`
        }}
      >
        {({ values, submitForm, setFieldValue, isSubmitting }) => (
          <form onSubmit={submitForm}>
            <Grid
              container
              gap={3}
            >
              <Grid
                item
                xs={12}
              >
                <Typography variant="h3">{t('HolidayBonusChangeRequest')}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography variant="h5">{t('YouCanChangeHolidayBonusToVacation')}</Typography>
                <Typography>{infoText?.description}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography variant="h5">{t('HolidayBonusAccumulation')}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography variant="formFieldHeader">{t('HolidayBonusChangeMaxAmount')}</Typography>
                <Typography>{maxChangeDays}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography
                  sx={{ mb: 1 }}
                  variant="formFieldHeader"
                >
                  {t('HolidayBonusChangeAmount')}
                </Typography>
                {holidayBonusChangePeriod && holidayBonusChangePeriod.choices.length > 0 && (
                  <>
                    <Select
                      name="percentage"
                      value={selectedPercent}
                      onChange={(ev) => {
                        setSelectedPercent(ev.target.value);
                        setFieldValue('changeDays', countChangeDaysFromPercent(ev.target.value));
                      }}
                      sx={{ width: 120 }}
                    >
                      <MenuItem
                        key="default"
                        value="default"
                      >
                        {t('HolidayBonusChangeSelectPercent')}
                      </MenuItem>
                      {holidayBonusChangePeriod.choices.map((percent) => (
                        <MenuItem
                          key={percent}
                          value={percent}
                        >
                          {`${percent}%`}
                        </MenuItem>
                      ))}
                    </Select>
                    <span style={{ marginLeft: 10 }}>
                      {t('HolidayBonusChangeCurrentSelection')}
                      :
                      {' '}
                      {values.changeDays || 0}
                      {' '}
                      {t('HolidayBonusChangeSelectedDays')}
                    </span>
                  </>
                )}
                {holidayBonusChangePeriod && holidayBonusChangePeriod.choices.length === 0 && (
                  <TextField
                    sx={{ width: 100 }}
                    type="number"
                    name="changeDays"
                    value={values.changeDays}
                    onChange={(ev) => {
                      const numberValue = parseInt(ev.target.value, 10);

                      if (numberValue > maxChangeDays) return;

                      setFieldValue('changeDays', ev.target.value);
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: maxChangeDays
                      }
                    }}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography variant="formFieldHeader">{t('ReceivedVacationDaysAmount')}</Typography>
                <Typography>
                  {calculateHolidayBalanceChangeVacationDays(values.changeDays, holidayBonusChangePeriod.divider)}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <LoadingButton
              variant="contained"
              onClick={submitForm}
              loading={isSubmitting}
            >
              {t('GenericButtonSave')}
            </LoadingButton>
          </form>
        )}
      </Formik>
    </>
  );
};

export default HolidayBonusChangeRequestForm;
