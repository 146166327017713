import { Box, Button, CircularProgress, Divider, FormControlLabel, Grid, IconButton, List, ListItem, ListItemText, ListSubheader, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from 'src/store/mobx/appStore';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import { baseApi } from 'src/API/baseApi';
import { Absence, AbsenceType, AccessPermission, ManagerSubstitutionDto, SubordinateDto } from 'src/types/apiSchemas';
import AddIcon from '@mui/icons-material/Add';
import { formatInTimeZone } from 'date-fns-tz';
import toast from 'react-hot-toast';
import useMounted from 'src/hooks/useMounted';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router';
import { AuthorizationService, IAuthorizationService } from 'src/Services';
import { observer } from 'mobx-react';
import { WhiteOutlineButton } from 'src/components/WhiteOutlineButton';
import { fileExtensionValidation } from 'src/utils/yupUtils';
import { allowedAbsenceFileExtensions } from 'src/constants';

interface CreateAbsenceFormProps {
  onAbsenceCreate?: (absenceId: number) => void;
  disableAttachments?: boolean;
  onCancel?: () => void;
  disableOnBehalfSubordinate?: boolean;
  selectedSubordinateId?: number;
  alwaysPending?: boolean;
}

const CreateAbsenceForm: FC<CreateAbsenceFormProps> = observer(({ onAbsenceCreate, disableAttachments, onCancel, disableOnBehalfSubordinate, selectedSubordinateId, alwaysPending }) => {
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const { t } = useTranslation();
  const [absenceTypes, setAbsenceTypes] = useState<AbsenceType[]>([]);
  const [managers, setManagers] = useState<ManagerSubstitutionDto[]>([]);
  const [managerId, setManagerId] = useState<number>();
  const [isLoadingSubordinates, setIsLoadingSubordinates] = useState<boolean>(false);
  const [subordinateId, setSubordinateId] = useState<number | undefined>(selectedSubordinateId);
  const [subordinates, setSubordinates] = useState<SubordinateDto[]>([]);
  const [behalfOfSubordinate, setBehaldOfSubordinate] = useState(!!selectedSubordinateId);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const mounted = useMounted();
  const navigate = useNavigate();

  const auth: IAuthorizationService = new AuthorizationService();

  const initialStartDate = new Date();
  const initialEndDate = new Date();
  initialStartDate.setHours(0, 0, 0);
  initialEndDate.setHours(23, 59, 59);

  const getAbsenceTypes = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await baseApi.getAbsenceTypes(user?.customerId);
      setAbsenceTypes(data.filter((ac) => ac.absenceCategory === 'SickLeave' && (ac.attachmentRequirement !== 'None' || !disableAttachments)));
    } catch (err) {
      console.log(`Error loading absence types: ${err}`);
    } finally {
      setIsLoading(false);

    }
  }, [mounted]);

  const getMyEffectiveManagerSubstitutions = useCallback(async () => {
    setIsLoading(true);
    try {
      const substituteData = await baseApi.getMyEffectiveManagerSubstitutions();
      setManagers(substituteData);
      if (auth.get(user).hasManagerAccess) {
        setManagerId(user.userId);
      } else if (substituteData.length > 0) {
        setManagerId(substituteData[0].managerId);
      }
    } catch (err) {
      console.error(`Error loading manager substitutions: ${err}`);
    } finally {
      setIsLoading(false);
    }
  }, [mounted]);

  const getMyManagerSubstitutionSubordinates = useCallback(async (userId: number) => {
    setIsLoadingSubordinates(true);
    try {
      const subordinateData = await baseApi.getMyManagerSubstitutionSubordinates(userId);
      setSubordinates(subordinateData);
      if (subordinateData.length > 0) {
        setSubordinateId(subordinateData[0].id);
      }
    } catch (err) {
      console.error(`Error loading manager substitution subordinates: ${err}`);
    } finally {
      setIsLoadingSubordinates(false);
    }
  }, [managerId]);

  const getUserSubordinates = useCallback(async () => {
    setIsLoadingSubordinates(true);
    try {
      const subordinateData = await baseApi.getSubordinates(user?.customerId);
      setSubordinates(subordinateData);
      if (subordinateData.length > 0) {
        setSubordinateId(subordinateData[0].id);
      }
    } catch (err) {
      console.error(`Error loading user subordinates: ${err}`);
    } finally {
      setIsLoadingSubordinates(false);
    }
  }, [mounted]);

  useEffect(() => {
    getAbsenceTypes();
    if (!selectedSubordinateId) {
      getUserSubordinates();
      getMyEffectiveManagerSubstitutions();
    }
  }, [mounted]);

  useEffect(() => {
    if (!managerId || !!selectedSubordinateId) { return; }
    if (managerId === user.userId) {
      // getting own subordinates
      getUserSubordinates();
    } else {
      // if selected a substitution, getting subordinates of the substitution manager
      getMyManagerSubstitutionSubordinates(managerId);
    }
  }, [managerId]);

  const attachmentsEnabled = auth.get(user).has(AccessPermission.AttachmentsAccess).verify();

  const canSubmitOnBehalfOfSubordinate = !disableOnBehalfSubordinate &&
    auth.get(user).hasManagerOrSubstituteManagerAccess && (subordinates?.length > 0 || managers?.length > 0);
  const hasSubstitutes = managers?.length > 0;

  if (!auth.get(user).has(AccessPermission.AbsencesAccess).verify()) {
    navigate('/401');
  }

  if (isLoading || isSending) {
    return <CircularProgress sx={{ margin: 'auto', display: 'block' }} />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        absenceTypeId: absenceTypes.length > 0 ? absenceTypes[0].absenceTypeId : '',
        startDateUtc: initialStartDate,
        endDateUtc: initialEndDate,
        singleDayAbsence: false,
        startTimeUtc: new Date(),
        endTimeUtc: new Date(),
        attachments: [] as File[],
        pendingAttachment: false,
        behalfOfSubordinate: behalfOfSubordinate,
        substituteId: managerId ? managerId.toString() : '',
        subordinateId: subordinateId ? subordinateId.toString() : '',
      }}
      validationSchema={Yup.object().shape({
        startDateUtc: Yup.date().nullable().required(t('YupGeneralMessage')),
        endDateUtc: Yup.date().nullable().when('singleDayAbsence', (value) => {
          if (value) {
            return Yup.date().nullable();
          }

          return Yup.date().nullable().when('startDateUtc', (startDateUtc, schema) => {
            if (!startDateUtc) {
              return Yup.date().nullable().required(t('YupGeneralMessage'));
            }

            return schema.min(startDateUtc, t('AbsenceFormValidationEndDateMustBeLater')).required(t('YupGeneralMessage'));
          });
        }),
        startTimeUtc: Yup.date().nullable().when('singleDayAbsence', (value) => {
          if (value) {
            return Yup.date().nullable().required(t('YupGeneralMessage'));
          }
          return Yup.date().nullable();
        }),
        endTimeUtc: Yup.date().nullable().when('singleDayAbsence', (value, schema) => {
          if (value) {
            return schema.min(Yup.ref('startTimeUtc'), t('AbsenceFormValidationEndTimeMustBeLater')).required(t('YupGeneralMessage'));
          }
          return Yup.date().nullable();
        }),
        attachments: Yup.array().when(['absenceTypeId', 'pendingAttachment'], (absenceTypeId, pendingAttachment) => {
          const absence = absenceTypes.find((at) => at.absenceTypeId === absenceTypeId);

          if (!attachmentsEnabled || absence.attachmentRequirement !== 'Required' || pendingAttachment || disableAttachments) {
            return Yup.array().test(fileExtensionValidation(t('FileTypeNotAccepted'), allowedAbsenceFileExtensions));
          }
          return Yup.array().min(1, t('AbsenceFormValidationMissingAttachment')).test(fileExtensionValidation(t('FileTypeNotAccepted'), allowedAbsenceFileExtensions));
        })
      })}
      onSubmit={async (values) => {
        setIsSending(true);
        try {
          const getStartDate = (date: Date) => {
            if (values.singleDayAbsence) {
              const startDt = new Date(values.startDateUtc);
              startDt.setHours(values.startTimeUtc.getHours());
              startDt.setMinutes(values.startTimeUtc.getMinutes());
              return startDt;
            }
            return date;
          };

          const getEndDate = (date: Date) => {
            if (values.singleDayAbsence) {
              const endDt = new Date(values.startDateUtc);
              endDt.setHours(values.endTimeUtc.getHours());
              endDt.setMinutes(values.endTimeUtc.getMinutes());
              return endDt;
            }
            return date;
          };

          const newAbsence: Absence = {
            absenceTypeId: values.absenceTypeId as number,
            startOfAbsenceUTC: formatInTimeZone(getStartDate(values.startDateUtc), 'UTC', "yyyy-MM-dd'T'HH:mm:ss'Z'"),
            endOfAbsenceUTC: formatInTimeZone(getEndDate(values.endDateUtc), 'UTC', "yyyy-MM-dd'T'HH:mm:ss'Z'"),
            alwaysPending: !!alwaysPending
          };
          const data = await baseApi.postAbsence(user, newAbsence, values.behalfOfSubordinate ? subordinateId : null);

          if (values.attachments.length > 0) {
            try {
              await baseApi.postAttachment(user, values.attachments, 'SickLeaveCertificate', data.absenceId, values.behalfOfSubordinate ? subordinateId : null);
            } catch (err) {
              const attachmentError = err?.response?.data?.resultKey?.split('.').at(-1);
              if (attachmentError) {
                toast.error(`${t('AttachmentsFormAttachmentsSendFailed')}: ${t(attachmentError)}`);
              } else {
                toast.error(t('AttachmentsFormAttachmentsSendFailed'));
              }
            }
          }

          appStore.notificationStore.refreshApprovalNotifications(user);

          toast.success(t('AbsenceFormAbsenceSaved'));

          if (onAbsenceCreate) {
            onAbsenceCreate(data.absenceId);
          } else {
            navigate('/dashboard/absences');
          }
        } catch (err) {
          console.log(err);
          toast.error(t('AbsenceFormAbsenceSaveFailed'));
        }
        setIsSending(false);
      }}
    >
      {({ values, touched, errors, setFieldValue, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Typography
            variant="h3"
            sx={{ mb: 3 }}
          >
            {t('AbsenceFormSickLeave')}
          </Typography>
          <Grid
            container
            gap={3}
          >
            {canSubmitOnBehalfOfSubordinate && (
              <Grid
                item
                xs={12}
              >
                <FormControlLabel
                  sx={{ ml: 0.3 }}
                  control={(
                    <Switch
                      onChange={(event) => {
                        setFieldValue('behalfOfSubordinate', event.target.checked);
                        setBehaldOfSubordinate(event.target.checked);
                      }}
                      checked={values.behalfOfSubordinate}
                      color="primary"
                      edge="start"
                    />
                  )}
                  label={(
                    <Box>
                      {t('AbsenceFormSendBehalfOfSubordinate')}
                    </Box>
                  )}
                />
                {values.behalfOfSubordinate && (
                  <>
                    {hasSubstitutes && (
                      <Grid
                        item
                        xs={12}
                        sx={{ mt: 2 }}
                      >
                        <Typography
                          variant="formFieldHeader"
                          sx={{ mb: 1 }}
                        >
                          {t('UserManager')}
                        </Typography>
                        <Select
                          sx={{ mb: 2, minWidth: 300 }}
                          value={values.substituteId}
                          name="substituteId"
                          onChange={(event) => {
                            setFieldValue('substituteId', event.target.value);
                            setManagerId(Number(event.target.value));
                          }}
                        >
                          {auth.get(user).hasManagerAccess && (
                            <MenuItem
                              value={user.userId}
                              key={`own-${user.userId}`}
                            >
                              {t('OwnTeamSubordinates')}
                            </MenuItem>
                          )}
                          {managers.length > 0 && (
                            <ListSubheader sx={{ marginTop: 2 }}>
                              <Typography
                                variant="body2"
                                fontWeight="bold"
                              >
                                {t('ManagerSubstitutions')}
                              </Typography>
                            </ListSubheader>
                          )}
                          {managers.map((manager) => (
                            <MenuItem
                              value={manager.managerId}
                              key={`substitute-${manager.managerId}`}
                            >
                              {manager.managerName}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}

                    >
                      <Typography
                        variant="formFieldHeader"
                        sx={{ mb: 1 }}
                      >
                        {t('AbsenceFormSubordinate')}
                      </Typography>
                      {isLoadingSubordinates ?
                        (
                          <CircularProgress size={20} />
                        )
                        :
                        (
                          <Select
                            sx={{ minWidth: 300 }}
                            value={values.subordinateId}
                            name="subordinateId"
                            onChange={(event) => {
                              setFieldValue('subordinateId', event.target.value);
                              setSubordinateId(Number(event.target.value));
                            }}
                          >
                            {subordinates?.map((member) => (
                              <MenuItem
                                value={member.id}
                                key={member.id}
                              >
                                {member.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }
                    </Grid>
                  </>
                )}
              </Grid>
            )}
            <Grid
              item
              xs={12}
            >
              <Typography
                variant="formFieldHeader"
                sx={{ mb: 1 }}
              >
                {t('AbsenceFormAbsenceReason')}
              </Typography>
              <Select
                value={values.absenceTypeId}
                name="absenceTypeId"
                onChange={handleChange}
              >
                {absenceTypes.map((absenceType) => (
                  <MenuItem
                    value={absenceType.absenceTypeId}
                    key={absenceType.absenceTypeId}
                  >
                    {absenceType.contents.find((c) => c.languageCode === user?.languageCode)?.name || absenceType.code}
                  </MenuItem>
                ))}
              </Select>
              <Typography sx={{ mt: 1 }}>{absenceTypes.find((at) => at.absenceTypeId === values.absenceTypeId)?.contents.find((c) => c.languageCode === user?.languageCode)?.description}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography
                variant="formFieldHeader"
                sx={{ mb: 1 }}
              >
                {t('AbsenceFormAbsenceStartDate')}
              </Typography>
              <DatePicker
                value={values.startDateUtc}
                onChange={(val: Date) => {
                  if (val) {
                    val.setHours(0, 0, 0);
                  }
                  setFieldValue('startDateUtc', val);
                }}
                mask="__.__.____"
                componentsProps={{
                  actionBar: {
                    actions: ['clear']
                  }
                }}
                inputFormat="dd.MM.yyyy"
                renderInput={(params) => (
                  <TextField
                    sx={{ maxWidth: 200 }}
                    style={{ display: 'flex' }}
                    {...params}
                    error={Boolean(touched.startDateUtc && errors.startDateUtc)}
                    helperText={(touched.startDateUtc && errors.startDateUtc)}
                  />
                )}
              />
            </Grid>
            {!values.singleDayAbsence && (
              <Grid
                item
                xs={12}
                sm={5}
              >
                <Typography
                  variant="formFieldHeader"
                  sx={{ mb: 1 }}
                >
                  {t('AbsenceFormAbsenceEndDate')}
                </Typography>
                <DatePicker
                  value={values.endDateUtc}
                  onChange={(val: Date) => {
                    if (val) {
                      val.setHours(23, 59, 59);
                    }
                    setFieldValue('endDateUtc', val);
                  }}
                  mask="__.__.____"
                  componentsProps={{
                    actionBar: {
                      actions: ['clear']
                    }
                  }}
                  inputFormat="dd.MM.yyyy"
                  renderInput={(params) => (
                    <TextField
                      sx={{ maxWidth: 200 }}
                      style={{ display: 'flex' }}
                      {...params}
                      error={Boolean(touched.endDateUtc && errors.endDateUtc)}
                      helperText={(touched.endDateUtc && errors.endDateUtc)}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
            >
              <FormControlLabel
                sx={{ ml: 0.3 }}
                control={(
                  <Switch
                    onChange={(event) => {
                      setFieldValue('singleDayAbsence', event.target.checked);
                    }}
                    checked={values.singleDayAbsence}
                    color="primary"
                    edge="start"
                  />
                )}
                label={(
                  <Box>
                    {t('AbsenceFormUnderOneDayAbsence')}
                  </Box>
                )}
              />
            </Grid>
            {values.singleDayAbsence && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Typography
                    variant="formFieldHeader"
                    sx={{ mb: 1 }}
                  >
                    {t('AbsenceFormStartTime')}
                  </Typography>
                  <TimePicker
                    ampm={false}
                    value={values.startTimeUtc}
                    onChange={(val) => {
                      setFieldValue('startTimeUtc', val);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ maxWidth: 200 }}
                        style={{ display: 'flex' }}
                        {...params}
                        error={Boolean(touched.startTimeUtc && errors.startTimeUtc)}
                        helperText={(touched.startTimeUtc && errors.startTimeUtc)}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                >
                  <Typography
                    variant="formFieldHeader"
                    sx={{ mb: 1 }}
                  >
                    {t('AbsenceFormEndTime')}
                  </Typography>
                  <TimePicker
                    ampm={false}
                    value={values.endTimeUtc}
                    onChange={(val) => {
                      setFieldValue('endTimeUtc', val);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ maxWidth: 200 }}
                        style={{ display: 'flex' }}
                        {...params}
                        error={Boolean(touched.endTimeUtc && errors.endTimeUtc)}
                        helperText={(touched.endTimeUtc && errors.endTimeUtc)}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            {!disableAttachments && attachmentsEnabled && absenceTypes.find((at) => at.absenceTypeId === values.absenceTypeId)?.attachmentRequirement !== 'None'
              && (
                <>
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      variant="h5"
                      sx={{ mb: 1, mt: 5 }}
                    >
                      {t('AbsenceFormAddAttachments')}
                    </Typography>
                    {values.attachments.length > 0 && (
                      <List sx={{ maxWidth: 300 }}>
                        {values.attachments.map((file: File, index) => (
                          <ListItem
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={index}
                            secondaryAction={(
                              <IconButton
                                onClick={() => {
                                  setFieldValue('attachments', values.attachments.filter((v, i) => i !== index));
                                }}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            )}
                            disableGutters
                            sx={{ p: 0 }}
                          >
                            <ListItemText>
                              <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: 40 }}>{file.name}</div>
                              {errors.attachments?.at(index) && <div><Typography color="error">{errors.attachments?.at(index)}</Typography></div>}
                            </ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Button
                      startIcon={<AddIcon fontSize="small" />}
                      variant="light"
                      component="label"
                    >
                      {t('AbsenceFormAddDocument')}
                      <input
                        accept={allowedAbsenceFileExtensions.join(', ')}
                        name="attachment"
                        type="file"
                        hidden
                        onChange={(e) => {
                          setFieldValue('attachments', [...values.attachments, e.target.files[0]]);
                          e.target.value = '';
                        }}
                        capture
                      />
                    </Button>
                    <div style={{ margin: '10px 0' }}>
                      {t('AttachmentsPageAllowedFileExtensions')}
                      :
                      {' '}
                      {allowedAbsenceFileExtensions.join(', ')}
                    </div>
                  </Grid>
                  {absenceTypes.find((at) => at.absenceTypeId === values.absenceTypeId)?.attachmentRequirement === 'Required'
                    && (
                      <Grid
                        item
                        xs={12}
                      >
                        {values.attachments.length < 1 && <Typography>{t('AbsenceFormPendingAttachmentRequired')}</Typography>}
                        {errors.attachments && touched.attachments && values.attachments.length < 1 && (
                          <div style={{ marginBottom: 10 }}>
                            <Typography color="error">
                              {errors.attachments}
                            </Typography>
                          </div>
                        )}
                        {/* user can save absences without an attachment, if adding the absence for themselves */}
                        {!values.behalfOfSubordinate && values.attachments.length < 1 && (
                          <FormControlLabel
                            sx={{ ml: 0.3 }}
                            control={(
                              <Switch
                                checked={values.pendingAttachment}
                                color="primary"
                                edge="start"
                                name="compact"
                                onChange={(event) => {
                                  setFieldValue('pendingAttachment', event.target.checked);
                                }}
                              />
                            )}
                            label={(
                              <Box>
                                {t('AbsenceFormPendingAttachment')}
                              </Box>
                            )}
                          />
                        )}
                      </Grid>
                    )}
                </>
              )}
            <Grid
              item
              xs={12}
            >
              <Divider sx={{ mb: 3 }} />
              <LoadingButton
                loading={isSending}
                variant="contained"
                type="submit"
              >
                {t('GenericButtonSave')}
              </LoadingButton>
              {onCancel && (
                <WhiteOutlineButton
                  variant="outlined"
                  sx={{ ml: 2 }}
                  onClick={onCancel}
                >
                  {t('ButtonCancel')}
                </WhiteOutlineButton>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
});

export default CreateAbsenceForm;
