import { FC } from 'react';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CreateAnnouncementBaseProps } from '../../createAnnouncement/props/CreateAnnouncementBaseProps';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AnnouncementScopeDto, AnnouncementTargetGroup } from 'src/types/apiSchemas';
import CreateAnnouncementsTargetGroups from '../../createAnnouncement/CreateAnnouncementsTargetGroups';

interface CreateAnnouncementRightPanelProps extends CreateAnnouncementBaseProps {
  setFieldValueHandler: any;
  scopes: AnnouncementScopeDto[];
}

const EditAnnouncementRightPanel: FC<CreateAnnouncementRightPanelProps> = (props) => {
  const { onChangeHandler, touchedHandler, errorHandler, formValues, setFieldValueHandler, scopes } = props;
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      rowSpacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          color="textPrimary"
          variant="formFieldHeader"
          sx={{ ml: 1, mb: 1 }}
        >
          {t('CreateAnnouncementsRightPanelSender')}
        </Typography>
        <TextField
          fullWidth
          name="sender"
          onChange={onChangeHandler}
          value={formValues.sender}
          error={Boolean(touchedHandler.sender && errorHandler.sender)}
          helperText={touchedHandler.sender && errorHandler.sender}
          variant="outlined"
          disabled
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography
          color="textPrimary"
          variant="formFieldHeader"
          sx={{ ml: 1, mb: 1 }}
        >
          {t('CreateAnnouncementsRightPanelReleaseDate')}
        </Typography>
        <DateTimePicker
          disabled
          mask="__.__.____ __:__"
          componentsProps={{
            actionBar: {
              actions: ['clear']
            }
          }}
          ampm={false}
          inputFormat="dd.MM.yyyy HH:mm"
          value={formValues.releaseDate}
          onChange={(val: Date) => {
            if (val != null) {
              val.setSeconds(0);
            }
            setFieldValueHandler('releaseDate', val);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(touchedHandler.releaseDate && errorHandler.releaseDate)}
              helperText={(touchedHandler.releaseDate && errorHandler.releaseDate)}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography
          color="textPrimary"
          variant="formFieldHeader"
          sx={{ ml: 1, mb: 1 }}
        >
          {t('CreateAnnouncementsRightPanelExpirationDate')}
        </Typography>
        <DateTimePicker
          mask="__.__.____ __:__"
          componentsProps={{
            actionBar: {
              actions: ['clear']
            }
          }}
          ampm={false}
          inputFormat="dd.MM.yyyy HH:mm"
          value={formValues.expirationDate}
          onChange={(val: Date) => {
            if (val != null) {
              val.setSeconds(0);
            }
            setFieldValueHandler('expirationDate', val);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(touchedHandler.expirationDate && errorHandler.expirationDate)}
              helperText={touchedHandler.expirationDate && errorHandler.expirationDate}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FormControl sx={{ mb: 2 }}>
          <FormLabel>{t('CreateAnnouncementsRightPanelAnnouncementTargetGroup')}</FormLabel>
          <RadioGroup
            aria-labelledby="targetGroup"
            name="targetGroup"
            value={formValues.targetGroup}
            onChange={onChangeHandler}
          >
            <FormControlLabel
              value={AnnouncementTargetGroup.Everyone}
              control={<Radio />}
              label={t('CreateAnnouncementsRightPanelTargetEveryone')}
            />
            <FormControlLabel
              value={AnnouncementTargetGroup.SpecificGroups}
              control={<Radio />}
              label={t('CreateAnnouncementsRightPanelTargetSpecificGroups')}
            />
          </RadioGroup>
        </FormControl>
        {formValues.targetGroup === AnnouncementTargetGroup.SpecificGroups && (
          <Grid
            item
            xs={12}
          >
            <CreateAnnouncementsTargetGroups
              onChangeHandler={onChangeHandler}
              touchedHandler={touchedHandler}
              errorHandler={errorHandler}
              formValues={formValues}
              scopes={scopes}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

EditAnnouncementRightPanel.propTypes = {
  // @ts-ignore
  onChangeHandler: PropTypes.any.isRequired,
  touchedHandler: PropTypes.any.isRequired,
  errorHandler: PropTypes.any.isRequired,
  formValues: PropTypes.any.isRequired,
  setFieldValueHandler: PropTypes.any.isRequired
};

export default EditAnnouncementRightPanel;
