import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AnnouncementLink } from 'src/types/announcementLink';
import CreateAnnouncementAddLink from './CreateAnnouncementAddLink';
import CreateAnnouncementDisplayLink from './CreateAnnouncementDisplayLinks';

interface CreateAnnouncementLinksProps {
  formValues: any,
  arrayHelpers: any
}

const CreateAnnouncementLinks : FC<CreateAnnouncementLinksProps> = (props) => {
  const { formValues, arrayHelpers } = props;
  const { t } = useTranslation();

  const createdLinks = (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      rowSpacing={1}
    >
      {formValues.createdLinks.map((link:AnnouncementLink) => (
        <Grid
          item
          key={link.url}
        >
          <CreateAnnouncementDisplayLink
            link={link}
            formValues={formValues}
            arrayHelpers={arrayHelpers}
          />
        </Grid>
      ))}
    </Grid>
  );
  const noContent = (<Typography sx={{ m: 2 }}>{t('CreateAnnouncementLinksNoAddedLinks')}</Typography>);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
      >
        <CreateAnnouncementAddLink
          arrayHelpers={arrayHelpers}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography
          variant="formFieldHeader"
          color="textPrimary"
          sx={{ ml: 1, mb: 1 }}
        >
          {t('CreateAnnouncementLinksAddedLinks')}
        </Typography>
        {formValues.createdLinks.length > 0 ? createdLinks : noContent}
      </Grid>

    </Grid>
  );
};

export default CreateAnnouncementLinks;
