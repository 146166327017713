import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { PaymentReviewDto, PaymentReviewStatus } from '../../../types/apiSchemas';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { displayLocalDateTime } from 'src/utils/dateUtils';
import { getInitials } from 'src/utils/getInitial';

interface PaymentReviewsProps {
  paymentReviews: PaymentReviewDto[];
  paymentStatus: string;
}

const PaymentReviewsCard: FC<PaymentReviewsProps> = (props) => {
  const { paymentReviews, paymentStatus, ...other } = props;
  const { t } = useTranslation();

  const iconChange = (status: PaymentReviewStatus) => {
    if (status === 'Pending') {
      if (paymentStatus === 'Completed' || paymentStatus === 'Declined') {
        return (
          <Typography />
        );
      }
      return (
        <PendingActionsIcon sx={{ color: 'text.primary' }} />
      );
    }
    return status === 'Declined'
      ? (
        <CancelIcon
          sx={{ color: 'error.main' }}
        />
      )
      : (
        <DoneIcon
          sx={{ color: 'success.main' }}
        />
      );
  };

  const getReviewed = () => {
    const reviewed: PaymentReviewDto[] = paymentReviews.filter((review) => review.status === 'Reviewed' || review.status === 'Declined');
    return `${reviewed.length} / ${paymentReviews.length}`;
  };

  return (
    <Card {...other}>
      <CardHeader
        title={`${t('PaymentReviewsTitle')} ${getReviewed()}`}
      />
      <Divider />
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '40%' }}>
                  <Typography variant="tableHead">{t('PaymentReviewerTitle')}</Typography>
                </TableCell>
                <TableCell sx={{ width: '20%' }}>
                  <Typography variant="tableHead">{t('StatusTitle')}</Typography>
                </TableCell>
                <TableCell sx={{ width: '40%' }}>
                  <Typography variant="tableHead">{t('CommentTitle')}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentReviews.map((item) => (
                <TableRow key={item.paymentReviewId}>
                  <TableCell sx={{ width: '40%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        src={item.profilePictureUrl}
                        sx={{
                          height: 32,
                          width: 32,
                          marginRight: '8px',
                        }}
                        alt={item.userName}
                      >
                        {getInitials(item.userName)}
                      </Avatar>
                      <Typography>{item.userName}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ width: '20%' }}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item>
                        {iconChange(item.status)}
                      </Grid>
                      <Grid
                        marginLeft={1}
                        item
                      >
                        <Typography>
                          {item.processed && `${displayLocalDateTime(item.processed, true)}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell sx={{ width: '40%' }}>
                    <Typography>{item.comment}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

PaymentReviewsCard.propTypes = {
  paymentReviews: PropTypes.array.isRequired
};

export default PaymentReviewsCard;
