import { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CreateAnnouncementHeader from 'src/components/dashboard/createAnnouncement/CreateAnnouncementHeader';
import CampaignIcon from '@mui/icons-material/Campaign';
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import CreateAnnouncementTypeSettings from 'src/components/dashboard/createAnnouncement/CreateAnnouncementTypeSettings';
import { AnnouncementDetailDto, AnnouncementImportance, AnnouncementScopeDto, AnnouncementScopeType, AnnouncementTargetGroup, AnnouncementType } from 'src/types/apiSchemas';
import CreateAnnouncementContentHolder from 'src/components/dashboard/createAnnouncement/CreateAnnouncementContentHolder';
import EditAnnouncementRightPanel from 'src/components/dashboard/announcements/editAnnouncement/EditAnnouncementRightPanel';
import CreateAnnouncementAddImage from 'src/components/dashboard/createAnnouncement/CreateAnnouncementAddImage';
import { AnnouncementLink } from 'src/types/announcementLink';
import CreateAnnouncementLinks from 'src/components/dashboard/createAnnouncement/CreateAnnouncementLinks';
import CreateAnnouncementConfirmation from 'src/components/dashboard/createAnnouncement/CreateAnnouncementConfirmation';
import EditAnnouncementModal from 'src/components/dashboard/announcements/editAnnouncement/EditAnnouncementModal';
import { baseApi } from 'src/API/baseApi';
import { useParams } from 'react-router-dom';
import { useMounted } from 'src/hooks';
import { ensureUTCFormat } from 'src/utils/dateUtils';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import CreateAnnouncementDatetimeInputs from 'src/components/dashboard/createAnnouncement/CreateAnnouncementDatetimeInputs';
import CommonModal from 'src/components/common/CommonModal';

const CreateAnnouncement: FC = observer(() => {
  // Page
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [scopes, setScopes] = useState<AnnouncementScopeDto[]>([]);
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const { announcementId } = useParams();

  const mounted = useMounted();
  const [loading, setLoading] = useState<boolean>(true);
  const [announcement, setAnnouncement] = useState<AnnouncementDetailDto>();

  const getAnnouncement = useCallback(async () => {
    try {
      const data = await baseApi.getAnnouncement(announcementId, user?.customerId);

      if (mounted.current) {
        setAnnouncement(data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getAnnouncement();
  }, [mounted]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        setScopes(await baseApi.fetchAnnouncementScopes(user?.customerId));
      } catch (error) {
        setError(true);
        setIsLoading(false);
      }
      setIsLoading(false);
    };

    setIsLoading(false);
    setError(false);

    fetchData();
  }, [mounted]);

  const toggleModal = () => {
    setOpen(!open);
  };

  const YupSchema = Yup
    .object()
    .shape({
      subjectFi: Yup
        .string()
        .max(50, t('YupFieldTooLong', { count: 50 }))
        .when(['subjectEn', 'subjectSv'], {
          is: (...args) => args.find((v) => v),
          then: Yup.string(),
          otherwise: Yup.string().required(t('YupAnnouncementTitleRequired'))
        }),
      contentFi: Yup
        .string()
        .when('subjectFi', {
          is: (val) => !val,
          then: Yup.string().max(0, t('YupAnnouncementPleaseAddTitle')),
          otherwise: Yup.string().required(t('YupGeneralMessage'))
        }),
      subjectEn: Yup
        .string()
        .max(50, t('YupFieldTooLong', { count: 50 }))
        .when(['subjectFi', 'subjectSv'], {
          is: (...args) => args.find((v) => v),
          then: Yup.string(),
          otherwise: Yup.string().required(t('YupAnnouncementTitleRequired'))
        }),
      contentEn: Yup
        .string()
        .when('subjectEn', {
          is: (val) => !val,
          then: Yup.string().max(0, t('YupAnnouncementPleaseAddTitle')),
          otherwise: Yup.string().required(t('YupGeneralMessage'))
        }),
      subjectSv: Yup
        .string()
        .max(50, t('YupFieldTooLong', { count: 50 }))
        .when(['subjectFi', 'subjectEn'], {
          is: (...args) => args.find((v) => v),
          then: Yup.string(),
          otherwise: Yup.string().required(t('YupAnnouncementTitleRequired'))
        }),
      contentSv: Yup
        .string()
        .when('subjectSv', {
          is: (val) => !val,
          then: Yup.string().max(0, t('YupAnnouncementPleaseAddTitle')),
          otherwise: Yup.string().required(t('YupGeneralMessage'))
        }),
      sender: Yup
        .string()
        .required(t('YupGeneralMessage')),
      releaseDate: Yup
        .date()
        .nullable()
        .required(t('YupGeneralMessage')),
      expirationDate: Yup
        .date()
        .nullable()
        .when('releaseDate', (val, schema) => {
          if (val) {
            return val && schema.min(val, t('YupExpirationDateShouldBeLaterThanPublishDate'));
          }
          return Yup.string();
        }),
      eventStartTimeUTC: Yup
        .date()
        .nullable()
        .when('announcementType', (val) => {
          if (val === 'Event') { return Yup.date().nullable().required(t('YupGeneralMessage')); }

          return Yup.date().nullable();
        }),
      eventEndTimeUTC: Yup
        .date()
        .nullable()
        .when(['announcementType', 'eventStartTimeUTC'], (...args) => {
          const [announcementType, eventStartTimeUTC, schema] = args;

          if (announcementType === 'Event') {
            if (eventStartTimeUTC) {
              return eventStartTimeUTC && schema.min(eventStartTimeUTC, t('YupEventEndDateDateShouldBeLaterThanEventStartDate')).required(t('YupGeneralMessage'));
            }

            return Yup.date()
              .nullable()
              .required(t('YupGeneralMessage'));
          }

          return Yup.date().nullable();
        }),
      reminderDueDateUTC: Yup
        .date()
        .nullable()
        .when('announcementType', (val) => {
          if (val === AnnouncementType.Reminder) {
            return Yup.date().nullable().required(t('YupGeneralMessage'));
          }

          return Yup.date().nullable();
        }),
      announcementType: Yup
        .string()
        .oneOf(Object.keys(AnnouncementType).filter((x) => Number.isNaN(Number(x))) as string[])
        .required(t('YupGeneralMessage')),
      responseRequired: Yup
        .boolean(),
      pushNotification: Yup
        .boolean(),
      pinAnnouncement: Yup.boolean(),
      responseText: Yup
        .string()
        .when('responseRequired', {
          is: true,
          then: Yup.string().required(t('YupGeneralMessage'))
        })
    }, [['subjectFi', 'subjectEn'], ['subjectSv', 'subjectEn'], ['subjectSv', 'subjectFi']]);

  const getLang = (langCode: string) => {
    const content = announcement.announcementContents.find((x) => x.languageCode === langCode);
    return content || { title: '', content: '' };
  };

  const targetGroup: AnnouncementTargetGroup = announcement?.announcementScopeDtos?.length > 0
    ? AnnouncementTargetGroup.SpecificGroups
    : AnnouncementTargetGroup.Everyone;

  const mapSelectedScopesToJsonStringArray = (): string[] => {
    if (targetGroup === AnnouncementTargetGroup.Everyone) { return []; }
    const announcementSelectedScopes: string[] = announcement.announcementScopeDtos.map((annoucementScope: AnnouncementScopeDto) => (
      JSON.stringify(
        {
          ...annoucementScope,
          scopeType: AnnouncementScopeType[annoucementScope.scopeType],
          announcementScopeId: 0
        }
      )
    ));
    return announcementSelectedScopes;
  };

  return (
    <>
      {!loading && announcement && (
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 8,
            mx: 2
          }}
        >
          {announcement && (
            <Formik
              initialValues={{
                subjectFi: getLang('fi').title,
                subjectEn: getLang('en').title,
                subjectSv: getLang('sv').title,
                contentFi: getLang('fi').content,
                contentEn: getLang('en').content,
                contentSv: getLang('sv').content,
                sender: announcement.authorName,
                releaseDate: new Date(ensureUTCFormat(announcement.publishTimeUTC)),
                expirationDate: !Number.isNaN(Date.parse(announcement.expirationTimeUTC)) ? new Date(ensureUTCFormat(announcement.expirationTimeUTC)) : null,
                eventStartTimeUTC: !Number.isNaN(Date.parse(announcement.eventStartTimeUTC)) ? new Date(ensureUTCFormat(announcement.eventStartTimeUTC)) : null,
                eventEndTimeUTC: !Number.isNaN(Date.parse(announcement.eventEndTimeUTC)) ? new Date(ensureUTCFormat(announcement.eventEndTimeUTC)) : null,
                reminderDueDateUTC: !Number.isNaN(Date.parse(announcement.reminderDueDateUTC)) ? new Date(ensureUTCFormat(announcement.reminderDueDateUTC)) : null,
                announcementType: announcement.announcementType,
                importance: announcement.importance,
                pinAnnouncement: announcement.importance === AnnouncementImportance.Important,
                targetGroup,
                imageUrl: announcement.authorImageUrl,
                localImage: announcement.attachedImage,
                localImageName: announcement.attachedImageCaption,
                pushNotification: announcement.pushNotification,
                createdLinks: announcement.links as Array<AnnouncementLink>,
                responseRequired: announcement.confirmationRequired,
                responseText: announcement.confirmationLabel,
                selectedScopes: mapSelectedScopesToJsonStringArray(),
                announcementNotification: announcement.announcementNotification
              }}
              validationSchema={YupSchema}
              validateOnChange={false}
              validateOnBlur={false}
              validateOnMount={false}
              onSubmit={async (values, {
                setSubmitting
              }): Promise<void> => {
                try {
                  setSubmitting(true);
                  toggleModal();
                  setSubmitting(false);
                } catch (err) {
                  console.error(err);
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                isValidating
              }): JSX.Element => (
                <form
                  onSubmit={handleSubmit}
                >
                  <Container
                    maxWidth="xl"
                  >
                    <Grid
                      container
                      wrap="nowrap"
                      direction="column"
                      justifyContent="center"
                      alignItems="stretch"
                      sx={{ mb: 5 }}
                    >
                      <Typography
                        color="textPrimary"
                        fontSize={30}
                        fontWeight={600}
                        sx={{ mx: 2, my: 2 }}
                      >
                        {t('EditAnnouncementHeaderTitle')}
                      </Typography>
                      <CreateAnnouncementHeader
                        formValues={values}
                        submit={handleSubmit}
                        loading={isLoading || isSubmitting || isValidating}
                        disabled={isError}
                        icon={<CampaignIcon sx={{ width: '100%', height: '100%' }} />}
                        announcementId={announcement.announcementId}
                      />
                    </Grid>
                    <Box>
                      {isError && <Alert severity="error">{t('GenericErrorMessageUnableToRetrieveData')}</Alert>}
                    </Box>
                    {isLoading && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        sx={{ mt: 15 }}
                        bgcolor="background.default"
                      >
                        <CircularProgress size={65} />
                      </Box>
                    )}
                    {!isLoading && !isError && (
                      <Grid
                        container
                        columnSpacing={7}
                        rowSpacing={2}
                      >
                        <Grid
                          item
                          container
                          wrap="nowrap"
                          direction="column"
                          justifyContent="flex-start"
                          rowSpacing={3}
                          sm={12}
                          md={8}
                        >
                          <Grid
                            item
                          >
                            <CreateAnnouncementTypeSettings
                              onChangeHandler={handleChange}
                              touchedHandler={touched}
                              errorHandler={errors}
                              formValues={values}
                            />
                          </Grid>
                          <Grid item>
                            <CreateAnnouncementDatetimeInputs
                              onChangeHandler={handleChange}
                              touchedHandler={touched}
                              errorHandler={errors}
                              formValues={values}
                              setFieldValueHandler={setFieldValue}
                            />
                          </Grid>
                          <Grid
                            item
                          >
                            <CreateAnnouncementContentHolder
                              onChangeHandler={handleChange}
                              onBlurHandler={handleBlur}
                              touchedHandler={touched}
                              errorHandler={errors}
                              formValues={values}
                            />
                          </Grid>
                          <Grid item>
                            <CreateAnnouncementAddImage
                              formValues={values}
                              setFieldValue={setFieldValue}
                            />
                          </Grid>
                          <Grid item>
                            <FieldArray
                              name="createdLinks"
                              render={(arrayHelpers) => (
                                <CreateAnnouncementLinks
                                  formValues={values}
                                  arrayHelpers={arrayHelpers}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item>
                            <CreateAnnouncementConfirmation
                              onChangeHandler={handleChange}
                              touchedHandler={touched}
                              errorHandler={errors}
                              formValues={values}
                              onBlurHandler={handleBlur}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs
                        >
                          <EditAnnouncementRightPanel
                            onChangeHandler={handleChange}
                            touchedHandler={touched}
                            errorHandler={errors}
                            formValues={values}
                            setFieldValueHandler={setFieldValue}
                            scopes={scopes}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Container>
                  <CommonModal
                    open={open}
                    onClose={(e: object, reason: string) => {
                      switch (reason) {
                        case 'backdropClick':
                          break;
                        default:
                          toggleModal();
                          break;
                      }
                    }}
                  >
                    <EditAnnouncementModal
                      closeModal={toggleModal}
                      formValues={values}
                      announcement={announcement}
                      announcementId={announcement.announcementId}
                      noImg={announcement.attachedImage === null}
                    />
                  </CommonModal>
                </form>
              )}
            </Formik>
          )}
        </Box>
      )}
      <Helmet>
        <title>{t('EditAnnouncementPageHelmet')}</title>
      </Helmet>

    </>
  );
});

export default CreateAnnouncement;
