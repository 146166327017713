import { FC, useState } from 'react';
import { Box, Grid, Tab } from '@mui/material';
import PropTypes from 'prop-types';
import { CreateAnnouncementBasePropsWithBlur } from './props/CreateAnnouncementBaseProps';
import { useTranslation } from 'react-i18next';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CreateAnnouncementContent from './CreateAnnouncementContent';

const CreateAnnouncementContentHolder: FC<CreateAnnouncementBasePropsWithBlur> = (props) => {
  const { onChangeHandler, touchedHandler, errorHandler, formValues, onBlurHandler } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
      >
        <TabContext
          value={value}
        >
          <TabList
            onChange={handleChange}
          >
            <Tab
              label={t('LanguageFinnish')}
              value="1"
            />
            <Tab
              label={t('LanguageEnglish')}
              value="2"
            />
            <Tab
              label={t('LanguageSwedish')}
              value="3"
            />
          </TabList>
          <Box sx={{ border: 1, borderRadius: 1, borderTopLeftRadius: 0, borderColor: 'divider' }}>
            <TabPanel value="1">
              <CreateAnnouncementContent
                onChangeHandler={onChangeHandler}
                onBlurHandler={onBlurHandler}
                touchedHandler={touchedHandler}
                errorHandler={errorHandler}
                formValues={formValues}
                language="fi"
              />
            </TabPanel>
            <TabPanel value="2">
              <CreateAnnouncementContent
                onChangeHandler={onChangeHandler}
                onBlurHandler={onBlurHandler}
                touchedHandler={touchedHandler}
                errorHandler={errorHandler}
                formValues={formValues}
                language="en"
              />
            </TabPanel>
            <TabPanel value="3">
              <CreateAnnouncementContent
                onChangeHandler={onChangeHandler}
                onBlurHandler={onBlurHandler}
                touchedHandler={touchedHandler}
                errorHandler={errorHandler}
                formValues={formValues}
                language="sv"
              />
            </TabPanel>
          </Box>
        </TabContext>
      </Grid>
    </Grid>
  );
};

CreateAnnouncementContentHolder.propTypes = {
  // @ts-ignore
  onChangeHandler: PropTypes.any.isRequired,
  onBlurHandler: PropTypes.any.isRequired,
  touchedHandler: PropTypes.any.isRequired,
  errorHandler: PropTypes.any.isRequired,
  formValues: PropTypes.any.isRequired
};

export default CreateAnnouncementContentHolder;
