import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { List, ListSubheader } from '@mui/material';
import type { ListProps } from '@mui/material';
import NavItem from './NavItem';

interface Item {
  path?: string;
  icon?: ReactNode;
  info?: ReactNode;
  amount?: ReactNode;
  children?: Item[];
  title: string;
  customeElement?: JSX.Element;
  showMessageActions?: boolean;
}

interface NavSectionProps extends ListProps {
  items: Item[];
  pathname: string;
  title: string;
  hideTitle: boolean;
}

const renderNavItems = ({
  depth = 0,
  items,
  pathname,
  hideTitle
}: {
  items: Item[];
  pathname: string;
  hideTitle: boolean;
  depth?: number;
}): JSX.Element => (
  <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        pathname,
        depth,
        hideTitle
      }),
      []
    )}
  </List>
);

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth,
  hideTitle
}: {
  acc: JSX.Element[];
  pathname: string;
  item: Item;
  depth: number;
  hideTitle: boolean;
}): Array<JSX.Element> => {
  const key = `${item.path}-${depth}`;
  const exactMatch = item.path ? !!matchPath({
    path: item.path,
    end: true
  }, pathname) : false;

  if (item.children) {
    const partialMatch = item.path ? !!matchPath({
      path: item.path,
      end: false
    }, pathname) : false;

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
        amount={item.amount}
        hideTitle={hideTitle}
        showMessageActions={item.showMessageActions}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname,
          hideTitle
        })}
      </NavItem>
    );
  } else {

    acc.push(
      !item.customeElement ?
        <NavItem
          active={exactMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          path={item.path}
          title={item.title}
          amount={item.amount}
          hideTitle={hideTitle}
        /> : hideTitle ? null : item.customeElement
    );
  }

  return acc;
};

const NavSection: FC<NavSectionProps> = (props) => {
  const {
    items,
    pathname,
    title,
    hideTitle,
    ...other
  } = props;

  return (
    <List
      subheader={(
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'text.primary',
            fontSize: '0.75rem',
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: 'uppercase'
          }}
        >
          {title}
        </ListSubheader>
      )}
      {...other}
    >
      {renderNavItems({
        items,
        pathname,
        hideTitle
      })}
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string
};

export default NavSection;
