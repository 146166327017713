export interface AnnouncementSenderOption {
  type: AnnouncementSenderType;
  name: string;
  senderId: number;
  customerId: number;
}

export enum AnnouncementSenderType {
  User,
  Company,
  Customer,
}
