import { LANGUAGES } from 'src/constants';
import { DropDownOption } from 'src/types/dropDownOption';
import { enGB, fi, sv } from 'date-fns/locale';

export const isAvailableLanguage = (languageCode: string): boolean => {
  if (!languageCode) { return false; }

  return LANGUAGES.filter((lang: DropDownOption) => lang.value === languageCode).length > 0;
};

export const getLocale = (languageCode: string) => {
  if (languageCode === 'en') {
    return enGB;
  } else if (languageCode === 'sv') {
    return sv;
  } else {
    return fi;
  }
}; 
