import { FC, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import X from 'src/icons/X';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { displayLocalDateTime } from 'src/utils/dateUtils';

interface ReleasesDialogProps {
  openDialog: boolean
  closeDialog: () => void
  releaseData: any
}

const ReleasesDialog: FC<ReleasesDialogProps> = (props) => {
  const { openDialog, closeDialog, releaseData } = props;
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    closeDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <Box
        bgcolor="background.default"
      >
        <Grid
          container
          sx={{
            p: 3
          }}
        >
          <Grid
            item
            xs={11}
            container
          >
            <Typography
              color="textPrimary"
              variant="h4"
            >
              {releaseData.results[activeStep]?.title}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {`\u00A0\u00A0${displayLocalDateTime(releaseData.results[activeStep]?.releaseDateUTC, true)}`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            container
            justifyContent="flex-end"
          >
            <IconButton
              aria-label="close"
              onClick={() => { closeDialog(); }}
              size="large"
            >
              <X />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            container
          >
            <Typography
              color="textPrimary"
              style={{
                whiteSpace: 'pre-line'
              }}
            >
              {releaseData.results[activeStep]?.description}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 3
            }}
            container
          >
            <Grid
              item
              xs={6}
            >
              <Button
                size="large"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
                {t('ReleasesBack')}
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              container
              justifyContent="flex-end"
            >
              <Button
                size="large"
                onClick={handleNext}
                disabled={activeStep === releaseData.results.length - 1}
              >
                {t('ReleasesNext')}
                <KeyboardArrowRight />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ReleasesDialog;
