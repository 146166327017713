import { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Scrollbar from '../../Scrollbar';
import { DashboardPaymentTransactionsQueryParameters, TransactionChangeDto, TransactionChangeType, TransactionDto } from '../../../types/apiSchemas';
import { useTranslation } from 'react-i18next';
import BadgeIcon from '@mui/icons-material/Badge';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SalaryChangeIcon from '../../../icons/SalaryChange';
import EuroOverlinedIcon from '../../../icons/EuroOverlined';
import HelpIcon from '@mui/icons-material/Help';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import { nanoid } from 'nanoid';
import ChevronRightButton from 'src/components/ChevronRightButton';
import { formatNumber } from 'src/utils/numberUtils';

interface PaymentTransactionProps {
  transactions: TransactionDto[];
  queryParams: DashboardPaymentTransactionsQueryParameters;
  clicked: () => void;
}

const PaymentChangedTransactionCard: FC<PaymentTransactionProps> = (props) => {
  const { transactions, queryParams, clicked, ...other } = props;
  const { t } = useTranslation();
  const states = {
    NEW_EMPLOYMENT:
      (
        <Tooltip
          title={t('NewEmployment')}
          key="NEW_EMPLOYMENT"
        >
          <BadgeIcon sx={{ marginX: 1 }} />
        </Tooltip>
      ),
    ENDING_EMPLOYMENT:
      (
        <Tooltip
          title={t('EndingEmployment')}
          key="ENDING_EMPLOYMENT"
        >
          <CancelPresentationIcon sx={{ marginX: 1 }} />
        </Tooltip>
      ),
    SALARY_CHANGE:
      (
        <Tooltip
          title={t('SalaryChange')}
          key="SALARY_CHANGE"
        >
          <SalaryChangeIcon sx={{ marginX: 1 }} />
        </Tooltip>
      ),
    EXCEPTION:
      (
        <Tooltip
          title={t('Exception')}
          key="EXCEPTION"
        >
          <HelpIcon sx={{ marginX: 1 }} />
        </Tooltip>
      ),
    PAYLESS_ABSENCES:
      (
        <Tooltip
          title={t('PaylessAbsences')}
          key="PAYLESS_ABSENCES"
        >
          <EuroOverlinedIcon sx={{ marginX: 1 }} />
        </Tooltip>
      ),
    WORKTIME_CHANGE:
      (
        <Tooltip
          title={t('WorktimeChange')}
          key="WORKTIME_CHANGE"
        >
          <AccessTimeIcon sx={{ marginX: 1 }} />
        </Tooltip>
      )
  };

  const displayTransactionChangeDetails = (transactionChange: TransactionChangeDto) => {
    switch (transactionChange.transactionChangeType) {
      case TransactionChangeType.ENDING_EMPLOYMENT:
        return transactionChange.transactionChangeDetails.map((tcd) => (
          <Tooltip
            title={`${t('EndingEmployment')}`}
            key={tcd.transactionChangeDetailId}
          >
            <Typography style={{ display: 'flex', alignItems: 'center' }}>
              <CancelPresentationIcon sx={{ marginX: 1 }} />
              {tcd.newValue}
            </Typography>
          </Tooltip>
        ));
      case TransactionChangeType.EXCEPTION:
        return transactionChange.transactionChangeDetails.map((tcd) => (
          <Tooltip
            title={`${tcd.newValue ? t('ExceptionNewRow') : t('ExceptionMissingRow')} (${tcd.detailContent})`}
            key={tcd.transactionChangeDetailId}
          >
            <HelpIcon sx={{ marginX: 1 }} />
          </Tooltip>
        ));
      case TransactionChangeType.SALARY_CHANGE:
        return transactionChange.transactionChangeDetails.map((tcd) => (
          <Tooltip
            title={`${t('SalaryChange')} ${tcd.oldValue}€ → ${tcd.newValue}€ (${tcd.detailContent})`}
            key={tcd.transactionChangeDetailId}
          >
            <SalaryChangeIcon sx={{ marginX: 1 }} />
          </Tooltip>
        ));
      case TransactionChangeType.WORKTIME_CHANGE:
        return transactionChange.transactionChangeDetails.map((tcd) => (
          <Tooltip
            title={`${t('WorktimeChange')}: ${tcd.detailContent} ${t('WorktimeChangeChanged')} ${tcd.oldValue} → ${tcd.newValue}`}
            key={tcd.transactionChangeDetailId}
          >
            <AccessTimeIcon sx={{ marginX: 1 }} />
          </Tooltip>
        ));
      default:
        return null;
    }
  };

  const filteredChanged = transactions.filter((transaction) => transaction.changes?.length > 0);
  if (filteredChanged.length === 0) {
    return null;
  }

  return (
    <>
      <Card
        {...other}
      >
        <CardHeader
          title={`${t('PaymentTransactionsChangedTitle')}`}
        />
        <Divider />
        <Scrollbar>
          <Table id="transactionTable">
            <TableHead>
              <TableRow key={nanoid()}>
                <TableCell sx={{ width: '20%' }}>
                  <Typography variant="tableHead">{t('TransactionRecipientTitle')}</Typography>
                </TableCell>
                <TableCell sx={{ width: '20%' }}>
                  <Typography variant="tableHead">{t('TransactionDescriptionTitle')}</Typography>
                </TableCell>
                <TableCell sx={{ width: '20%' }}>
                  <Typography variant="tableHead">{t('TransactionNetAmountTitle')}</Typography>
                </TableCell>
                <TableCell sx={{ width: '20%' }}>
                  <Typography variant="tableHead">{t('TransactionsChangesTitle')}</Typography>
                </TableCell>
                <TableCell sx={{ width: '20%' }} />
                <TableCell sx={{ width: '20%' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredChanged.map((filteredTransaction) => (
                <TableRow key={filteredTransaction.transactionId}>
                  <TableCell sx={{ width: '25%' }}>
                    <Typography>{filteredTransaction.recipient}</Typography>
                  </TableCell>
                  <TableCell sx={{ width: '25%' }}>
                    <Typography>{filteredTransaction.description}</Typography>
                  </TableCell>
                  <TableCell sx={{ width: '25%' }}>
                    <Typography noWrap>{formatNumber(filteredTransaction.netAmount)}</Typography>
                  </TableCell>
                  <TableCell sx={{ width: '25%' }}>
                    {filteredTransaction.changes && filteredTransaction.changes.map((change) => {
                      const transactionChange = filteredTransaction.changeDetails.find((cd) => cd.transactionChangeType === TransactionChangeType[change]);
                      return transactionChange?.transactionChangeDetails.length > 0 ? displayTransactionChangeDetails(transactionChange) : states[change];
                    })}
                  </TableCell>
                  <TableCell>
                    {filteredTransaction.flagged === true && (
                      <Tooltip
                        key="comment"
                        title={filteredTransaction.comments}
                      >
                        <EmojiFlagsIcon sx={{ color: 'error.main' }} />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {filteredTransaction.payslipId && filteredTransaction.changes && (
                      <ChevronRightButton
                        to={`/dashboard/payments/${filteredTransaction.paymentId}/${filteredTransaction.payslipId}/${filteredTransaction.changes}`}
                        state={{ queryParams }}
                        onClick={clicked}
                        size={{ height: 25, width: 25 }}
                      />
                    )}
                    {!filteredTransaction.payslipId && filteredTransaction.changes && (
                      <ChevronRightButton
                        to={`/dashboard/payments/${filteredTransaction.paymentId}/${filteredTransaction.transactionId}/payslip/${filteredTransaction.changes}`}
                        state={{ queryParams }}
                        onClick={clicked}
                        size={{ height: 25, width: 25 }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </Scrollbar>
      </Card>
      <Divider
        variant="middle"
        sx={{
          marginTop: 3,
          visibility: 'hidden'
        }}
      />
    </>
  );
};

PaymentChangedTransactionCard.propTypes = {
  transactions: PropTypes.array.isRequired
};

export default PaymentChangedTransactionCard;
