import createSvgIcon from '@mui/material/utils/createSvgIcon';

const LandingPage = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    fill="currentColor"
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M16 2V4H12V2H16ZM6 2V8H2V2H6ZM16 10V16H12V10H16ZM6 14V16H2V14H6ZM18 0H10V6H18V0ZM8 0H0V10H8V0ZM18 8H10V18H18V8ZM8 12H0V18H8V12Z"
    />
  </svg>,
  'LandingPage'
);

export default LandingPage;
