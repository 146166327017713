// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, makeAutoObservable } from 'mobx';
import { baseApi } from 'src/API/baseApi';
import { LANGUAGES, StorageKeys, THEMES } from 'src/constants';
import { LoginInfoDto } from 'src/types/apiSchemas';
import { SiteUser } from 'src/types/siteUser';
import { isAvailableLanguage } from 'src/utils/languageUtils';
import { autoSaveToStorage } from './autoSaveToStorage';
import { resolveTheme } from '../../utils/themeUtils';
import { uiTheme } from 'src/utils/environmentUtils';

export interface SiteSettingsEntity {
  compact?: boolean;
  direction?: 'ltr' | 'rtl';
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
  language?: string;
}

export class SiteSettingsStore {
  constructor() {
    makeAutoObservable(this);
    autoSaveToStorage(this, StorageKeys.settingsStoreKey);
  }

  public siteSettings: SiteSettingsEntity = {
    compact: true,
    direction: 'ltr',
    responsiveFontSizes: true,
    roundedCorners: true,
    theme: resolveTheme(),
    language: LANGUAGES.find((x) => x.label === 'Finnish').value
  };

  private static instance = null;

  public static getInstance(): SiteSettingsStore {
    if (SiteSettingsStore.instance === null) {
      SiteSettingsStore.instance = new SiteSettingsStore();
    }
    return SiteSettingsStore.instance;
  }

  @action.bound
  public get(): SiteSettingsEntity {
    return this.siteSettings;
  }

  @action.bound
  public set(settings: SiteSettingsEntity): void {
    this.siteSettings = settings;
  }

  @action.bound
  public async save(user: SiteUser, settings: SiteSettingsEntity): Promise<void> {
    this.set(settings);
    await this.storeLanguageAndTheme(user, settings);
  }

  private storeLanguageAndTheme = async (user: SiteUser, settings: SiteSettingsEntity): Promise<void> => {
    const isValidLanguage = isAvailableLanguage(settings.language);
    if (!isValidLanguage) {
      console.warn(`${settings.language} is not an available language`);
    }

    try {
      await baseApi.updateCurrentUserLanguageAndTheme({ ...user, languageCode: isValidLanguage ? settings.language : user.languageCode, siteTheme: uiTheme === THEMES.ACCOUNTOR ? null : settings.theme } as LoginInfoDto);
    } catch (err) {
      console.error(`Failed to save user language to DB ${err}`);
      throw new Error(err);
    }
  };
}
