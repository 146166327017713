/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Card, CardMedia, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { CreateAnnouncementBasePropsWithBlur } from './props/CreateAnnouncementBaseProps';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTranslation } from 'react-i18next';
import { WhiteOutlineButton } from 'src/components/WhiteOutlineButton';

interface CreateAnnouncementAddImageProps {
  formValues: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const CreateAnnouncementAddImage : FC<CreateAnnouncementAddImageProps> = (props) => {
  const { formValues, setFieldValue } = props;
  const { t } = useTranslation();

  return (
    <Grid
      container
      rowSpacing={2}
    >
      <Grid
        item
        xs={12}
        container
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="formFieldHeader"
            color="textPrimary"
            sx={{ ml: 1, mb: 1 }}
          >
            {t('CreateAnnouncementAddImage')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Button
            variant="contained"
            component="label"
          >
            {t('CreateAnnouncementImageUploadButtonTitle')}
            <input
              name="image"
              accept="image/png, image/jpeg"
              id="contained-button-file"
              type="file"
              hidden
              onChange={(e) => {
                const fileReader = new FileReader();
                fileReader.onload = () => {
                  if (fileReader.readyState === 2) {
                    setFieldValue('localImage', fileReader.result);
                  }
                };
                fileReader.readAsDataURL(e.target.files[0]);
                setFieldValue('localImageName', e.target.files[0].name);
              }}
            />
          </Button>
        </Grid>
      </Grid>
      {formValues?.localImage && (
      <Grid
        item
        container
        sx={{ width: '150px' }}
      >
        <Grid item>
          <Card>
            <CardMedia
              component="img"
              image={formValues?.localImage}
              alt="Local image"
            />
          </Card>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            container
            wrap="nowrap"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="body1"
              color="textSecondary"
              noWrap
            >
              {formValues.localImageName}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{ mt: 1 }}
          >
            <WhiteOutlineButton
              variant="outlined"
              onClick={() => setFieldValue('localImage', null)}
              startIcon={<DeleteForeverIcon />}
            >
              {t('CreateAnnouncementImageDeleteButtonTitle')}
            </WhiteOutlineButton>
          </Grid>
        </Grid>
      </Grid>
      )}
    </Grid>
  );
};

export default CreateAnnouncementAddImage;
