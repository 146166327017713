import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import { Navigate } from 'react-router-dom';
import PaymentSummary from './pages/dashboard/PaymentSummary';
import Payslip from './pages/dashboard/Payslip';
import Approvals from './pages/dashboard/Approvals';
import HolidayBonusChange from './pages/dashboard/HolidayBonusChange';
import AnnouncementDetails from './pages/dashboard/AnnouncementDetails';
import EditAnnouncement from './pages/dashboard/EditAnnouncement';
import ApprovalsAbsenceCard from './pages/dashboard/ApprovalsAbsenceCard';
import { manageSubstitutionsPath } from './constants';
import CreateAbsence from './pages/dashboard/CreateAbsence';
import SingleAbsence from './pages/dashboard/SingleAbsence';
import AccessRequestList from './pages/dashboard/AccessRequestsList';
import LeaveTeamPage from './pages/dashboard/LeaveTeamPage';
import { QuickSearch } from './pages/dashboard/Messages';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Login page
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

// Dashboard pages
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Announcements = Loadable(lazy(() => import('./pages/dashboard/Announcements')));
const CreateAnnouncement = Loadable(lazy(() => import('./pages/dashboard/CreateAnnouncement')));
const Payments = Loadable(lazy(() => import('./pages/dashboard/Payments')));
const LandingPage = Loadable(lazy(() => import('./pages/dashboard/LandingPage')));
const ReportPage = Loadable(lazy(() => import('./pages/dashboard/ReportPage')));
const HolidayBonusChangePeriodForm = Loadable(lazy(() => import('./pages/dashboard/HolidayBonusChangePeriodForm')));
const AbsencesPage = Loadable(lazy(() => import('./pages/dashboard/Absences')));
const ManageSubstitutionsPage = Loadable(lazy(() => import('./pages/dashboard/ManageSubstitutions')));
const AttachmentsPage = Loadable(lazy(() => import('./pages/dashboard/Attachments')));
const MessagesPage = Loadable(lazy(() => import('./pages/dashboard/Messages')));
const MessageThreadPage = Loadable(lazy(() => import('./pages/dashboard/MessageThread')));
const UserPayslipsPage = Loadable(lazy(() => import('./pages/dashboard/UserPayslips')));
const UserPayslipPage = Loadable(lazy(() => import('./pages/dashboard/UserPayslip')));
const AccessRequestsPage = Loadable(lazy(() => import('./pages/dashboard/AccessRequestPage')));
const NotificationSettingsPage = Loadable(lazy(() => import('./pages/dashboard/NotificationSettings')));
const UserList = Loadable(lazy(() => import('./pages/dashboard/UserList')));
const UserDetails = Loadable(lazy(() => import('./pages/dashboard/UserDetails')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes: RouteObject[] = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: (
          <Navigate
            to="/dashboard/landingpage/"
            replace
          />
        )
      },
      {
        path: 'announcements',
        children: [
          {
            index: true,
            element: <Announcements />
          },
          {
            path: 'create',
            element: <CreateAnnouncement />
          },
          {
            path: ':announcementId',
            children: [
              {
                index: true,
                element: <AnnouncementDetails />
              },
              {
                path: 'edit',
                element: <EditAnnouncement />
              }
            ]
          }
        ]
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'landingpage',
        element: <LandingPage />
      },
      {

        path: manageSubstitutionsPath,
        element: <ManageSubstitutionsPage />
      },
      {

        path: 'accessrequests',
        element: <AccessRequestsPage />
      },
      {

        path: 'leaveteam',
        element: <LeaveTeamPage />
      },
      {
        path: 'payments',
        children: [
          {
            index: true,
            element: <Payments />
          },
          {
            path: ':paymentId',
            children: [
              {
                index: true,
                element: <PaymentSummary />
              },
              {
                path: ':payslipId',
                children: [
                  {
                    index: true,
                    element: <Payslip />
                  },
                  {
                    path: ':changes',
                    element: <Payslip />
                  }
                ]
              },
              {
                path: ':transactionId/payslip',
                children: [
                  {
                    index: true,
                    element: <Payslip isTransactionPayslip />
                  },
                  {
                    path: ':changes',
                    element: <Payslip isTransactionPayslip />
                  }
                ]
              },
              {
                path: 'payslip/:payslipId',
                element: <Payslip disablePrevNextNavigation />
              }
            ],
          },
        ]
      },
      {
        path: 'approvals',
        children: [
          {
            index: true,
            element: <Approvals />
          },
          {
            path: ':approvalType',
            children: [
              {
                path: ':approvalId',
                element: <ApprovalsAbsenceCard />
              },
            ]
          },
        ]
      },
      {
        path: 'reports/workspace/:workspaceId/report/:reportId/:reportName',
        children: [
          {
            index: true,
            element: <ReportPage />
          },
        ]
      },
      {
        path: 'demo/workspace/:workspaceId/report/:reportId/:reportName',
        children: [
          {
            index: true,
            element: <ReportPage isDemoReport />
          },
        ]
      },
      {
        path: 'holidaybonuschange',
        children: [
          {
            children: [
              { index: true, element: <HolidayBonusChange /> },
              {
                path: 'create',
                element: <HolidayBonusChangePeriodForm />
              },
              {
                path: 'edit/:holidayBonusChangePeriodId',
                element: <HolidayBonusChangePeriodForm />
              }
            ]
          }
        ]
      },
      {
        path: 'absences',
        children: [{
          index: true,
          element: <AbsencesPage />
        }, {
          path: 'create',
          element: <CreateAbsence />
        }, {
          path: ':absenceId',
          element: <SingleAbsence />
        }]
      },
      {
        path: 'attachments',
        element: <AttachmentsPage />
      },
      {
        path: 'messages',
        children: [{
          index: true,
          element: <MessagesPage quickSearch={QuickSearch.AllMessages}/>
        }, {
          path: ':messageId',
          element: <MessageThreadPage />
        },
        {
          path: 'allunsolved',
          element: <MessagesPage quickSearch={QuickSearch.AllUnsolved} />
        },
        {
          path: 'myunsolved',
          element: <MessagesPage quickSearch={QuickSearch.MyUnsolved} />
        },
        {
          path: 'unassigned',
          element: <MessagesPage quickSearch={QuickSearch.NotAssigned} />
        },
        {
          path: 'allsolved',
          element: <MessagesPage quickSearch={QuickSearch.AllSolved} />
        }, 
        {
          path: 'views/:messageViewName',
          element: <MessagesPage />
        }]
      },
      {
        path: 'payslips',
        children: [{
          index: true,
          element: <UserPayslipsPage />
        }, {
          path: ':payslipId',
          element: <UserPayslipPage />
        }]
      },
      {
        path: 'accessRequestList',
        element: <AccessRequestList />
      },
      {
        path: 'notificationSettings',
        element: <NotificationSettingsPage />
      },
      {
        path: 'users',
        children: [{
          index: true,
          element: <UserList />
        }, {
          path: ':userId',
          element: <UserDetails />
        }]
      },
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
