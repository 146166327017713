import { TFunction } from 'i18next';
import { InternalRole, UserRole } from 'src/types/apiSchemas';

export const GetUserRoleTranslation = (t: TFunction<'translation'>, role: UserRole): string => {
  switch (role) {
    case 'Reviewer':
      return t('UserRoleReviewer');
    case 'Approver':
      return t('UserRoleApprover');
    case 'HR':
      return t('UserRoleHR');
    case 'MainUser':
      return t('UserRoleMainUser');
    case 'Finance':
      return t('UserRoleFinance');
    case 'Technical':
      return t('UserRoleTechnical');
    default:
      return role;
  }
};

export const GetInternalRoleTranslation = (t: TFunction<'translation'>, internalRole: InternalRole): string => {
  switch (internalRole) {
    case 'Payroll':
      return t('InternalRolePayroll');
    case 'IntegrataHR':
      return t('InternalRoleIntegrataHR');
    case 'MepcoAdmin':
      return t('InternalRoleMepcoAdmin');
    case 'MepcoWebAdmin':
      return t('InternalRoleMepcoWebAdmin');
    case 'QuinyxAdmin':
      return t('InternalRoleQuinyxAdmin');
    case 'TiimaAdmin':
      return t('InternalRoleTiimaAdmin');
    case 'NeptonAdmin':
      return t('InternalRoleNeptonAdmin');
    case 'M2Admin':
      return t('InternalRoleM2Admin');
    case 'AcubizAdmin':
      return t('InternalRoleAcubizAdmin');
    case 'SympaAdmin':
      return t('InternalRoleSympaAdmin');
    case 'AccountManager':
      return t('InternalRoleAccountManager');
    case 'IT':
      return t('InternalRoleIT');
    case 'TravelExpenses':
      return t('InternalRoleTravelExpenses');
    case 'HumHumSupport':
      return t('InternalRoleHumHumSupport');
    case 'Billing':
      return t('InternalRoleBilling');
    case 'Analytics':
      return t('InternalRoleAnalytics');
    case 'ProjectManager':
      return t('InternalRoleProjectManager');
    case 'MepcoConsultant':
      return t('InternalRoleMepcoConsultant');
    default:
      return internalRole;
  }
};

const roleIdToUserRole = {
  0: UserRole.HR,
  1: UserRole.Approver,
  2: UserRole.Reviewer,
  3: UserRole.MainUser,
  4: UserRole.Finance,
  5: UserRole.Technical,
};

const internalRoleIdToInternalRole = {
  0: InternalRole.Payroll,
  1: InternalRole.IntegrataHR,
  2: InternalRole.MepcoAdmin,
  3: InternalRole.MepcoWebAdmin,
  4: InternalRole.QuinyxAdmin,
  5: InternalRole.TiimaAdmin,
  6: InternalRole.NeptonAdmin,
  7: InternalRole.M2Admin,
  8: InternalRole.AcubizAdmin,
  9: InternalRole.SympaAdmin,
  10: InternalRole.AccountManager,
  11: InternalRole.IT,
  12: InternalRole.TravelExpenses,
  13: InternalRole.HumHumSupport,
  14: InternalRole.Billing,
  15: InternalRole.Analytics,
  16: InternalRole.ProjectManager,
  17: InternalRole.MepcoConsultant
};

export const GetUserRoleTranslationFromId = (t: TFunction<'translation'>, roleId: number): string => GetUserRoleTranslation(t, roleIdToUserRole[roleId] || null);

export const GetInternalRoleTranslationFromId = (t: TFunction<'translation'>, internalRole: number): string => GetInternalRoleTranslation(t, internalRoleIdToInternalRole[internalRole] || null);

const customerRelatedInternalRoles = [InternalRole.Payroll, InternalRole.IntegrataHR, InternalRole.TravelExpenses, InternalRole.ProjectManager, InternalRole.MepcoConsultant];
export const IsCustomerRelatedInternalRole = (role: InternalRole) => (customerRelatedInternalRoles.includes(role));
