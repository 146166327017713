import { CloseOutlined } from '@mui/icons-material';
import { Card, CircularProgress, IconButton, InputAdornment, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography, styled } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import ChevronRightButton from 'src/components/ChevronRightButton';
import { PagedResult, PaymentPayslipDto } from 'src/types/apiSchemas';
import { formatNumber } from 'src/utils/numberUtils';
import SearchIcon from '@mui/icons-material/Search';
import { useDebounce } from 'src/hooks';
import { useTranslation } from 'react-i18next';
import stateStore from 'src/store/mobx/stateStore';
import { observer } from 'mobx-react';

const CompactTable = styled(Table)`
& th, & td {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
}

& th:first-of-type, & td:first-of-type {
  padding-left: 16px;
}

& th:last-child, & td:last-child {
  padding-right: 16px;
}
`;

interface PaymentPayslipsProps {
  payslips: PagedResult<PaymentPayslipDto>;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, page: number, searchString: string) => void;
  onSearchStringChange: (searchString: string) => void;
  isLoading: boolean;
  paymentId: string;
}

const totalsPayPeriodLabels = ['Kertymä palkkakaudelta', 'Totals pay period', 'Totals period', 'Införtjänat aktuell period'];
const earningsLabels = ['Ennakonpid. al. tulo', 'Ennakonpid.al. tulo', 'Ennakonpid. al. tul', 'Taxable income', 'Skattepliktig inkomst', 'Skattepliktig inkom'];
const benefitsLabels = ['Luontoisedut', 'Ennakonpid.alainen luontoisetu', 'Taxed fringe benefit', 'Fringe benefits', 'Benefits', 'Naturaförmån', 'Besk.naturaförmån'];
const taxfreePaymentsLabels = ['Verottomat korv.', 'Tax-free compens.', 'Tax-free compensations', 'Tax-free compensati', 'Tax-free comp.', 'Skattefria ersättningar', 'Skattefria ersättni'];
const deductionsLabels = ['Vähennykset', 'Deductions', 'Avdrag'];
const amountLabels = ['Rahapalkka', 'Monetary wage', 'Monetary salary', 'Cash income', 'Salary in money', 'Penninglön'];
const withHoldingLabels = ['Enn.pid.', 'Ennakonpidätys', 'Ennakonpidätys.', 'Withh.tax', 'Withholding tax', 'Förskottsinnehållning'];

const PaymentPayslips: FC<PaymentPayslipsProps> = observer(({ payslips, onPageChange, onSearchStringChange, isLoading, paymentId }) => {
  const { payslipSearch: { [paymentId]: payslipSearch = '' }, setPayslipSearch } = stateStore;
  const debouncedSearchString = useDebounce(payslipSearch, 500);
  const { t } = useTranslation();

  useEffect(() => {
    onSearchStringChange(debouncedSearchString);
  }, [debouncedSearchString]);

  const isPayslipV2 = (json: any) => {
    return json?.Version === '2.0';
  };

  const extractDataFromJSON = (json: any) => ({
    employeeId: json?.Employee?.PersonNumber?.Value,
    amount: json?.EarningsToDate?.find((e) => totalsPayPeriodLabels.includes(e.Heading))?.Set.Line.find((l) => l.Cell.find((c) => amountLabels.includes(c.Label)))?.Cell.find((c) => amountLabels.includes(c.Label))?.Value,
    withholding: json?.EarningsToDate?.find((e) => totalsPayPeriodLabels.includes(e.Heading))?.Set.Line.find((l) => l.Cell.find((c) => withHoldingLabels.includes(c.Label)))?.Cell.find((c) => withHoldingLabels.includes(c.Label))?.Value,
    netPayment: isPayslipV2(json) ? json?.HeaderData?.NetPayment?.Value : json?.HeaderData?.NetPayment,
    earnings: json?.EarningsToDate?.find((e) => totalsPayPeriodLabels.includes(e.Heading))?.Set.Line.find((l) => l.Cell.find((c) => earningsLabels.includes(c.Label)))?.Cell.find((c) => earningsLabels.includes(c.Label))?.Value,
    benefits: json?.EarningsToDate?.find((e) => totalsPayPeriodLabels.includes(e.Heading))?.Set.Line.find((l) => l.Cell.find((c) => benefitsLabels.includes(c.Label)))?.Cell.find((c) => benefitsLabels.includes(c.Label))?.Value,
    taxfreePayments: json?.EarningsToDate?.find((e) => totalsPayPeriodLabels.includes(e.Heading))?.Set.Line.find((l) => l.Cell.find((c) => taxfreePaymentsLabels.includes(c.Label)))?.Cell.find((c) => taxfreePaymentsLabels.includes(c.Label))?.Value,
    deductions: json?.EarningsToDate?.find((e) => totalsPayPeriodLabels.includes(e.Heading))?.Set.Line.find((l) => l.Cell.find((c) => deductionsLabels.includes(c.Label)))?.Cell.find((c) => deductionsLabels.includes(c.Label))?.Value,
  });

  const jsonMap = useMemo(() => {
    const parseJSON = (json: any) => {
      try {
        return JSON.parse(json);
      } catch (err) {
        console.log(err);
      }
      return null;
    };
    return new Map(payslips?.results?.map((p): [number, any] => [p.payslipId, extractDataFromJSON(parseJSON(p.payslipJsonFile))]));
  }, [payslips]);

  return (
    <>
      <TextField
        value={payslipSearch}
        sx={{ maxWidth: 500, marginBottom: 3 }}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: payslipSearch.length > 0 ? (
            <InputAdornment position="end">
              <IconButton onClick={() => {
                setPayslipSearch('', paymentId);
              }}
              >
                <CloseOutlined />
              </IconButton>
            </InputAdornment>
          ) : null
        }}
        onChange={(e) => {
          setPayslipSearch(e.target.value, paymentId);
        }}
        placeholder={t('PaymentPayslipsSearchPlaceholder')}
        autoComplete="off"
      />
      {isLoading && <CircularProgress style={{ display: 'block', margin: 'auto' }} />}
      {!isLoading && (
        <Card
          style={{ overflowX: 'auto' }}
        >
          <CompactTable>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div><Typography variant="tableHead">{t('PaymentPayslipsRecipient')}</Typography></div>
                  <div>{t('PaymentPayslipsEmployeeId')}</div>
                </TableCell>
                <TableCell><Typography variant="tableHead">{t('PaymentPayslipsAmount')}</Typography></TableCell>
                <TableCell><Typography variant="tableHead">{t('PaymentPayslipsBenefits')}</Typography></TableCell>
                <TableCell><Typography variant="tableHead">{t('PaymentPayslipsTaxableIncome')}</Typography></TableCell>
                <TableCell><Typography variant="tableHead">{t('PaymentPayslipsWithholdingTax')}</Typography></TableCell>
                <TableCell><Typography variant="tableHead">{t('PaymentPayslipsTaxfreePayments')}</Typography></TableCell>
                <TableCell><Typography variant="tableHead">{t('PaymentPayslipsDeductions')}</Typography></TableCell>
                <TableCell><Typography variant="tableHead">{t('PaymentPayslipsNetPayment')}</Typography></TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {payslips?.results.map((p) => {
                const json = jsonMap.get(p.payslipId);
                return (
                  <TableRow key={p.payslipId}>
                    <TableCell>
                      <Typography whiteSpace="nowrap">{p.recipient}</Typography>
                      <div>{json.employeeId}</div>
                    </TableCell>
                    <TableCell><Typography whiteSpace="nowrap">{formatNumber(json.amount)}</Typography></TableCell>
                    <TableCell><Typography whiteSpace="nowrap">{formatNumber(json.benefits)}</Typography></TableCell>
                    <TableCell><Typography whiteSpace="nowrap">{formatNumber(json.earnings)}</Typography></TableCell>
                    <TableCell><Typography whiteSpace="nowrap">{formatNumber(json.withholding)}</Typography></TableCell>
                    <TableCell><Typography whiteSpace="nowrap">{formatNumber(json.taxfreePayments)}</Typography></TableCell>
                    <TableCell><Typography whiteSpace="nowrap">{formatNumber(json.deductions)}</Typography></TableCell>
                    <TableCell><Typography whiteSpace="nowrap">{formatNumber(json.netPayment)}</Typography></TableCell>
                    <TableCell>
                      <ChevronRightButton
                        to={`/dashboard/payments/${p.payslipCollection.paymentId}/payslip/${p.payslipId}`}
                        size={{ height: 25, width: 25 }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </CompactTable>
          {payslips &&
            <TablePagination
              component="div"
              count={payslips.totalCount}
              onPageChange={(e, page) => {
                onPageChange(e, page, payslipSearch);
              }}
              page={payslips.pageNumber - 1}
              rowsPerPage={payslips.pageSize}
              rowsPerPageOptions={[]}
            />}
        </Card>
      )}
    </>
  );
});

export default PaymentPayslips;
