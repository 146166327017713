import { Grid, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentApprovalDto, PaymentReviewDto, TransactionDtoPagedResultDto } from 'src/types/apiSchemas';
import { formatNumber } from 'src/utils/numberUtils';

interface PaymentCommentSummaryTableProps {
  approvals: PaymentApprovalDto[];
  reviews: PaymentReviewDto[];
  flaggedTransactions: TransactionDtoPagedResultDto;
  showNoCommentsLabel: boolean;
}

const PaymentCommentSummaryTable: FC<PaymentCommentSummaryTableProps> = (props) => {
  const { approvals, reviews, flaggedTransactions, showNoCommentsLabel } = props;
  const { t } = useTranslation();

  const approvalsWithComment = approvals?.filter((approval) => approval?.comment);
  const approvalsWithCommentCount = approvalsWithComment?.length ?? 0;
  const reviewsWithComment = reviews?.filter((review) => review?.comment);
  const reviewsWithCommentCount = reviewsWithComment?.length ?? 0;
  const flaggedTransactionsWithComments = flaggedTransactions?.results?.filter((flaggedTransaction) => flaggedTransaction?.comments);
  const flaggedTransactionsWithCommentsCount = flaggedTransactionsWithComments?.length ?? 0;
  const commentCount = approvalsWithCommentCount + reviewsWithCommentCount + flaggedTransactionsWithCommentsCount;

  if (commentCount === 0 && showNoCommentsLabel) {
    return (
      <>
        {t('NoCommentsYetLabel')}
      </>
    );
  }

  return (
    <Grid sx={{ pt: 1 }}>
      {approvalsWithComment?.length > 0 && (
        <Grid sx={{ pb: 1 }}>
          <Typography
            sx={{ pb: 1 }}
            variant="h5"
          >
            {t('ApproverCommentsTitle')}
          </Typography>
          <Table>
            <TableHead>
              {approvalsWithComment?.map((approval) => (
                <TableRow key={approval.paymentApprovalId}>
                  <TableCell
                    style={{ width: '50%' }}
                  >
                    {approval.userName}
                  </TableCell>
                  <TableCell
                    style={{ width: '50%' }}
                  >
                    {`- ${approval.comment}`}
                  </TableCell>
                </TableRow>
              ))}
            </TableHead>
          </Table>
        </Grid>
      )}
      {reviewsWithComment?.length > 0 && (
        <Grid sx={{ pb: 1 }}>
          <Typography
            sx={{ pb: 1 }}
            variant="h5"
          >
            {t('ReviewerCommentsTitle')}
          </Typography>
          <Table>
            <TableHead>
              {reviewsWithComment?.map((review) => (
                <TableRow key={review.paymentReviewId}>
                  <TableCell
                    style={{ width: '50%' }}
                  >
                    {review.userName}
                  </TableCell>
                  <TableCell
                    style={{ width: '50%' }}
                  >
                    {`- ${review.comment}`}
                  </TableCell>
                </TableRow>
              ))}
            </TableHead>
          </Table>
        </Grid>
      )}
      {flaggedTransactionsWithComments?.length > 0 && (
        <Grid>
          <Typography
            sx={{ pb: 1 }}
            variant="h5"
          >
            {t('TransactionsCommentsTitle')}
          </Typography>
          <Table>
            <TableHead>
              {flaggedTransactionsWithComments.map((flaggedTransaction) => (
                <TableRow key={flaggedTransaction.transactionId}>
                  <TableCell
                    style={{ width: '25%' }}
                  >
                    {flaggedTransaction?.recipient}
                  </TableCell>
                  <TableCell
                    style={{ width: '25%' }}
                  >
                    {flaggedTransaction?.description}
                  </TableCell>
                  <TableCell
                    style={{ width: '25%' }}
                  >
                    {formatNumber(flaggedTransaction?.netAmount)}
                  </TableCell>
                  <TableCell
                    style={{ width: '25%' }}
                  >
                    {`- ${flaggedTransaction.comments}`}
                  </TableCell>
                </TableRow>
              ))}
            </TableHead>
          </Table>
        </Grid>
      )}
    </Grid>
  );
};

export default PaymentCommentSummaryTable;
