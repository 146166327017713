import { useRef, useState } from 'react';
import type { FC } from 'react';
import {
  Box,
  ButtonBase,
  Divider,
  Popover,
  PopoverOrigin,
  Typography
} from '@mui/material';
import { endpointUrl, environmentName, isDevelopment, isStaging } from 'src/utils/environmentUtils';
import IntegrataDevLogo from 'src/icons/IntegrataDevLogo';
import StagingLogo from 'src/icons/StagingLogo';

export enum PopoverLocation {
  Navbar,
  Sidebar,
}

interface DevInfoPopoverProps {
  location: PopoverLocation;
}

const DevInfoPopover: FC<DevInfoPopoverProps> = (props) => {
  const { location } = props;
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const calculateAnchorOrigin = (): PopoverOrigin => {
    switch (location as PopoverLocation) {
      case PopoverLocation.Navbar:
        return {
          horizontal: 'center',
          vertical: 'bottom'
        };
      case PopoverLocation.Sidebar:
        return {
          horizontal: 'right',
          vertical: 'center'
        };

      default:
        return null;
        break;
    }
  };

  const calculateTransformOrigin = (): PopoverOrigin => {
    switch (location as PopoverLocation) {
      case PopoverLocation.Navbar:
        return {
          vertical: 'top',
          horizontal: 'center'
        };

      case PopoverLocation.Sidebar:
        return {
          vertical: 'center',
          horizontal: 'left', };

      default:
        return null;
        break;
    }
  };

  interface DeveloperInfo {
    key: string,
    value: string
  }

  const developerInfos : DeveloperInfo[] = [
    {
      key: 'Environment',
      value: environmentName
    },
    {
      key: 'Endpoint',
      value: endpointUrl
    }
  ];

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {isDevelopment && (
        <IntegrataDevLogo
          sx={{ width: 75, height: 60 }}
        />
        )}
        {isStaging && (
          <StagingLogo
            sx={{ width: 105, height: 90, marginTop: 5 }}
          />
        )}
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        onClose={handleClose}
        open={open}
        anchorOrigin={calculateAnchorOrigin()}
        transformOrigin={calculateTransformOrigin()}
        PaperProps={{
          sx: { minWidth: 200 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box>
            <Typography
              color="textPrimary"
              variant="h6"
              sx={{ mb: 1 }}
            >
              Developer Info
            </Typography>
            <Divider />
          </Box>
          {developerInfos.map((info:DeveloperInfo) => (
            <Box
              sx={{ mt: 1 }}
              key={info.key}
            >
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {info.key}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {info.value}
              </Typography>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default DevInfoPopover;
