import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { CustomerCompanyDto, LoginInfoDto, UserInternalRoleDto, UserRoleDto } from 'src/types/apiSchemas';
import CommonDialog from 'src/components/common/CommonDialog';
import { baseApi } from 'src/API/baseApi';
import { IAuthenticationService, AuthenticationService, ISiteUserUpdate, RefreshOperation } from 'src/Services';
import toast from 'react-hot-toast';

export interface CustomerDetailsState {
  customerCompanies: CustomerCompanyDto[];
  userRoles: UserRoleDto[];
  userInternalRoles: UserInternalRoleDto[];
  userCompanies: CustomerCompanyDto[];
}

export interface UserSelections {
  companies?: number[];
  roles?: number[];
  internalRoles?: number[];
}

const AccessRequestPage: FC = observer(() => {
  const { t } = useTranslation();
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const navigate = useNavigate();
  const authenticationService: IAuthenticationService = new AuthenticationService(appStore);

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
    navigate(-1);
  };

  const handleLeaveTeamConfirm = async () => {
    setIsLoading(true);
    try {
      await baseApi.leaveCustomerTeam(user?.customerId);
      const newUser: LoginInfoDto = await baseApi.login(user.token);

      authenticationService.refreshSiteUser({ userDto: newUser, accessToken: user?.token, operation: RefreshOperation.update } as ISiteUserUpdate);
      closeModal();
      toast.success(t('LeaveTeamSuccessToast'));
      const updatedUser = appStore.loginStore.get().user;
      await appStore.notificationStore.refreshAllNotifications(updatedUser);
    } catch (err) {
      console.error(err);
      toast.error(t('LeaveTeamFailToast'));
    } finally {
      setIsLoading(false);
    }
  };

  if (user.isConsultantUser === false) {
    navigate('/401');
  }

  return (
    <CommonDialog
      isOpen={isOpen}
      dialogTitle={t('LeaveTeamDialogTitle')}
      dialogContent={t('LeaveTeamDialogContent')}
      handleCancel={closeModal}
      handleConfirm={handleLeaveTeamConfirm}
      confirmButtonTitle={t('OkButtonTitle')}
      cancelButtonTitle={t('ButtonCancel')}
      processing={isLoading}
    />
  );
});

export default AccessRequestPage;
