import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = observer(({ children }) => {
  const appStore = useAppStore();

  if (appStore.loginStore.get().isAuthenticated) {
    return <Navigate to="/dashboard/" />;
  }

  return <>{children}</>;
});

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
