import createSvgIcon from '@mui/material/utils/createSvgIcon';

const IntegrataDevLogo = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 200"
    fill="white"
  >
    <path
      id="Imported Path"
      fill="white"
      stroke="none"
      d="M 86.70,41.30
           C 86.70,41.30 86.70,73.00 86.70,73.00
             84.60,73.00 69.80,73.90 69.50,74.10
             52.40,75.80 39.00,90.20 39.00,107.70
             39.00,107.70 39.00,123.30 39.00,123.30
             39.00,141.90 54.10,157.00 72.70,157.00
             91.30,157.00 106.40,141.90 106.40,123.30
             106.40,123.30 106.40,41.30 106.40,41.30
             106.40,22.70 86.30,25.10 86.70,41.30 Z
           M 72.70,137.20
           C 65.00,137.20 58.80,130.90 58.80,123.30
             58.80,123.30 58.80,107.70 58.80,107.70
             58.80,100.00 64.80,93.20 72.40,93.20
             73.40,93.20 83.30,93.10 86.60,92.90
             86.60,92.90 86.60,123.30 86.60,123.30
             86.70,131.00 80.40,137.20 72.70,137.20 Z
           M 260.20,79.70
           C 259.10,77.20 257.20,75.30 254.90,74.40
             252.50,73.30 249.80,73.30 247.20,74.20
             244.60,75.20 242.60,77.10 241.50,79.70
             241.50,79.70 225.10,118.60 225.10,118.60
             225.10,118.60 208.70,79.60 208.70,79.60
             207.60,77.10 205.60,75.10 203.10,74.10
             200.60,73.10 197.90,73.20 195.70,74.20
             193.10,75.20 191.10,77.10 190.00,79.70
             189.00,82.20 189.00,85.10 190.00,87.40
             190.00,87.40 214.90,146.70 214.90,146.70
             215.40,147.90 216.10,149.10 216.90,150.10
             216.90,150.10 217.00,150.30 217.00,150.30
             218.00,151.70 219.30,152.80 220.80,153.50
             220.80,153.50 222.50,154.10 222.50,154.10
             222.80,154.20 223.20,154.30 223.50,154.40
             223.70,154.40 223.80,154.50 224.00,154.50
             224.30,154.50 224.60,154.50 224.90,154.60
             225.50,154.60 226.20,154.60 226.60,154.50
             226.60,154.50 227.70,154.30 227.70,154.30
             227.70,154.30 228.90,153.90 228.90,153.90
             229.00,153.80 229.10,153.80 229.30,153.70
             229.50,153.60 229.70,153.40 229.90,153.30
             230.20,153.10 230.50,152.90 230.80,152.70
             230.90,152.60 231.00,152.60 231.10,152.50
             231.20,152.40 231.30,152.30 231.40,152.30
             231.90,151.80 232.40,151.30 232.70,151.00
             233.90,149.40 234.80,147.70 235.60,146.00
             235.60,146.00 260.40,87.40 260.40,87.40
             261.30,85.00 261.20,82.10 260.20,79.70 Z
           M 150.70,71.70
           C 132.60,71.70 118.00,86.40 118.00,104.50
             118.00,104.50 118.00,124.20 118.00,124.20
             118.00,142.30 132.70,157.00 150.70,157.00
             156.20,157.00 160.60,152.50 160.60,147.10
             160.60,141.70 156.10,137.20 150.70,137.20
             143.60,137.20 137.80,131.40 137.80,124.30
             137.80,124.30 137.80,104.60 137.80,104.60
             137.80,97.50 143.60,91.70 150.70,91.70
             157.80,91.70 163.60,97.50 163.60,104.60
             163.60,104.60 163.60,110.30 163.60,110.30
             163.60,110.30 152.60,110.30 152.60,110.30
             147.10,110.30 142.70,114.80 142.70,120.20
             142.70,125.60 147.20,130.10 152.60,130.10
             152.60,130.10 168.80,130.10 168.80,130.10
             176.90,130.10 183.50,123.50 183.50,115.40
             183.50,115.40 183.50,104.40 183.50,104.40
             183.50,86.40 168.80,71.70 150.70,71.70 Z"
    />
  </svg>,
  'Integrata'
);

export default IntegrataDevLogo;
