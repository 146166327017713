export const getBackendUrl = () => process.env.REACT_APP_HUMHUM_BACKEND_URL;
export const getAuthHeader = (accessToken:string) => `Bearer ${accessToken}`;

/**
 * Formats the given parameter to string even if it is null or undefined.
 */
export const formatQueryParameterToString = (param: any) : string => {
  switch (param) {
    case null:
    case undefined:
      return '';
    default:
      return param.toString();
  }
};
