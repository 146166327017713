import { FC } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import { observer } from 'mobx-react';
import { Grid, TextField, Typography } from '@mui/material';
import { CreateAnnouncementBaseProps } from './props/CreateAnnouncementBaseProps';
import { useTranslation } from 'react-i18next';
import { AnnouncementType } from 'src/types/apiSchemas';

interface CreateAnnouncementDatetimeInputsProps extends CreateAnnouncementBaseProps {
  setFieldValueHandler: any;
}

const CreateAnnouncementDatetimeInputs: FC<CreateAnnouncementDatetimeInputsProps> = observer((props) => {
  const { setFieldValueHandler, touchedHandler, errorHandler, formValues } = props;
  const { announcementType } = formValues;
  const { t } = useTranslation();

  return (
    <>
      {announcementType === AnnouncementType.Reminder && (
        <Grid
          item
          sm={6}
        >
          <Typography
            color="textPrimary"
            variant="formFieldHeader"
            sx={{ ml: 1, mb: 1 }}
          >
            {t('CreateAnnouncementReminderDueDate')}
          </Typography>
          <DateTimePicker
            mask="__.__.____ __:__"
            componentsProps={{
              actionBar: {
                actions: ['clear']
              }
            }}
            ampm={false}
            inputFormat="dd.MM.yyyy HH:mm"
            value={formValues.reminderDueDateUTC}
            onChange={(val: Date) => {
              if (val != null) {
                val.setSeconds(0);
              }
              setFieldValueHandler('reminderDueDateUTC', val);
            }}
            renderInput={(params) => (
              <TextField
                style={{ display: 'flex' }}
                {...params}
                error={Boolean(touchedHandler.reminderDueDateUTC && errorHandler.reminderDueDateUTC)}
                helperText={(touchedHandler.reminderDueDateUTC && errorHandler.reminderDueDateUTC)}
              />
            )}
          />
        </Grid>
      )}
      {(announcementType === 'Event') && (
        <Grid
          xs
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          rowSpacing={1}
          columnSpacing={5}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              color="textPrimary"
              variant="formFieldHeader"
              sx={{ ml: 1, mb: 1 }}
            >
              {t('CreateAnnouncementStartDatetime')}
            </Typography>
            <DateTimePicker
              mask="__.__.____ __:__"
              componentsProps={{
                actionBar: {
                  actions: ['clear']
                }
              }}
              ampm={false}
              inputFormat="dd.MM.yyyy HH:mm"
              value={formValues.eventStartTimeUTC}
              onChange={(val: Date) => {
                if (val != null) {
                  val.setSeconds(0);
                }
                setFieldValueHandler('eventStartTimeUTC', val);
              }}
              renderInput={(params) => (
                <TextField
                  style={{ display: 'flex' }}
                  {...params}
                  error={Boolean(touchedHandler.eventStartTimeUTC && errorHandler.eventStartTimeUTC)}
                  helperText={(touchedHandler.eventStartTimeUTC && errorHandler.eventStartTimeUTC)}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              color="textPrimary"
              variant="formFieldHeader"
              sx={{ ml: 1, mb: 1 }}
            >
              {t('CreateAnnouncementEndDatetime')}
            </Typography>
            <DateTimePicker
              mask="__.__.____ __:__"
              componentsProps={{
                actionBar: {
                  actions: ['clear']
                }
              }}
              ampm={false}
              inputFormat="dd.MM.yyyy HH:mm"
              value={formValues.eventEndTimeUTC}
              onChange={(val: Date) => {
                if (val != null) {
                  val.setSeconds(0);
                }
                setFieldValueHandler('eventEndTimeUTC', val);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ display: 'flex' }}
                  error={Boolean(touchedHandler.eventEndTimeUTC && errorHandler.eventEndTimeUTC)}
                  helperText={(touchedHandler.eventEndTimeUTC && errorHandler.eventEndTimeUTC)}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
});

export default CreateAnnouncementDatetimeInputs;
