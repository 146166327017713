import React, { FC, useEffect, useState } from 'react';
import { Box, Breadcrumbs, Container, Grid, Typography, Link, Button, List, Paper, ListItemButton, Divider, CircularProgress, Tabs, Tab, ListItem } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';
import { useAppStore } from 'src/store/mobx/appStore';
import ChevronRightIcon from '../../icons/ChevronRight';
import { holidayBonusApi } from 'src/API/holidayBonusApi';
import { HolidayBonusChangePeriod } from 'src/types/apiSchemas';
import { displayLocalDate } from 'src/utils/dateUtils';
import HolidayBonusChangeRequestForm from 'src/pages/dashboard/HolidayBonusChangeRequestForm';
import { IAuthorizationService, AuthorizationService } from 'src/Services';
import NotFound from '../NotFound';
import HolidayBonusChangePeriodRequestsModal from 'src/components/dashboard/holidayBalance/HolidayBonusChangePeriodRequestsModal';

type HolidayBonusChangeTab = 'holidayBonusChangePeriods' | 'holidayBonusChangeRequestForm';

const HolidayBonusChange: FC = observer(() => {
  const appStore = useAppStore();
  const { t } = useTranslation();
  const { user } = appStore.loginStore.get();
  const [periods, setPeriods] = useState<HolidayBonusChangePeriod[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [holidayBonusChangePeriod, setHolidayBonusChangePeriod] = useState<HolidayBonusChangePeriod>(null);
  const auth: IAuthorizationService = new AuthorizationService();
  const [activeTab, setActiveTab] = useState<HolidayBonusChangeTab>(auth.get(user).isHR ? 'holidayBonusChangePeriods' : 'holidayBonusChangeRequestForm');

  useEffect(() => {
    const getPeriods = async () => {
      if (!auth.get(user).isHR) {
        return;
      }
      try {
        const response = await holidayBonusApi.getHolidayBonusChangePeriods(user?.customerId);
        setPeriods(response.results);
      } catch (err) {
        console.log(err);
      }
    };

    const getOngoingHolidayBonusChangePeriod = async () => {
      try {
        const response = await holidayBonusApi.getOngoingHolidayBonusChangePeriod();
        if (response.customerId === user?.customerId){
          setHolidayBonusChangePeriod(response);
        }
      } catch (err) {
        console.log(err);
      }
    };

    const updateData = async () => {
      setIsLoading(true);
      await Promise.allSettled([getOngoingHolidayBonusChangePeriod(), getPeriods()]);
      setIsLoading(false);
    };

    updateData();
  }, []);

  if ((!isLoading && !holidayBonusChangePeriod && !auth.get(user).isHR) || !auth.get(user).hasHolidayBonusChangeAccess) {
    return <NotFound />;
  }

  return (
    <>
      <Helmet>
        <title>{t('HolidayBonusChangePageHelmet')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
          mx: 2
        }}
      >
        <Container maxWidth="lg">
          <Typography
            color="textPrimary"
            variant="h5"
            sx={{ mb: 3 }}
          >
            {t('HolidayBonusChangePageTitle')}
          </Typography>
          <Grid
            container
            direction="row"
          >
            <Breadcrumbs
              sx={{
                paddingLeft: '1%',
              }}
              aria-label="breadcrumb"
              separator={(
                <ChevronRightIcon
                  fontSize="small"
                  sx={{ mb: 5 }}
                />
              )}
            >
              <Grid
                item
                sx={{ mb: 5 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/landingpage"
                  variant="subtitle2"
                >
                  {t('LandingPagePageTitle')}
                </Link>
              </Grid>
              <Grid item>
                <Typography
                  color="text.primary"
                  sx={{ mb: 5 }}
                  variant="subtitle2"
                >
                  {t('HolidayBonusChangePageTitle')}
                </Typography>
              </Grid>
            </Breadcrumbs>
          </Grid>
          {isLoading && <CircularProgress sx={{ display: 'block', margin: 'auto' }} />}
          {!isLoading
            && (
              <>
                {auth.get(user).isHR
                  && (
                    <Tabs
                      value={activeTab}
                      onChange={(e, val) => {
                        setActiveTab(val);
                      }}
                      style={{ marginBottom: 30 }}
                    >
                      <Tab
                        label={t('HolidayBonusChangePeriodTabPeriods')}
                        value="holidayBonusChangePeriods"
                      />
                      {!!holidayBonusChangePeriod && (
                        <Tab
                          label={t('HolidayBonusChangePeriodTabForm')}
                          value="holidayBonusChangeRequestForm"
                        />
                      )}
                    </Tabs>
                  )}
                {activeTab === 'holidayBonusChangePeriods'
                  && (
                    <Grid
                      container
                      gap={3}
                      direction="column"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          to="/dashboard/holidaybonuschange/create"
                          component={RouterLink}
                        >
                          {t('HolidayBonusChangePeriodCreate')}
                        </Button>
                      </Grid>
                      <Grid item>
                        {!isLoading && periods.length > 0 && (
                          <Paper>
                            <List>
                              {periods.map((p) => (
                                <React.Fragment key={p.holidayBonusChangePeriodId}>
                                  <ListItem>
                                    <ListItemButton
                                      component={RouterLink}
                                      to={`edit/${p.holidayBonusChangePeriodId}`}
                                    >
                                      {displayLocalDate(p.periodStartUtc, true)}
                                      {' '}
                                      -
                                      {' '}
                                      {displayLocalDate(p.periodEndUtc, true)}
                                    </ListItemButton>
                                    <HolidayBonusChangePeriodRequestsModal holidayBonusChangePeriodId={p.holidayBonusChangePeriodId} />
                                  </ListItem>
                                  <Divider />
                                </React.Fragment>
                              ))}
                            </List>
                          </Paper>
                        )}
                      </Grid>
                    </Grid>
                  )}
                {activeTab === 'holidayBonusChangeRequestForm' && <Paper style={{ padding: 30 }}><HolidayBonusChangeRequestForm holidayBonusChangePeriod={holidayBonusChangePeriod} /></Paper>}
              </>
            )}
        </Container>
      </Box>
    </>
  );
});

export default HolidayBonusChange;
