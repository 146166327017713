import createSvgIcon from '@mui/material/utils/createSvgIcon';

const SalaryChange = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 262 180"
    fill="currentColor"
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M261.383 32.602C261.383 50.6077 246.806 65.2041 228.825 65.2041C225.863 65.2041 222.994 64.8082 220.268 64.0663L138.166 155.966L90.1083 108.137L18.0217 180L0 162.064L90.1083 72.2659L138.166 120.095L201.094 49.6935C198.033 44.724 196.267 38.8696 196.267 32.602C196.267 14.5964 210.843 0 228.825 0C246.806 0 261.383 14.5964 261.383 32.602ZM228.825 46.8367C236.676 46.8367 243.04 40.4636 243.04 32.602C243.04 24.7404 236.676 18.3673 228.825 18.3673C220.974 18.3673 214.609 24.7404 214.609 32.602C214.609 40.4636 220.974 46.8367 228.825 46.8367Z"
    />
  </svg>,
  'SalaryChange'
);

export default SalaryChange;
