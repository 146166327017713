import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateAnnouncementBasePropsWithBlur } from './props/CreateAnnouncementBaseProps';

const CreateAnnouncementConfirmation : FC<CreateAnnouncementBasePropsWithBlur> = (props) => {
  const { onChangeHandler, touchedHandler, errorHandler, formValues, onBlurHandler } = props;
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item>
        <Typography
          variant="formFieldHeader"
          color="textPrimary"
          sx={{ ml: 1, mb: 1 }}
        >
          {t('CreateAnnouncementConfirmationSectionHeader')}
        </Typography>
        <FormControlLabel
          sx={{ ml: { xs: 1, sm: 0 }, mt: 1 }}
          control={(
            <Checkbox
              checked={formValues.responseRequired}
              onChange={onChangeHandler}
              name="responseRequired"
              color="primary"
            />
            )}
          label={t('CreateAnnouncementConfirmationSectionToggleText')}
        />
      </Grid>
      {formValues.responseRequired && (
        <Grid
          item
          xs={12}
          sm={12}
          md
        >
          <Typography
            variant="formFieldHeader"
            color="textPrimary"
            sx={{ ml: 1, mb: 1 }}
          >
            {t('CreateAnnouncementConfirmationText')}
          </Typography>
          <TextField
            error={Boolean(touchedHandler.responseText && errorHandler.responseText)}
            helperText={(touchedHandler.responseText && errorHandler.responseText) ?? ' '}
            value={formValues.responseText}
            fullWidth
            name="responseText"
            required
            variant="outlined"
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CreateAnnouncementConfirmation;
