import { THEMES } from '../constants';
import { uiTheme } from './environmentUtils';
import { Theme } from '@mui/material';

export const resolveTheme = (): string => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in THEMES) {
    if (THEMES[key] === uiTheme) {
      return THEMES[key];
    }
  }
  return THEMES.DARK;
};

interface StyleObject {
  [key: string]: string;
}

export const getComponentStyle = (
  theme: Theme,
  component: string,
  variant: string,
  styleSheet: StyleObject
): StyleObject => {
  const variants = theme.components?.[component]?.variants;
  const variantStyle = variants?.find(v => v.props.variant === variant)?.style;

  if (!variantStyle) {
    return styleSheet; // Return original if no variant style found
  }

  return { ...styleSheet, ...variantStyle };
};