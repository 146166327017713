import axios from 'axios';
import {
  ManagerSubstitutionDto,
} from 'src/types/apiSchemas';
import { getBackendUrl } from 'src/utils/requestUtils';

class ManagersApi {
  async addOrUpdateManagerSubstitute(dto: ManagerSubstitutionDto): Promise<ManagerSubstitutionDto> {
    try {
      const endpointUrl = `${getBackendUrl()}/api/v1/Managers/Me/AddOrUpdateSubstitute`;
      const response = await axios.post(endpointUrl, dto, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const substitute: ManagerSubstitutionDto = response.data;
      return await Promise.resolve(substitute);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async deleteManagerSubstitute(dto: ManagerSubstitutionDto): Promise<ManagerSubstitutionDto> {
    try {
      const endpointUrl = `${getBackendUrl()}/api/v1/Managers/Me/DeleteSubstitute`;
      const response = await axios.delete(endpointUrl, {
        data: JSON.stringify(dto),
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const substitute: ManagerSubstitutionDto = response.data;
      return await Promise.resolve(substitute);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getAllManagerSubstitutes(): Promise<ManagerSubstitutionDto[]> {
    try {
      const endpointUrl = `${getBackendUrl()}/api/v1/Managers/Me/AllSubstitutes`;
      const response = await axios(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const substitutes: ManagerSubstitutionDto[] = response.data;
      return await Promise.resolve(substitutes);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export const managersApi = new ManagersApi();
