import { CircularProgress, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { MessageAttachmentCreation, MessageAttachmentType } from 'src/types/apiSchemas';
import { isAllowedAttachmentFile, isImageFile, toBase64 } from 'src/utils/imageUtils';
import X from 'src/icons/X';
import { Error } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { allowedMessageFileExtensions } from 'src/constants';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useTranslation } from 'react-i18next';

export const RemoveAttachmentButton = ({ onClick }: { onClick?: () => void }) =>
  <div style={{ position: 'absolute', right: -15, top: -15, zIndex: 2 }}>
    <IconButton sx={{
      color: '#fff',
      backgroundColor: 'black', ':hover': {
        backgroundColor: 'black'
      }
    }}
      size='small'
      onClick={onClick}
    >
      <X fontSize="small" />
    </IconButton>
  </div>;

export const AttachmentPreview = ({ attachment, onRemove, displayRemoveButton, thumbnailSize = 80, displayPreviewLink, onClick, isLoading, uploadAttachment, messageId, t }: { attachment: MessageAttachmentCreation, onRemove?: () => void, displayRemoveButton?: boolean, thumbnailSize?: number, displayPreviewLink?: boolean, onClick?: () => void, isLoading?: boolean, uploadAttachment?: (attachment: MessageAttachmentCreation, attachmentMessageId: number) => Promise<any>, messageId?: number, t: (key: string) => string }) =>
  isImageFile(attachment.fileName) && (attachment.base64File || attachment.url) ?
    <div onClick={onClick}
      title={attachment.fileName}
      style={{ width: thumbnailSize, height: thumbnailSize, maxWidth: '100%', borderRadius: 8, backgroundColor: '#d9d9d9', backgroundImage: `url(${attachment.base64File || attachment.thumbnailUrl})`, backgroundSize: 'cover', backgroundPosition: '50% 50%', position: 'relative', cursor: displayPreviewLink ? 'pointer' : 'default' }}
    >
      {isLoading && <CircularProgress sx={{ display: 'block', margin: 'auto', marginTop: '40%' }} />}
      {displayRemoveButton && <RemoveAttachmentButton onClick={onRemove} />}
      <div style={{ width: '100%', overflow: 'hidden', boxSizing: 'border-box', padding: '0px 5px', position: 'absolute', bottom: 0, left: 0, background: 'rgba(255,255,255,0.75)', color: '#000', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, fontSize: 12, whiteSpace: 'nowrap', display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {attachment.loadError &&
            <Error color="error"
              sx={{ marginRight: '5px' }}
            />
          }
          {attachment.fileName}
        </div>
        {attachment.loadError && !attachment.isLoading && attachment.allowUploadRetry && uploadAttachment &&
          <div>
            <Box
              sx={{
                cursor: 'pointer', padding: '5px', fontSize: 14, display: 'inline-block', '&:hover': {
                  textDecoration: 'underline'
                }
              }}
              onClick={async () => {
                try {
                  await uploadAttachment(attachment, messageId);
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {t('MessagesPageRetryUpload')}
            </Box>
          </div>}
      </div>
    </div> :
    <div onClick={onClick} style={{ display: 'flex', alignItems: 'center', border: '1px solid rgba(0,0,0,0.5)', borderRadius: 8, padding: '5px 10px', minWidth: thumbnailSize, fontSize: 14, position: 'relative', paddingRight: 20, alignSelf: 'baseline', cursor: displayPreviewLink ? 'pointer' : 'default' }}>
      {displayRemoveButton && <RemoveAttachmentButton onClick={onRemove} />}
      {isLoading &&
        <CircularProgress size={20}
          sx={{ marginRight: '10px' }}
          onClick={async () => {
            try {
              await uploadAttachment(attachment, messageId);
            } catch (err) {
              console.log(err);
            }
          }}
        />}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {attachment.loadError && <Error color="error" sx={{ marginRight: '10px' }} />}
          {attachment.fileName}
        </div>
        {attachment.loadError && !attachment.isLoading && attachment.allowUploadRetry &&
          <div>
            <Box
              sx={{
                cursor: 'pointer', padding: '5px', fontSize: 14, display: 'inline-block', '&:hover': {
                  textDecoration: 'underline'
                }
              }}
              onClick={async () => {
                try {
                  await uploadAttachment(attachment, messageId);
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {t('MessagesPageRetryUpload')}
            </Box>
          </div>}
      </div>
    </div>;

const attachmentFileSizeLimitInMB = 10;

export const useAttachments = () => {
  const [attachments, setAttachments] = useState<MessageAttachmentCreation[]>([]);

  useEffect(() => {
    const addMessageAttachments = async (files: File[]) => {
      for (const file of files) {
        if (isAllowedAttachmentFile(file.name, allowedMessageFileExtensions) && file.size < attachmentFileSizeLimitInMB * 1024 * 1024) {
          const base64File = await toBase64(file);
          setAttachments(a => [...a, ...[{
            fileName: file.name,
            file: file,
            messageAttachmentType: MessageAttachmentType.Other,
            base64File
          }].filter(att => !a.some(ea => ea.base64File === att.base64File))]);
        }
      }
    };

    const dropFile = async (ev: DragEvent) => {
      ev.preventDefault();
      if (ev.dataTransfer.items) {
        const files = [...ev.dataTransfer.items].filter(item => item.kind === 'file').map(item => item.getAsFile());
        addMessageAttachments(files);
      } else {
        addMessageAttachments([...ev.dataTransfer.files]);
      }
    };

    const preventDefault = (ev: DragEvent) => {
      ev.preventDefault();
    };

    const pasteFile = async (ev: ClipboardEvent) => {
      const files = [...ev.clipboardData.files];
      if (files.length > 0) {
        ev.preventDefault();
      }
      addMessageAttachments([...files]);
    };

    document.addEventListener('dragover', preventDefault);
    document.addEventListener('drop', dropFile);
    document.addEventListener('paste', pasteFile);

    return () => {
      document.removeEventListener('dragover', preventDefault);
      document.removeEventListener('drop', dropFile);
      document.removeEventListener('paste', pasteFile);
    };
  }, []);

  return [attachments, setAttachments] as const;
};

export const AttachmentUploadButton = ({ attachments, setAttachments }: { attachments: MessageAttachmentCreation[], setAttachments: (value: React.SetStateAction<MessageAttachmentCreation[]>) => void }) => {
  const { t } = useTranslation();
  return (
    <IconButton
      color="inherit"
      component="label"
      sx={{
        color: '#fff',
        backgroundColor: '#000', ':hover': {
          backgroundColor: '#000'
        }
      }}
      title={t('MessagesPageAddAttachment')}
    >
      <AttachFileIcon
        fontSize="small"
      />
      <input
        accept={allowedMessageFileExtensions.join(', ')}
        hidden
        capture
        type="file"
        onChange={async (e) => {
          const file = e.target.files[0];
          try {
            if (file && file.size < attachmentFileSizeLimitInMB * 1024 * 1024 && isAllowedAttachmentFile(file.name, allowedMessageFileExtensions)) {
              const base64File = await toBase64(file);

              if (!attachments.some(a => a.base64File === base64File)) {
                setAttachments((a) => [...a, {
                  fileName: file.name,
                  file: file,
                  messageAttachmentType: MessageAttachmentType.Other,
                  base64File
                }]);
              }
            }
          } catch (err) {
            console.log(err);
          }
          e.target.value = '';
        }}
      />
    </IconButton>);
};