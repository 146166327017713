import { Button, Grid, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AnnouncementLink } from 'src/types/announcementLink';
import AddIcon from '@mui/icons-material/Add';

interface CreateAnnouncementAddLinkProps {
  arrayHelpers: any,
}

const CreateAnnouncementAddLink: FC<CreateAnnouncementAddLinkProps> = (props) => {
  const { arrayHelpers } = props;
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        title: '',
        url: ''
      }}
      validationSchema={Yup
        .object()
        .shape({
          title: Yup
            .string()
            .max(255)
            .min(1)
            .required(t('YupGeneralMessage')),
          url: Yup
            .string()
            .max(255)
            .min(1)
            .required(t('YupGeneralMessage')),
        })}
      validateOnChange={false}
      validateOnMount={false}
      validateOnBlur={false}
      onSubmit={async (values, {
        resetForm,
        setStatus,
        setSubmitting
      }): Promise<void> => {
        try {
          setSubmitting(true);
          const newLink: AnnouncementLink = {
            text: values.title,
            url: encodeURIComponent(values.url)
          };
          arrayHelpers.push(newLink);
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          console.error(err);
          setSubmitting(false);
        }
      }}
    >
      {(subFormik): JSX.Element => (
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          columnSpacing={2}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md
          >
            <Typography
              variant="formFieldHeader"
              color="textPrimary"
              sx={{ ml: 1, mb: 1 }}
            >
              {t('CreateAnnouncementAddLinkSectionHeader')}
            </Typography>
            <TextField
              error={Boolean(subFormik.touched.url && subFormik.errors.url)}
              helperText={(subFormik.touched.url && subFormik.errors.url) ?? ' '}
              value={subFormik.values.url}
              fullWidth
              name="url"
              required
              variant="outlined"
              onBlur={subFormik.handleBlur}
              onChange={subFormik.handleChange}
            />
          </Grid>
          <Grid
            item
            xs
          >
            <Typography
              variant="formFieldHeader"
              color="textPrimary"
              sx={{ ml: 1, mb: 1 }}
            >
              {t('CreateAnnouncementAddLinkTitle')}
            </Typography>
            <TextField
              error={Boolean(subFormik.touched.title && subFormik.errors.title)}
              helperText={(subFormik.touched.title && subFormik.errors.title) ?? ' '}
              value={subFormik.values.title}
              fullWidth
              name="title"
              required
              variant="outlined"
              onBlur={subFormik.handleBlur}
              onChange={subFormik.handleChange}
            />
          </Grid>
          <Grid
            item
            sx={{ mt: 1.5 }}
          >
            <Button
              startIcon={<AddIcon fontSize="small" />}
              color="primary"
              disabled={subFormik.isSubmitting}
              variant="contained"
              onClick={() => subFormik.handleSubmit()}
            >
              {t('CreateAnnouncementAddLinkButtonTitle')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default CreateAnnouncementAddLink;
