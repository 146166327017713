import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enJson from './translations/translations.en.json';
import fiJson from './translations/translations.fi.json';
import svJson from './translations/translations.sv.json';

const resources = {
  en: {
    translation: enJson
  },
  fi: {
    translation: fiJson
  },
  sv: {
    translation: svJson
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fi',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });
