import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { WhiteOutlineButton } from 'src/components/WhiteOutlineButton';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { baseApi } from 'src/API/baseApi';
import { useAppStore } from 'src/store/mobx/appStore';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

interface DeleteAnnouncementModalProps {
  announcementId: number;
  closeModal: () => void;
}

const DeleteAnnouncementModal: FC<DeleteAnnouncementModalProps> = observer((props) => {
  const { announcementId, closeModal } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await baseApi.deleteAnnouncement(user.customerId, announcementId);
      toast.success(t('DeleteAnnouncementModalDeleteSuccessMessage'));
      navigate('/dashboard/announcements');
    } catch (err) {
      setLoading(false);
      toast.error(t('DeleteAnnouncementModalDeleteFailedError'));
    }
  };

  return (
    <>
      <h3>{t('DeleteAnnouncementModalHeaderTitle')}</h3>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        sx={{ mt: 2 }}
      >
        <WhiteOutlineButton
          aria-label="close"
          onClick={closeModal}
          variant="outlined"
          sx={{ mr: 1 }}
        >
          {t('DeleteAnnouncementModalCloseButtonTitle')}
        </WhiteOutlineButton>
        <LoadingButton
          color="primary"
          onClick={handleDelete}
          variant="contained"
          loading={loading}
        >
          {t('DeleteAnnouncementModalDeleteButtonTitle')}
        </LoadingButton>
      </Grid>
    </>
  );
});

export default DeleteAnnouncementModal;
