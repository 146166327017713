import createSvgIcon from '@mui/material/utils/createSvgIcon';

const EuroOverlined = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 183 180"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      xmlns="http://www.w3.org/2000/svg"
      d="M6.81218 166.814L44.5633 127.734C41.544 123.019 39.0423 117.94 37.1379 112.58H0L8.21634 96.147H33.3584C33.0297 93.4356 32.8654 90.7242 32.8654 87.9306C32.8654 85.1371 33.0297 82.4257 33.3584 79.7143H0L8.21634 63.2816H37.1379C47.3261 34.6066 74.6044 13.9836 106.812 13.9836C120.533 13.9836 133.43 17.7516 144.467 24.3128L167.953 0L182.145 13.7098L20.4623 180L6.81218 166.814ZM74.5705 154.475C84.3055 159.216 95.2408 161.878 106.812 161.878C125.792 161.878 143.047 154.73 156.111 142.98L141.485 128.437C132.201 136.489 120.123 141.337 106.812 141.337C100.925 141.337 95.2652 140.386 89.9765 138.63L74.5705 154.475ZM54.0635 79.7143H90.9497L75.0759 96.147H54.0635C53.6527 93.4356 53.4062 90.7242 53.4062 87.9306C53.4062 85.1371 53.6527 82.4257 54.0635 79.7143ZM129.642 39.6599L106.823 63.2816H59.4863C68.36 46.1916 86.2716 34.5244 106.812 34.5244C114.962 34.5244 122.706 36.3643 129.642 39.6599Z"
    />
  </svg>,
  'EuroOverlined'
);

export default EuroOverlined;
