import { Box, Breadcrumbs, Button, Card, CircularProgress, Container, Divider, Grid, Link, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '../../icons/ChevronRight';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { baseApi } from 'src/API/baseApi';
import { useAppStore } from 'src/store/mobx';
import { AbsenceTranslated } from 'src/types/apiSchemas';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { absenceTimeDurationFormatter, absenceTranslator, datesFormatter, timeFormatter } from 'src/pages/dashboard/ApprovalsAbsenceCard';
import AttachmentsPreviewDialog from 'src/components/dashboard/approvals/AttachmentsPreviewDialog';
import axios from 'axios';

const Absences: FC = () => {
  const { t } = useTranslation();
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const { absenceId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [absence, setAbsence] = useState<AbsenceTranslated>();
  const [attachmentViewIndex, setAttachmentViewIndex] = useState(-1);
  const navigate = useNavigate();

  useEffect(() => {
    const getAbsence = async () => {
      setIsLoading(true);
      try {
        const res = await baseApi.getUserAbsence(user?.userId, absenceId);
        setAbsence(res);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 404) {
          navigate('/404');
        }
      }

      setIsLoading(false);
    };

    getAbsence();
  }, [absenceId]);

  return (
    <>
      <Helmet>
        <title>{t('SickLeavePageHelmet')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
          mx: 2,
        }}
      >
        <Container maxWidth="lg">
          <Typography
            color="textPrimary"
            variant="h5"
            sx={{ mb: 3 }}
          >
            {t('SickLeavePageTitle')}
          </Typography>
          <Breadcrumbs
            sx={{
              paddingLeft: '1%',
            }}
            aria-label="breadcrumb"
            separator={(
              <ChevronRightIcon
                fontSize="small"
                sx={{ mb: 5 }}
              />
            )}
          >
            <Grid
              item
              sx={{ mb: 5 }}
            >
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/dashboard/landingpage"
                variant="subtitle2"
              >
                {t('LandingPagePageTitle')}
              </Link>
            </Grid>
            <Grid
              item
              sx={{ mb: 5 }}
            >
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/dashboard/absences"
                variant="subtitle2"
              >
                {t('SickLeavePageTitle')}
              </Link>
            </Grid>
            <Grid item>
              <Typography
                color="text.primary"
                variant="subtitle2"
                sx={{ mb: 5 }}
              >
                {t('ApprovalSickLeaveTitle')}
              </Typography>
            </Grid>
          </Breadcrumbs>
          {isLoading && (
            <CircularProgress
              size={65}
              sx={{ display: 'block', margin: 'auto', mt: 10 }}
            />
          )}
          {!isLoading && absence && (
            <Card sx={{ width: '100%', backgroundColor: 'primary.cardBackground' }}>
              <Grid
                container
                spacing={1}
                sx={{ m: 3 }}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h5">{user.name}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Typography>
                    {absenceTranslator(t, absence.absenceCategory)}
                    {' '}
                    {absenceTimeDurationFormatter(t, absence.startOfAbsenceUTC, absence.endOfAbsenceUTC)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  gap={1}
                >
                  <Grid item>{datesFormatter(absence.startOfAbsenceUTC, absence.endOfAbsenceUTC)}</Grid>
                  <Grid item>{timeFormatter(absence.startOfAbsenceUTC, absence.endOfAbsenceUTC)}</Grid>
                </Grid>
              </Grid>
              <Divider />
              <Grid
                container
                spacing={1}
                sx={{ m: 3 }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Typography variant="h6">{t('ApprovalAbsenceTypeTitle')}</Typography>
                  <Typography>{absence.absenceTypeName}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Typography variant="h6">{t('ApprovalCreationTimeTitle')}</Typography>
                  <Typography>{format(new Date(`${absence.absenceCreatedUTC}Z`), 'dd.MM.yyyy HH:mm')}</Typography>
                </Grid>
                {absence.approverComment && (
                  <Grid
                    item
                    marginTop={2}
                    xs={12}
                    sm={6}
                  >
                    <Typography variant="h6">{t('ApproverCommentTitle')}</Typography>
                    <Typography>{absence.approverComment}</Typography>
                  </Grid>
                )}
              </Grid>
              {absence.attachment && (
                <>
                  <Divider />
                  <Grid
                    container
                    spacing={1}
                    sx={{ m: 3 }}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography variant="h6">{t('ApprovalAttachmentsTitle')}</Typography>
                      {absence.attachment?.files?.map((attachment, i) => (
                        <div key={attachment.userAttachmentFileId}>
                          <Button
                            variant="text"
                            color="inherit"
                            disabled={!attachment.uri}
                            startIcon={<AttachmentIcon />}
                            onClick={() => {
                              setAttachmentViewIndex(i);
                            }}
                          >
                            {attachment.originalFilename}
                          </Button>
                        </div>
                      ))}
                    </Grid>
                  </Grid>
                </>
              )}
            </Card>
          )}
        </Container>
      </Box>
      {absence && attachmentViewIndex > -1 && (
        <AttachmentsPreviewDialog
          files={absence.attachment.files}
          initialIndex={attachmentViewIndex}
          onClose={() => {
            setAttachmentViewIndex(-1);
          }}
        />
      )}
    </>
  );
};

export default Absences;
