import { Configuration, LogLevel } from '@azure/msal-browser';
import { externalIdAppId, externalIdAuthority } from '../utils/environmentUtils';

export const MSAL_EXTERNAL_CONFIG: Configuration = {
  auth: {
    clientId: externalIdAppId,
    authority: externalIdAuthority,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // eslint-disable-next-line default-case
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
        }
      },
    },
  },
};
