import { Avatar } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InternalRole, UserRole } from 'src/types/apiSchemas';
import { GetInternalRoleTranslation, GetUserRoleTranslation } from 'src/utils/roleUtils';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

interface RoleDisplayProps {
  role: InternalRole | UserRole;
}

const isInternalRole = (role) => role in InternalRole;

const RoleDisplay: FC<RoleDisplayProps> = ({ role }) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
      <div>
        <Avatar sx={{
          backgroundColor: isInternalRole(role) ? 'primary.integrataRed' : 'success.main',
          color: '#ffffff'
        }}
        >
          <GroupsOutlinedIcon />
        </Avatar>
      </div>
      <div>{isInternalRole(role) ? GetInternalRoleTranslation(t, role as InternalRole) : GetUserRoleTranslation(t, role as UserRole)}</div>
    </div>
  );
};

export default RoleDisplay;
