import { ValidationError } from 'yup';

// eslint-disable-next-line func-names
export const fileExtensionValidation = (errorMessage: string, allowedExtensions: string[]) => function () {
  const { attachments } = this.parent;
  const errors = [];
  attachments.forEach((a: File, i: number) => {
    const extension = a.name?.split('.').at(-1);
    if (extension && !allowedExtensions.some((e) => e.toLocaleLowerCase() === `.${extension.toLocaleLowerCase()}`)) {
      errors.push(this.createError({
        path: `attachments[${i}]`,
        message: errorMessage
      }));
    }
  });
  if (errors.length > 0) {
    return new ValidationError(errors);
  }
  return true;
};
