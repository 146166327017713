import type { FC } from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { getInitial } from 'src/utils/getInitial';
import { displayLocalDateTime } from 'src/utils/dateUtils';
import ChevronRightButton from 'src/components/ChevronRightButton';

interface AnnouncementCardHeaderProps {
  imageUrl: string;
  author: string;
  date: string;
  showArrow: boolean;
  announcementId: number,
}

const AnnouncementCardHeader: FC<AnnouncementCardHeaderProps> = (props) => {
  const { imageUrl, author, date, showArrow, announcementId } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      sx={{ mb: showArrow ? 0 : 1 }}
    >
      <Grid
        item
        xs={11}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Avatar
          src={imageUrl}
          sx={{
            height: 32,
            width: 32,
          }}
          alt={author}
        >
          {getInitial(author)}
        </Avatar>
        <Typography
          color="textSecondary"
          sx={{ mx: 1 }}
        >
          {author}
        </Typography>
        <Typography
          color="textSecondary"
        >
          •
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ mx: 1 }}
        >
          {displayLocalDateTime(date, true)}
        </Typography>
      </Grid>
      {showArrow && (
        <Grid
          item
          xs={1}
        >
          <ChevronRightButton
            to={`/dashboard/announcements/${announcementId}`}
            size={{ height: 25, width: 25 }}
          />
        </Grid>
      )}
    </Grid>
  );
};

AnnouncementCardHeader.propTypes = {
  // @ts-ignore
  imageUrl: PropTypes.string,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  showArrow: PropTypes.bool.isRequired
};

export default AnnouncementCardHeader;
