import React, { createContext } from 'react';
import { LoginStore } from './loginStore';
import { NotificationStore } from './notificationsStore';
import { SiteSettingsStore } from './siteSettingsStore';

export class AppStore {
  public static instance: AppStore = null;

  public static get getInstance() {
    if (AppStore.instance === null) {
      AppStore.instance = new AppStore();
    }
    return AppStore.instance;
  }

  public get loginStore(): LoginStore {
    return LoginStore.getInstance();
  }

  public get notificationStore(): NotificationStore {
    return NotificationStore.getInstance();
  }

  public get settingsStore(): SiteSettingsStore {
    return SiteSettingsStore.getInstance();
  }
}

export const AppStoreContext = createContext<AppStore>(null);

export const useAppStore = () => React.useContext(AppStoreContext);
