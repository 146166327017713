import axios from 'axios';
import { CustomerNotification } from 'src/store/mobx';
import { ApprovalInclude, ManagerApprovalAbsenceTypeDto, ManagerApprovalDetailDto, ManagerApprovalDto, ManagerApprovalsDto } from 'src/types/apiSchemas';
import { SiteUser } from 'src/types/siteUser';
import { getBackendUrl } from 'src/utils/requestUtils';
import qs from 'qs';

class ManagerApprovalsApi {
  private readonly defaultApprovalIncludes: ApprovalInclude[] = [
    ApprovalInclude.IncludeAbsences,
    ApprovalInclude.IncludeAttachments,
    ApprovalInclude.IncludeHolidayBalanceRequests
  ];

  getMyApprovals = async (user: SiteUser, ApprovalStatus: string): Promise<ManagerApprovalsDto[]> => {
    const customerId = user?.customerId;
    if (!customerId) return Promise.reject();
    const endpointUrl = `${getBackendUrl()}/api/v1/Managers/Me/AllApprovals`;

    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
        params: { ApprovalStatus, CustomerId: customerId, Includes: this.defaultApprovalIncludes },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
      });
      const results: ManagerApprovalsDto[] = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  getMyPendingApprovalsCount = async (user: SiteUser, customerId?: number): Promise<CustomerNotification> => {
    const custId = customerId ?? user?.customerId;
    if (!custId) return Promise.reject();
    const endpointUrl = `${getBackendUrl()}/api/v1/Managers/Me/AllPendingApprovalsCount`;
    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
        params: { CustomerId: custId, Includes: this.defaultApprovalIncludes },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
      });
      const count: number = response.data;
      return await Promise.resolve({ count, customerId: custId } as CustomerNotification);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  putApproval = async (managerId: number, id: number, ApprovalStatus: string, comment: string): Promise<ManagerApprovalDto> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/Managers/${managerId}/Absences/${id}/Approval`;
    const stuff = { approverId: managerId, approvalStatus: ApprovalStatus, comment };
    try {
      const response = await axios.put(endpointUrl, stuff);
      const results: ManagerApprovalDto = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  putAbsence = async (managerId: number, id: number, ApprovalStatus: string, comment: string): Promise<ManagerApprovalDto> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/Managers/${managerId}/Attachments/${id}/Approval`;
    const stuff = { approverId: managerId, approvalStatus: ApprovalStatus, comment };
    try {
      const response = await axios.put(endpointUrl, stuff);
      const results: ManagerApprovalDto = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  async getAbsence(absenceId: string, managerId: number): Promise<ManagerApprovalDetailDto> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Managers/${managerId}/Absences/${absenceId}`;
    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getAttachment(userAttachmentId: string, managerId: number): Promise<ManagerApprovalDetailDto> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Managers/${managerId}/Attachments/${userAttachmentId}`;
    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getAttachmentRequirements(userId: number): Promise<ManagerApprovalAbsenceTypeDto[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${userId}/AbsenceTypes`;
    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getHolidayBonusChangeRequestDetails(user: SiteUser, requestId: string): Promise<ManagerApprovalDetailDto> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Managers/${user?.userId}/HolidayBonusChangeRequests/${requestId}`;
    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json'
        });
      const results: ManagerApprovalDetailDto = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  putHolidayBonusChangeRequest = async (managerId: number, requestId: number, ApprovalStatus: string, comment: string): Promise<ManagerApprovalDetailDto> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/Managers/${managerId}/HolidayBonusChangeRequests/${requestId}/Approval`;
    const stuff = { approverId: managerId, approvalStatus: ApprovalStatus, comment };
    try {
      const response = await axios.put(endpointUrl, stuff);
      const results: ManagerApprovalDetailDto = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  approveMultiHolidayBonusChangeRequests = async (managerId: number, ids: number[]): Promise<ManagerApprovalDetailDto> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/Managers/${managerId}/HolidayBonusChangeRequests/Approvals`;
    try {
      const response = await axios.put(endpointUrl, ids);
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  };
}

export const managerApprovalsApi = new ManagerApprovalsApi();
