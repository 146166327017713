import {
  PublicClientApplication,
  AuthenticationResult,
  AccountInfo,
  RedirectRequest,
  PopupRequest,
  Configuration,
  EndSessionPopupRequest,
} from '@azure/msal-browser';
import axios from 'axios';
import { AuthStorageEnum, IAuthConfigStorageService, AuthConfigStorageService } from 'src/Services';
import { MSAL_CONFIG } from './azure-authentication-config';
import { arrayBufferToFile } from 'src/utils/fileUtils';
import { aadLoginScopes, externalIdApiScopes, externalIdAppId } from '../utils/environmentUtils';
import { MSAL_EXTERNAL_CONFIG } from './external-authentication-config';

export class AzureAuthenticationContext {
  private myMSALObj: PublicClientApplication = null;
  private accessToken?: string;
  private account?: AccountInfo;
  private loginRedirectRequest?: RedirectRequest;
  private loginRequest?: PopupRequest;
  public isAuthenticationConfigured = false;
  // private appScopes: string[] = aadLoginScopes?.split(',') ?? [
  //   'https://integratafi.onmicrosoft.com/humhumapimultitenant/Announcements.Read',
  //   'https://integratafi.onmicrosoft.com/humhumapimultitenant/Dashboard.Use',
  //   'https://integratafi.onmicrosoft.com/humhumapimultitenant/UserInfos.Read',
  // ];

  /**
   * resolves app scopes
   * aad app scopes can be configured to env variable aadLoginScopes but if not, using default values
   * external usage authentication requires environment specific configured scopes (externalIdApiScopes)
   * @param config
   * @returns array of scopes
   */
  private getAppScopes(): string[] {
    if (this.isExternalIdConfig(this.authConfigStorageService.msalConfig)) {
      const scopes = externalIdApiScopes.split(',');
      return scopes;
    }
    // scopes can be configured in environment variables and if is, then using those configured scopes instead of hard coded ones
    return aadLoginScopes?.split(',') ?? [
      'https://integratafi.onmicrosoft.com/humhumapimultitenant/Announcements.Read',
      'https://integratafi.onmicrosoft.com/humhumapimultitenant/Dashboard.Use',
      'https://integratafi.onmicrosoft.com/humhumapimultitenant/UserInfos.Read',
    ];
  }

  private authConfigStorageService: IAuthConfigStorageService = new AuthConfigStorageService();

  private initClient(authStorage: AuthStorageEnum = null): void {
    if (authStorage === null) {
      authStorage = this.authConfigStorageService.msalConfig.cache.cacheLocation as AuthStorageEnum;
    }

    this.myMSALObj = new PublicClientApplication(
      { ...this.authConfigStorageService.msalConfig, cache: { ...this.authConfigStorageService.msalConfig.cache, cacheLocation: authStorage.toString() } }
    );
  }

  constructor() {
    this.initClient();
    this.account = null;
    this.setRequestObjects();
    if (MSAL_CONFIG?.auth?.clientId) {
      this.isAuthenticationConfigured = true;
    }
  }

  private isExternalIdConfig = (config: Configuration): boolean => (config?.auth?.clientId === externalIdAppId);

  public setAndInitCacheConfig(authStorage: AuthStorageEnum): void {
    this.initClient(authStorage);
  }

  public setConfigAndInit(config: Configuration): void {
    this.authConfigStorageService.msalConfig = config;
    this.setRequestObjects();
    this.initClient();
  }

  private setRequestObjects(): void {
    this.loginRequest = {
      scopes: this.getAppScopes(),
      prompt: 'select_account',
    };

    this.loginRedirectRequest = {
      ...this.loginRequest,
      redirectStartPage: window.location.href,
    };
  }

  public signUp(authCallback: any, handleError: any): void {
    this.setConfigAndInit(MSAL_EXTERNAL_CONFIG);
    this.myMSALObj
      .loginPopup({ ...this.loginRequest, prompt: 'create' })
      .then((resp: AuthenticationResult) => {
        this.handleResponse(resp, authCallback);
      })
      .catch((err) => {
        console.error(err);
        handleError(err);
      });
  }

  public login(authCallback: any, handleError: any, prompt: string = 'select_account'): void {
    this.loginRequest = {
      scopes: this.getAppScopes(),
      prompt,
    };
    this.myMSALObj
      .loginPopup(this.loginRequest)
      .then((resp: AuthenticationResult) => {
        this.handleResponse(resp, authCallback);
      })
      .catch((err) => {
        console.error(err);
        handleError(err);
      });
  }

  public async acquireTokenSilent(username: string): Promise<AuthenticationResult> {
    try {
      const currentAccount = this.myMSALObj.getAccountByUsername(username);

      const silentRequest = {
        scopes: this.getAppScopes(),
        account: currentAccount,
        forceRefresh: false,
      };

      const authResult: AuthenticationResult = await this.myMSALObj.acquireTokenSilent(silentRequest);
      return authResult;
    } catch (error) {
      throw new Error(`Could not refresh access token: ${error}`);
    }
  }

  public async GetUserPicture(): Promise<File> {
    try {
      const token = await this.myMSALObj.acquireTokenSilent({
        account: this.account,
        scopes: ['User.Read']
      }).then((resp: AuthenticationResult) => resp.accessToken);

      const graphEndpoint = 'https://graph.microsoft.com/v1.0/me/photo/$value';
      const response = await axios(graphEndpoint, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'arraybuffer'
      });

      const file = arrayBufferToFile(response.data, 'profilepic.png', 'image/png');
      return file;
    } catch (error) {
      console.error('Error fetching user picture:', error);
      throw error;
    }
  }

  public logoutCurrentAccount(callBack: any): void {
    const currentAccount = this.getAccount();
    this.logout(currentAccount, callBack);
  }

  public logout(account: AccountInfo, callBack: any): void {
    const logOutRequest: EndSessionPopupRequest = account?.homeAccountId ? {
      account: this.myMSALObj.getAccountByHomeId(account.homeAccountId),
    } : {};

    this.myMSALObj.logoutPopup(logOutRequest)
      .then(() => {
        if (callBack) {
          callBack();
        }
      })
      .catch((error) => console.log(error));
  }

  public handleResponse(response: AuthenticationResult, incomingFunction: any) {
    if (response !== null && response.account !== null && response.accessToken !== null) {
      this.account = response.account;
      this.accessToken = response.accessToken;
    } else {
      this.account = this.getAccount();
    }

    if (this.account) {
      incomingFunction(this.account, this.accessToken);
    }
  }

  public isAuthenticated = (): boolean => (!!this.getAccount());

  public getAccount(): AccountInfo | undefined {
    const currentAccounts = this.myMSALObj.getAllAccounts();
    if (currentAccounts === null) {
      // @ts-ignore
      console.log('No accounts detected');
      return undefined;
    }

    return currentAccounts[0];
  }
}

export default AzureAuthenticationContext;
