import { FC, useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import { observer } from 'mobx-react';
import { InfoOutlined, CloudDownload, ArrowDownward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { displayLocalDate } from 'src/utils/dateUtils';
import { holidayBonusApi } from 'src/API/holidayBonusApi';
import { useAppStore } from 'src/store/mobx/appStore';
import { CommonQueryParams, HolidayBonusChangePeriod, HolidayBonusChangeRequestDto } from 'src/types/apiSchemas';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import { calculateHolidayBalanceChangeVacationDays } from 'src/utils/holidayBalanceUtils';

interface HolidayBonusChangePeriodRequestsModalProps {
  holidayBonusChangePeriodId: number;
}

interface Pagination extends CommonQueryParams {
  TotalPages: number;
  TotalCount: number;
}

const HolidayBonusChangePeriodRequestsModal: FC<HolidayBonusChangePeriodRequestsModalProps> = observer((props) => {
  const initialQueryParameters = { PageNumber: 1, PageSize: 50 } as Pagination;

  const [paging, setPaging] = useState<Pagination>(initialQueryParameters);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>();
  const [isLoadingCsv, setIsLoadingCsv] = useState<boolean>();
  const [requests, setRequests] = useState<HolidayBonusChangeRequestDto[]>();
  const [period, setPeriod] = useState<HolidayBonusChangePeriod>(null);
  const { holidayBonusChangePeriodId } = props;
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    /**
    * gets the initial result of the holiday bonus requests for this period / first page to show
     */
    const getHolidayBonusChangeRequests = async () => {
      try {
        const holidayBonusRequests = await holidayBonusApi.getHolidayBonusChangePeriodRequests(user?.customerId, holidayBonusChangePeriodId, paging);
        setRequests(holidayBonusRequests.results);
        setPaging(
          {
            PageNumber: holidayBonusRequests.pageNumber,
            PageSize: holidayBonusRequests.pageSize,
            TotalPages: holidayBonusRequests.totalPages,
            TotalCount: holidayBonusRequests.totalCount
          }
        );
      } catch (err) {
        console.error(err);
      }
    };

    /**
     * gets period information - we need divider to calculate vacation days
     */
    const getHolidayBonusChangePeriod = async () => {
      try {
        const response = await holidayBonusApi.getHolidayBonusChangePeriod(user?.customerId, holidayBonusChangePeriodId);
        setPeriod(response);
      } catch (err) {
        console.log(err);
      }
    };

    const resolveData = async () => {
      setIsLoading(true);
      await Promise.allSettled([getHolidayBonusChangeRequests(), getHolidayBonusChangePeriod()]);
      setIsLoading(false);
    };

    resolveData();
  }, [holidayBonusChangePeriodId]);

  /**
   * sets up download for the CSV string
   * @param csvdata
   */
  const download = (csvdata) => {
    const blob = new Blob([csvdata], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `holiday-bonus-requests-${holidayBonusChangePeriodId}.csv`);
    a.click();
  };

  /**
   * gets the next page and returns accumulated the result
   * @param currentResponse
   * @param pageSize
   * @returns
   */
  const getCumulativeRequests = async (currentResponse: HolidayBonusChangeRequestDto[], pageSize: number = 50): Promise<HolidayBonusChangeRequestDto[]> => {
    const nextPageParams = { PageSize: pageSize, PageNumber: paging.PageNumber + 1 } as CommonQueryParams;
    const holidayBonusRequests = await holidayBonusApi.getHolidayBonusChangePeriodRequests(user?.customerId, holidayBonusChangePeriodId, nextPageParams);
    if (holidayBonusRequests?.results?.length < 1) { return currentResponse; }
    const cumulativeItems = [...currentResponse, ...holidayBonusRequests?.results ?? []];
    setPaging(
      {
        PageNumber: holidayBonusRequests.pageNumber,
        PageSize: holidayBonusRequests.pageSize,
        TotalPages: holidayBonusRequests.totalPages,
        TotalCount: holidayBonusRequests.totalCount
      }
    );
    return cumulativeItems;
  };

  /**
   * retrieves next page of holiday bonus change requests and accumulates it to the visible result
   */
  const getNextPage = async () => {
    try {
      setIsLoadingMore(true);
      const cumulativeBalances = await getCumulativeRequests(requests);
      if (cumulativeBalances) {
        setRequests(cumulativeBalances);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const handleDownload = async () => {
    const errorRequestsNames = [];
    let pendingCount = 0;
    let declinedCount = 0;
    let returnedCount = 0;
    try {
      setIsLoadingCsv(true);
      const csvRows = [];
      csvRows.push('employee id;holiday year;vacation days;divider;holiday bonus days;original balance');
      // loading all requests to the CSV
      const allRequestsParams = { PageNumber: 1, PageSize: paging.TotalCount } as CommonQueryParams;
      const csvHolidayBonusRequests = await holidayBonusApi.getHolidayBonusChangePeriodRequests(user?.customerId, holidayBonusChangePeriodId, allRequestsParams);

      // loading the balances information from Miller
      const periodBalances = await holidayBonusApi.getAllHolidayBalancesForPeriodRecursive(user?.customerId, holidayBonusChangePeriodId, 500);

      pendingCount = csvHolidayBonusRequests?.results?.filter((r) => r.status === 'Pending').length;
      declinedCount = csvHolidayBonusRequests?.results?.filter((r) => r.status === 'Declined').length;
      returnedCount = csvHolidayBonusRequests?.results?.filter((r) => r.status === 'Returned').length;

      // parsing the CSV contents
      csvHolidayBonusRequests?.results?.forEach((requestRow) => {
        if (requestRow.status === 'Approved') {
          let holidayYear = null;
          const employeeId = requestRow?.requester?.employeeId;
          let days = 0;
          let originalBalance = 'N/A';

          if (employeeId) {
            // finding the original balance for the user from Miller to be added to the CSV contents
            const employeeBalance = periodBalances?.items?.find((balance) => balance.employeeNumber === employeeId);
            if (employeeBalance) {
              holidayYear = employeeBalance.period;
              originalBalance = employeeBalance.amount.toString();
            }
          }
          if (requestRow?.days > 0) {
            days = requestRow.days;
          }
          if (holidayYear && employeeId && days > 0) {
            csvRows.push(`${employeeId};${holidayYear};${calculateHolidayBalanceChangeVacationDays(days.toString(), period.divider)};${period.divider};${days};${originalBalance}`);
          } else {
            errorRequestsNames.push(requestRow?.requester?.name);
          }
        }
      });
      const csvdata = csvRows.join('\n');
      download(csvdata);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingCsv(false);
      let feedbackMessage = '';
      if (errorRequestsNames.length > 0) {
        feedbackMessage += `${t('HolidayBonusChangeRequestsExportAlert1')} ${errorRequestsNames.join(', ')}. `;
      }
      if (pendingCount > 0) {
        feedbackMessage += `${t('HolidayBonusChangeRequestsExportAlert2')} ${pendingCount.toString()}. `;
      }
      if (declinedCount > 0) {
        feedbackMessage += `${t('HolidayBonusChangeRequestsExportAlert3')} ${declinedCount.toString()}. `;
      }
      if (returnedCount > 0) {
        feedbackMessage += `${t('HolidayBonusChangeRequestsExportAlert4')} ${returnedCount.toString()}. `;
      }
      if (feedbackMessage) {
        // showing feedback of the export
        // if there was some invalid data that could not be converted to CSV (some data missing)
        // number of still pending requests that would need to get approved to be included in CSV
        // number of declined requests that will never get included in the CSV
        toast.success(feedbackMessage, { duration: 15000, });
      }
    }
  };

  if (!isLoading && requests?.length === 0) {
    return (
      <Alert
        severity="warning"
      >
        <Typography
          color="textPrimary"
          variant="subtitle2"
          display="inline-block"
        >
          {t('HolidayBonusChangeRequestsNoRequestsForPeriod')}
        </Typography>
      </Alert>
    );
  }

  return (
    <>
      <Button
        startIcon={<InfoOutlined fontSize="small" />}
        disabled={isLoading}
        variant="light"
        component="label"
        onClick={handleOpen}
      >
        {t('HolidayBonusChangeRequestsShowButtonLabel')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxHeight: '80vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2
        }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="h6"
              mb={2}
            >
              {t('HolidayPeriodRequestsModalTitle')}
            </Typography>
            <LoadingButton
              onClick={handleDownload}
              variant="outlined"
              disabled={isLoading}
              loading={isLoadingCsv}
              startIcon={<CloudDownload />}
            >
              {t('HolidayBonusChangeRequestsLoadCsv')}
            </LoadingButton>
          </Box>

          {isLoading && (
            <CircularProgress
              size={65}
              sx={{ display: 'block', margin: 'auto', mt: 10 }}
            />
          )}

          {!isLoading && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('ApprovalHolidayChangePeriod')}</TableCell>
                    <TableCell>{t('SettingsUserName')}</TableCell>
                    <TableCell>{t('ReceivedVacationDaysAmountTitle')}</TableCell>
                    <TableCell>{t('HolidayBonusChangePeriodDivider')}</TableCell>
                    <TableCell>{t('HolidayPeriodBalancesAmountTitle')}</TableCell>
                    <TableCell>{t('StatusTitle')}</TableCell>
                    <TableCell>{t('ApproverTitle')}</TableCell>
                    <TableCell>{t('CommentTitle')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requests?.map((item) => (
                    <TableRow key={item.holidayBonusChangeRequestId}>
                      <TableCell>
                        {displayLocalDate(item?.period?.periodStartUtc, true)}
                        {' '}
                        -
                        {' '}
                        {displayLocalDate(item?.period?.periodEndUtc, true)}
                      </TableCell>
                      <TableCell>{item?.requester?.name ?? '-'}</TableCell>
                      <TableCell>{calculateHolidayBalanceChangeVacationDays(item?.days.toString(), period.divider)}</TableCell>
                      <TableCell>{period?.divider ?? '-'}</TableCell>
                      <TableCell>{item?.days ?? '-'}</TableCell>
                      <TableCell>{item?.status ?? '-'}</TableCell>
                      <TableCell>{item?.approver?.name ?? '-'}</TableCell>
                      <TableCell>{item?.approverComment ?? '-'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {requests?.length < paging?.TotalCount && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <LoadingButton
                    variant="outlined"
                    startIcon={<ArrowDownward />}
                    loading={isLoadingMore}
                    onClick={() => getNextPage()}
                  >
                    {t('LoadMore')}
                  </LoadingButton>
                </Box>
              )}
            </TableContainer>
          )}
        </Box>
      </Modal>
    </>
  );
});

export default HolidayBonusChangePeriodRequestsModal;
