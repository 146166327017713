import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  Popper,
  ClickAwayListener,
  Fade
} from '@mui/material';
import CogIcon from '../../icons/Cog';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ReleasesDialog from 'src/components/dashboard/ReleasesDialog';
import releasesApi from 'src/API/releasesApi';
import { ReleaseDtoPagedResultDto } from 'src/types/apiSchemas';
import useMounted from 'src/hooks/useMounted';
import { AuthorizationService, IAuthorizationService } from 'src/Services';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import { resolveTheme } from 'src/utils/themeUtils';
import { THEMES } from 'src/constants';
import AzureAuthenticationContext from 'src/azure/azure-authentication-context';
import ProfileAvatar from '../common/ProfileAvatar';

const AccountPopover: FC = observer(() => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const appStore = useAppStore();
  const mounted = useMounted();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const popperOpen = Boolean(anchorEl);
  const [releasesOpen, setReleasesOpen] = useState<boolean>(false);
  const [releaseData, setReleasedata] = useState<ReleaseDtoPagedResultDto>({
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalCount: 0,
    results: []
  });
  const { t } = useTranslation();
  const { user } = appStore.loginStore.get();
  const auth: IAuthorizationService = new AuthorizationService();
  // only users that are managers can configure substitutes for themselves
  const canCongigureSubstitutes: boolean = auth.get(user).hasManagerAccess;
  const uiTheme = resolveTheme();

  const getReleases = async () => {
    if (user?.customerId) {
      try {
        const data = await releasesApi.getReleases(1, 5, user?.customerId);
        if (mounted.current) {
          setReleasedata(data);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (releaseData.results.length > 0 && user?.lastLoginTimeUTC) {
      if (user.lastLoginTimeUTC.toString() < releaseData.results[0].releaseDateUTC) setReleasesOpen(true);
    }
  }, [releaseData]);

  useEffect(() => {
    getReleases();
    if (!user?.avatar) {
      setAnchorEl(document.getElementById('profile-picture-popover'));
    }
  }, []);

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleReleasesOpen = (): void => {
    setReleasesOpen(true);
    handleClose();
  };

  const handleReleasesClose = () => {
    setReleasesOpen(false);
  };

  const handleLogoutCallback = () => {
    appStore.loginStore.logout();
    navigate('/');
  };

  const handleLogout = async (): Promise<void> => {
    try {
      const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext();
      handleClose();
      authenticationModule.logoutCurrentAccount(handleLogoutCallback);
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <ProfileAvatar
          id="profile-picture-popover"
          src={user?.avatar}
          alt={user?.name}
          sx={{
            height: 32,
            width: 32
          }}
        />
        <Popper
          open={popperOpen}
          anchorEl={anchorEl}
          sx={{
            paddingTop: '25px',
            paddingRight: '20px'
          }}
          placement="bottom"
          transition
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handlePopperClose}>
              <Fade
                {...TransitionProps}
                timeout={500}
              >
                <Box sx={{ border: 0, p: 1, bgcolor: 'background.paper' }}>
                  <Typography
                    color="textPrimary"
                    variant="caption"
                  >
                    {t('AddProfilePicPopperContent')}
                  </Typography>
                </Box>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {appStore.loginStore.get().user.name}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem
            component={RouterLink}
            to="/dashboard/account"
            onClick={handleClose}
          >
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {t('SettingsTitle')}
                </Typography>
              )}
            />
          </MenuItem>
          {uiTheme !== THEMES.ACCOUNTOR && (
            <MenuItem
              component={RouterLink}
              to="/dashboard/notificationSettings"
              onClick={handleClose}
            >
              <ListItemIcon>
                <EditNotificationsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={(
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {t('NotificationSettingsTitle')}
                  </Typography>
                )}
              />
            </MenuItem>
          )}
          {canCongigureSubstitutes
            && (
              <MenuItem
                component={RouterLink}
                to="/dashboard/managesubstitutions"
                onClick={handleClose}
              >
                <ListItemIcon>
                  <SupervisorAccountIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={(
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      {t('ManageSubstitutions')}
                    </Typography>
                  )}
                />
              </MenuItem>
            )}
          {user.isConsultantUser
            && (
              <MenuItem
                component={RouterLink}
                to="/dashboard/accessrequests"
                onClick={handleClose}
              >
                <ListItemIcon>
                  <GroupAddIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={(
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      {t('RequestAccessMenuItemTitle')}
                    </Typography>
                  )}
                />
              </MenuItem>
            )}
          {user.isConsultantUser && user.customerId > 0 
            && (user.customerInternalRoles.some((r) => r.customerId === user.customerId) || 
                user.customerUserRoles.some((r) => r.customerId === user.customerId) )
            && (
              <MenuItem
                component={RouterLink}
                to="/dashboard/leaveteam"
                onClick={handleClose}
              >
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={(
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      {t('LeaveTeamMenuItemTitle')}
                    </Typography>
                  )}
                />
              </MenuItem>
            )}
          {releaseData?.results?.length > 0
            && (
              <MenuItem
                onClick={() => {
                  handleReleasesOpen();
                }}
              >
                <ListItemIcon>
                  <NewReleasesIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={(
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      {t('ButtonReleases')}
                    </Typography>
                  )}
                />
              </MenuItem>
            )}
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            {t('GenericButtonSignOut')}
          </Button>
        </Box>
      </Popover>
      <ReleasesDialog
        openDialog={releasesOpen}
        closeDialog={handleReleasesClose}
        releaseData={releaseData}
      />
    </>
  );
});

export default AccountPopover;
