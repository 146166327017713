import { Box, Card, darken, Grow, styled, Typography } from '@mui/material';
import { FC } from 'react';
import PropTypes from 'prop-types';
import { AnnouncementLink } from 'src/types/announcementLink';
import { addHttp } from 'src/utils/urlUtils';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

interface CreateAnnouncementDisplayLinkProps {
  link: AnnouncementLink,
  formValues: any,
  arrayHelpers: any
}

const CreateAnnouncementDisplayLink: FC<CreateAnnouncementDisplayLinkProps> = (props) => {
  const { link, formValues, arrayHelpers } = props;
  const rowHeight = '66px';
  const ClickableCard = styled(Card)(({ theme }) => ({
    border: '1px solid',
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.background.default,
    height: rowHeight,
    '&:hover': {
      backgroundColor: darken(theme.palette.background.default, 0.2),
    },
    cursor: 'pointer'
  }));

  return (
    <Grow in>
      <Box
        sx={{ display: 'flex' }}
      >
        <ClickableCard
          sx={{ flex: 1 }}
          onClick={() => {
            window.open(addHttp(link.url), '_blank');
          }}
        >
          <Box sx={{ p: 1, mx: 1 }}>
            <Typography
              variant="body1"
              color="textPrimary"
              noWrap
            >
              {link.text}
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              noWrap
            >
              {decodeURIComponent(link.url)}
            </Typography>
          </Box>
        </ClickableCard>
        <ClickableCard
          sx={{
            ml: 1,
            width: rowHeight,
          }}
          onClick={() => {
            const idx = formValues.createdLinks.indexOf(link);
            console.log(idx);
            if (idx !== undefined) {
              arrayHelpers.remove(idx);
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          <Box sx={{ p: 1, display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <DeleteForeverIcon />
          </Box>
        </ClickableCard>
      </Box>
    </Grow>
  );
};

CreateAnnouncementDisplayLink.propTypes = {
  // @ts-ignore
  link: PropTypes.any.isRequired
};

export default CreateAnnouncementDisplayLink;
