import { Autocomplete, CircularProgress, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { MessageThreadRelatedItemDto, MessageThreadRelatedItemOptionsDto, MessageThreadRelatedItemWithName, UserReferenceDto, UserRole } from 'src/types/apiSchemas';
import { baseApi } from 'src/API/baseApi';
import toast from 'react-hot-toast';
import { useAppStore } from 'src/store/mobx';
import { AuthorizationService, IAuthorizationService } from 'src/Services';


interface MessageThreadRelatedItemChangeProps {
  handleRelatedItemChange: (relatedItem: MessageThreadRelatedItemDto) => void
  handleDisableSaveButton?: (disable: boolean) => void
  currentRelatedItem?: MessageThreadRelatedItemWithName
  selectedCustomer: number;
}


enum MessageThreadRelatedItemType {
  NoRelatedItem = 'NoRelatedItem',
  Company = 'Company',
  Person = 'Person'
}

const MessageThreadRelatedItemChange: FC<MessageThreadRelatedItemChangeProps> = observer(({ handleRelatedItemChange, handleDisableSaveButton = null, currentRelatedItem, selectedCustomer }) => {
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const { t } = useTranslation();
  const auth: IAuthorizationService = new AuthorizationService();
  const userIsHR = auth.get(user).is(UserRole.HR, selectedCustomer).verify();

  const getCurrentSelection = () => {
    if (currentRelatedItem?.companyId && userIsHR){
      return MessageThreadRelatedItemType.Company;
    } else if (currentRelatedItem?.person){
      return MessageThreadRelatedItemType.Person;
    }

    return MessageThreadRelatedItemType.NoRelatedItem;
  };

  const [messageThreadRelatedItemOptions, setMessageThreadRelatedItemOptions] = useState<MessageThreadRelatedItemOptionsDto>(null);
  const [messageThreadRelatedItemOptionsLoading, setMessageThreadRelatedItemOptionsLoading] = useState<boolean>(true);
  const [messageThreadRelatedCompanyName, setMessageThreadRelatedCompanyName] = useState<string>(currentRelatedItem?.relatedCompanyName ?? '');
  const [messageThreadRelatedItemRadioGroupSelection, setMessageThreadRelatedItemRadioGroupSelection] = useState<MessageThreadRelatedItemType>(() => getCurrentSelection());
  const [messageThreadRelatedPersonSearchString, setMessageThreadRelatedPersonSearchString] = useState<string>(currentRelatedItem?.person?.name ?? '');
  const [messageThreadRelatedUser, setMessageThreadRelatedUser] = useState<UserReferenceDto>(currentRelatedItem?.person ?? null);
  const [selectedRelatedItem, setSelectedRelatedItem] = useState<MessageThreadRelatedItemDto>({ personUserId: null, companyId: null });

  const getMessageThreadRelatedItemOptions = async () => {
    setMessageThreadRelatedItemOptionsLoading(true);
    try {
      const response = await baseApi.getMessageThreadRelatedItemOptions(selectedCustomer, messageThreadRelatedItemRadioGroupSelection === MessageThreadRelatedItemType.Person ? messageThreadRelatedPersonSearchString : '');
      setMessageThreadRelatedItemOptions(response);
    } catch (err) {
      toast.error(t('CouldNotRetrieveMessageThreadRelatedItemOptionsToast'));
      console.log(err);
    }
    setMessageThreadRelatedItemOptionsLoading(false);
  };

  useEffect(() => {
    if (messageThreadRelatedItemRadioGroupSelection !== MessageThreadRelatedItemType.Person)
      setMessageThreadRelatedUser(null);
    if (messageThreadRelatedItemRadioGroupSelection !== MessageThreadRelatedItemType.Company)
      setMessageThreadRelatedCompanyName('');
    if (messageThreadRelatedItemRadioGroupSelection !== MessageThreadRelatedItemType.NoRelatedItem)
      if (messageThreadRelatedItemOptions === null)
        getMessageThreadRelatedItemOptions();
  }, [messageThreadRelatedItemRadioGroupSelection]);

  useEffect(() => {
    if (handleDisableSaveButton !== null) {
      if (currentRelatedItem !== null &&
      (
        (selectedRelatedItem?.personUserId === null && messageThreadRelatedItemRadioGroupSelection === MessageThreadRelatedItemType.Person)
      || 
        (selectedRelatedItem?.companyId === null && messageThreadRelatedItemRadioGroupSelection === MessageThreadRelatedItemType.Company)    
      )
      ) {
        handleDisableSaveButton(true);
      } else {
        handleDisableSaveButton(false);
      }
    }
    
  }, [selectedRelatedItem, messageThreadRelatedItemRadioGroupSelection]);

  useEffect(() => {
    getMessageThreadRelatedItemOptions();
  }, [messageThreadRelatedPersonSearchString]);

  useEffect(() => {
    if (selectedCustomer !== 0) {
      if (!currentRelatedItem) {
        setMessageThreadRelatedItemRadioGroupSelection(MessageThreadRelatedItemType.NoRelatedItem);
        setMessageThreadRelatedUser(null);
        setMessageThreadRelatedCompanyName('');
        setSelectedRelatedItem({ personUserId: null, companyId: null });
      }
      
      setMessageThreadRelatedItemOptions(null);
      getMessageThreadRelatedItemOptions();
    }
  }, [selectedCustomer]);

  return (
    <><Grid
      item
      xs={12}
      >
        <Typography
          variant="formFieldHeader"
          sx={{ mb: 1 }}
        >
          {t('MessagesRelatedCompanyOrEmployeeTitle')}
        </Typography>
      <FormControl>
        <RadioGroup
          defaultValue={MessageThreadRelatedItemType.NoRelatedItem}
          name="message-thread-related-company-or-employee-group"
          onChange={(e) => {
            setMessageThreadRelatedItemRadioGroupSelection(e.target.value as MessageThreadRelatedItemType);
            if (e.target.value as MessageThreadRelatedItemType === MessageThreadRelatedItemType.NoRelatedItem) {
              handleRelatedItemChange({ companyId: null, personUserId: null });
              setSelectedRelatedItem({ companyId: null, personUserId: null });
              setMessageThreadRelatedCompanyName('');
            }
          } }
        >
          <FormControlLabel value={MessageThreadRelatedItemType.NoRelatedItem} checked={messageThreadRelatedItemRadioGroupSelection === MessageThreadRelatedItemType.NoRelatedItem} control={<Radio />} label={t('MessagesNoRelatedCompanyOrEmployee')} />
          {userIsHR && (
            <FormControlLabel value={MessageThreadRelatedItemType.Company} checked={messageThreadRelatedItemRadioGroupSelection === MessageThreadRelatedItemType.Company} control={<Radio />} label={t('Company')} />
          )}
          <FormControlLabel value={MessageThreadRelatedItemType.Person} checked={messageThreadRelatedItemRadioGroupSelection === MessageThreadRelatedItemType.Person} control={<Radio />} label={t('MessageThreadRelatedPerson')} />
        </RadioGroup>
      </FormControl>

      </Grid>
      <Grid
        item
        xs={12}
      >
        {messageThreadRelatedItemRadioGroupSelection === MessageThreadRelatedItemType.Company && (
          <Grid
            item
            xs={12}
            flexDirection='row'
          >
            {messageThreadRelatedItemOptions && (
              <Select
              value={messageThreadRelatedCompanyName}
              renderValue={(selected) => {
                if (selectedRelatedItem?.companyId === null && currentRelatedItem?.companyId !== null) {
                  return currentRelatedItem?.relatedCompanyName;
                }
    
                return selected;
              }}
              name="relatedItem"
              disabled={messageThreadRelatedItemOptionsLoading}
              onChange={(e) => {
                const company = messageThreadRelatedItemOptions.companies.find((c) => c.name === e.target.value);
                const relatedItem = {
                  companyId: company?.id,
                  personUserId: null
                };
                setMessageThreadRelatedCompanyName(company.name);
                handleRelatedItemChange(relatedItem);
                setSelectedRelatedItem(relatedItem);
              } }
              sx={{ width: 300, maxWidth: '100%' }}
              >
              {messageThreadRelatedItemOptions?.companies?.map((c) => (
                <MenuItem value={c.name} key={c.id}>
                  {c.name}
                </MenuItem>
              ))}
              </Select>
            )}
            
            {messageThreadRelatedItemOptionsLoading && (
              <CircularProgress
                size={20}
                sx={{ ml: 2 }}
              />
            )}
          </Grid>
        )}
        {messageThreadRelatedItemRadioGroupSelection === MessageThreadRelatedItemType.Person && (
          <Grid
            item
            container
            xs={12}
            flexDirection='row'
            alignItems='center'
          >
            {messageThreadRelatedItemOptions && (
              <Autocomplete
              sx={{ width: 400, maxWidth: '100%' }}
              id="person"
              value={messageThreadRelatedUser}
              defaultValue={currentRelatedItem?.person}
              options={messageThreadRelatedItemOptions?.persons ?? []}
              getOptionLabel={(option: UserReferenceDto) => option.name}
              filterOptions={(options) => options}
              isOptionEqualToValue={(option, value) => { return option.id === value.id;}}
              onChange={(e, value: UserReferenceDto) => {
                setMessageThreadRelatedUser(value);
                handleRelatedItemChange({ companyId: null, personUserId: value?.id ?? null });
                setSelectedRelatedItem({ companyId: null, personUserId: value?.id ?? null });
              }}
              onInputChange={(event, value) => {
                setMessageThreadRelatedPersonSearchString(value);
              }}
              renderInput={(params) => (
                <>
                  <TextField
                    label={t('MessagesCreateMessageRelatedUserAutoCompletePlaceHolder')}
                    name="substitute"
                    {...params}
                  />
                </>
              )}
              />
            )}
            
            {messageThreadRelatedItemOptionsLoading && (
              <CircularProgress
                size={20}
                sx={{ ml: 2 }}
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
});

export default MessageThreadRelatedItemChange;