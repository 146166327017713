import {
  Configuration,
} from '@azure/msal-browser';
import { MSAL_CONFIG } from 'src/azure/azure-authentication-config';

export interface IAuthConfigStorageService {
  authStorage: Storage;
  msalConfig: Configuration;
}

export enum AuthStorageEnum {
  sessionStorage = 'sessionStorage',
  localStorage = 'localStorage'
}

export class AuthConfigStorageService implements IAuthConfigStorageService {
  private readonly msalConfigCacheKey: string = 'msalConfigCache';

  public get msalConfig(): Configuration {
    const storedConfig = localStorage.getItem(this.msalConfigCacheKey);
    if (storedConfig) {
      try {
        const parsedConfig: Configuration = JSON.parse(storedConfig);
        return parsedConfig;
      } catch (error) {
        console.error('Error parsing msalConfig from localStorage:', error);
      }
    }
    return MSAL_CONFIG;
  }

  public set msalConfig(value: Configuration) {
    try {
      localStorage?.setItem(this.msalConfigCacheKey, JSON.stringify(value));
    } catch (error) {
      console.error('Error parsing msalConfig to localStorage:', error);
    }
  }

  public get authStorage(): Storage {
    return this.msalConfig?.cache?.cacheLocation === AuthStorageEnum.sessionStorage ? window.sessionStorage : window.localStorage;
  }
}
