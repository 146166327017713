export const isDevelopment = process.env.REACT_APP_HUMHUM_ENV === 'development';
export const isStaging = process.env.REACT_APP_HUMHUM_ENV === 'staging';
export const isProduction = process.env.REACT_APP_HUMHUM_ENV === 'production';
export const environmentName = process.env.REACT_APP_HUMHUM_ENV;
export const endpointUrl = process.env.REACT_APP_HUMHUM_BACKEND_URL;
export const hotjarId = process.env.REACT_APP_HOTJAR_ID;
export const aadAppClientId = process.env.REACT_APP_AAD_APP_ID ?? '709a9eb1-1edb-4f88-bf9a-aa9f169d1013';
export const aadLoginScopes = process.env.REACT_APP_API_SCOPES;
export const uiTheme = process.env.REACT_APP_UI_THEME_VARIANT ?? 'DARK';
export const externalIdAppId = process.env.REACT_APP_EXTERNAL_ID_APP_ID;
export const externalIdAuthority = process.env.REACT_APP_EXTERNAL_ID_AUTHORITY;
export const externalIdApiScopes = process.env.REACT_APP_EXTERNAL_ID_API_SCOPES;
