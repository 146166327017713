import { FC, useEffect, useState } from 'react';
import { Grid, Typography, Card } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';

interface CreateAnnouncementSelectableLabelProps {
  arrayHelpers: any;
  formValues: any;
  value: string;
  children: any;
}

const CreateAnnouncementSelectableLabel: FC<CreateAnnouncementSelectableLabelProps> = (props) => {
  const { arrayHelpers, formValues, value, children } = props;
  const isActive = formValues.selectedScopes?.indexOf(value) >= 0;
  const [active, setActive] = useState<boolean>(isActive);

  useEffect(() => {
    const idx = formValues.selectedScopes.indexOf(value);
    if (active && idx === -1) {
      arrayHelpers.push(value);
    } else if (!active && idx >= 0) {
      arrayHelpers.remove(idx);
    }
  }, [active]);

  return (
    <Card
      sx={{
        backgroundColor: active ? 'primary.main' : 'background.paper',
        p: 1.5,
        m: 1
      }}
      style={{ cursor: 'pointer' }}
      onClick={() => setActive(!active)}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
      >
        {active && (
          <Grid
            item
            sx={{ mr: 1 }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
            >
              <CheckIcon sx={{ width: '100%' }} />
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            {children}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

CreateAnnouncementSelectableLabel.propTypes = {
  // @ts-ignore
  arrayHelpers: PropTypes.any.isRequired,
  formValues: PropTypes.any.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default CreateAnnouncementSelectableLabel;
