import type { FC } from 'react';
import { Box, Card, Fade, Grid, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { AnnouncementDetailDto, AnnouncementType } from 'src/types/apiSchemas';
import AnnouncementCardHeader from './AnnouncementCardHeader';
import AnnouncementCardImage from './AnnouncementCardImage';
import AnnouncementCardCalendarEvent from './AnnouncementCardCalendarEvent';
import AnnouncementCardCalendarReminder from './AnnouncementCardCalendarReminder';
import { withStyles } from '@mui/styles';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import { getAnnouncementContent } from '../../../pages/dashboard/AnnouncementDetails';
import { displayLocalDateTime } from 'src/utils/dateUtils';

interface AnnouncementCardProps {
  announcement: AnnouncementDetailDto;
  showArrow: boolean;
}

const AnnouncementCard: FC<AnnouncementCardProps> = observer((props) => {
  const { announcement, showArrow } = props;
  const appStore = useAppStore();
  const settings = appStore.settingsStore.get();
  const announcementContent = getAnnouncementContent(announcement, settings?.language);

  const WhiteUnderlineLink = withStyles({
    root: {
      color: '#FFFFFF',
      textDecoration: 'underline #b5b5b5'
    }
  })(Link);

  return (
    <Fade in>
      <Card sx={{ minWidth: 315, backgroundColor: 'primary.cardBackground' }}>
        <Box sx={{ m: 3 }}>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
              sx={{ my: -1 }}
            >
              <AnnouncementCardHeader
                imageUrl={announcement.authorImageUrl}
                author={announcement.authorName ?? 'No author name provided'}
                date={announcement.publishTimeUTC}
                showArrow={showArrow}
                announcementId={announcement.announcementId}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography
                color="textPrimary"
                variant="h5"
                fontWeight={700}
              >
                {/* TODO: Handle english and Finnish options */}
                {announcementContent.title}
              </Typography>
              {announcement.announcementType === AnnouncementType.Event && announcement.eventStartTimeUTC && (
                <Typography>
                  {displayLocalDateTime(announcement.eventStartTimeUTC, true)}
                  {announcement.eventEndTimeUTC && (announcement.eventEndTimeUTC !== announcement.eventStartTimeUTC) && ` - ${displayLocalDateTime(announcement.eventEndTimeUTC, true)}`}
                </Typography>
              )}
              {announcement.announcementType === AnnouncementType.Reminder && announcement.reminderDueDateUTC && (
                <Typography>
                  {displayLocalDateTime(announcement.reminderDueDateUTC, true)}
                </Typography>
              )}
            </Grid>
            {announcement.attachedImage && (
              <Grid
                item
                xs={12}
              >
                <AnnouncementCardImage
                  imageUrl={announcement.attachedImage}
                  caption={announcement.attachedImageCaption}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
            >
              <Typography
                color="textPrimary"
              >
                {announcementContent.content}
              </Typography>
            </Grid>
            {announcement.links.map((link) => (
              <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="center"
                xs={12}
                sx={{ mb: 1 }}
                key={link.url}
              >
                <WhiteUnderlineLink
                  href={link.url}
                >
                  <Typography
                    color="textPrimary"
                    fontWeight={600}
                  >
                    {link.text}
                  </Typography>
                </WhiteUnderlineLink>
              </Grid>
            ))}
            {announcement.announcementType === AnnouncementType.Event && announcement.eventStartTimeUTC && (
              <Grid
                item
                xs={12}
              >
                <AnnouncementCardCalendarEvent
                  start={announcement.eventStartTimeUTC}
                  end={announcement.eventEndTimeUTC}
                  title={announcementContent.title}
                  description={announcementContent.content}
                />
              </Grid>
            )}
            {announcement.announcementType === AnnouncementType.Reminder && announcement.reminderDueDateUTC && (
              <Grid
                item
                xs={12}
              >
                <AnnouncementCardCalendarReminder
                  reminderTime={announcement.reminderDueDateUTC}
                  title={announcementContent.title}
                  description={announcementContent.content}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Card>
    </Fade>
  );
});

AnnouncementCard.propTypes = {
  // @ts-ignore
  announcement: PropTypes.any.isRequired,
  showArrow: PropTypes.bool.isRequired
};

export default AnnouncementCard;
