import createSvgIcon from '@mui/material/utils/createSvgIcon';

const HideNavigation = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 12"
    fill="currentColor"
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M0 12L13 12V10L0 10L0 12ZM0 7L10 7V5L0 5L0 7ZM0 0L0 2L13 2V0L0 0ZM18 9.59L14.42 6L18 2.41L16.59 1L11.59 6L16.59 11L18 9.59Z"
    />
  </svg>,
  'HideNavigation'
);

export default HideNavigation;
